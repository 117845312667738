import { getSpacing, makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { FunctionComponent } from 'react';
import { getText } from 'assets/localization/localization';
import { AllergyTypeaheadField } from './AllergyTypeaheadField';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { MedicalConditionTypeaheadField } from './MedicalConditionTypeaheadField';
import { Form } from 'assets/layout';

export const MedicalInfoFormFields: FunctionComponent<
  MedicalInfoFormFieldsProps
> = ({ showEasyOpenBootleCaps = true }) => {
  const styles = useStyles();

  return (
    <View>
      <View>
        <Form.Row>
          <Form.Column>
            <Text style={styles.fieldLabel}>{getText('allergies') + ' *'}</Text>
            <View style={styles.marginTop}>
              <AllergyTypeaheadField
                name="allergies"
                rules={{
                  required: getText('allergies-required'),
                }}
              />
            </View>
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Text style={styles.fieldLabel}>{getText('more-information')}</Text>
            <View style={styles.marginTop}>
              <MedicalConditionTypeaheadField name="medical_conditions" />
            </View>
          </Form.Column>
        </Form.Row>

        {showEasyOpenBootleCaps && (
          <Form.Row>
            <Form.Column>
              <Text style={styles.fieldLabel}>
                {getText('would-like-easy-open-caps')}
              </Text>
              <RadioButtonGroupField
                name="prefers_easy_open_bottle_caps"
                testID={'easy-open-bottle-caps-test-id'}
                values={[
                  {
                    text: getText('prefers-easy-open-caps'),
                    value: true,
                  },
                  {
                    text: getText('prefers-safety-caps'),
                    value: false,
                  },
                ]}
              />
              <Text style={styles.finePrint}>
                {getText('easy-open-bottle-caps-description')}
              </Text>
            </Form.Column>
          </Form.Row>
        )}
      </View>
    </View>
  );
};

export interface MedicalInfoFormFieldsProps {
  /** Default is `true` */
  showEasyOpenBootleCaps?: boolean;
}

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    color: theme.palette.gray[700],
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    lineHeight: 20,
    marginTop: theme.getSpacing(1),
  },
  marginTop: {
    marginTop: theme.getSpacing(1),
  },
  finePrint: {
    color: theme.palette.gray[400],
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    paddingTop: getSpacing(1),
  },
}));
