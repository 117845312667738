import type { StackNavigationProp } from '@react-navigation/stack';
import {
  StackHeaderProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { Account } from './Account';
import { NativeHeader } from 'assets/components/native-header';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import { Medications } from '../medications/Medications';
import { FeedbackSuccessMessage } from './patient/FeedbackSuccessMessage';
import { GiveFeedback } from './patient/GiveFeedback';
import { MedicalInfo } from './patient/MedicalInfo';
import { MedicationScanner } from './patient/MedicationScanner';
import { PatientInfo } from './patient/PatientInfo';
import { PatientForms } from './patient/PatientForms';
import { EditPatientCard } from './patient/EditPatientCard';
import { PatientPreferences } from './patient/PatientPreferences';
import { PersonalInformation } from './patient/PersonalInformation';
import { InsuranceAndIDCards } from './patient/InsuranceAndIDCards';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { AboutApp } from './patient/AboutApp';
import { RefillStackParamList } from '../refill/RefillNavigation';

const Stack = createStackNavigator<AccountStackParamList>();
export const AccountNavigation: FunctionComponent<
  PropsWithChildren<AccountNavigationProps>
> = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !(Platform.OS === 'web'),
        header: AccountNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="home"
          component={Account}
          options={{ title: getText('home'), headerShown: false }}
        />
        <Stack.Screen
          name="patient-info"
          component={PatientInfo}
          options={{ title: getText('patient-info') }}
        />
        <Stack.Screen
          name="edit-patient-card"
          component={EditPatientCard}
          options={{
            title: getText('insurance-and-id-cards'),
            header: (props) => (
              <NativeHeader
                onBack={() => props.navigation.goBack()}
                showAction={false}
                showBack={true}
                title={getText('insurance-and-id-cards')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="insurance-and-id-card"
          component={InsuranceAndIDCards}
          options={{
            title: getText('insurance-and-id-cards'),
            header: (props) => (
              <NativeHeader
                onBack={() => props.navigation.goBack()}
                showAction={false}
                showBack={true}
                title={getText('insurance-and-id-cards')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="barcode"
          component={MedicationScanner}
          options={{ title: getText('barcode') }}
        />
        <Stack.Screen
          name="medical-info"
          component={MedicalInfo}
          options={{
            title: getText('medical-info'),
            header: (props) => (
              <NativeHeader
                onBack={() => props.navigation.goBack()}
                showAction={false}
                showBack={true}
                title={getText('medical-info')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="personal-information"
          component={PersonalInformation}
          options={{
            title: getText('personal-info'),
            header: (props) => (
              <NativeHeader
                onBack={() => props.navigation.goBack()}
                showAction={false}
                showBack={true}
                title={getText('personal-info')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="about-app"
          component={AboutApp}
          options={{ title: getText('about-this-app') }}
        />
        <Stack.Screen
          name="patient-preferences"
          component={PatientPreferences}
          options={{ title: getText('patient-preferences') }}
        />
        <Stack.Screen
          name="feedback"
          component={GiveFeedback}
          options={{ title: getText('feedback') }}
        />
        <Stack.Screen
          name="feedback-success"
          component={FeedbackSuccessMessage}
          options={{ title: getText('feedback-success') }}
        />
        <Stack.Screen
          name="medications"
          component={Medications}
          options={{ title: getText('medications') }}
        />
        <Stack.Screen
          name="patient-forms"
          component={PatientForms}
          options={{ title: getText('patient-forms') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};
export type AccountNavigationProps = StackHeaderProps;
export type AccountStackParamList = {
  home: undefined;
  'personal-information': undefined;
  medical: undefined;
  settings: undefined;
  'patient-info': undefined;
  'edit-patient-card': {
    cardType: CardType;
    nextNavigation?: keyof RefillStackParamList;
  };
  'insurance-and-id-card': undefined;
  barcode: undefined;
  feedback: undefined;
  'feedback-success': undefined;
  'medical-info': undefined;
  'patient-preferences': undefined;
  medications: undefined;
  'people-under-care': undefined;
  'patient-forms': undefined;
  'about-app': undefined;
};
export type AccountStackNavigationProp =
  StackNavigationProp<AccountStackParamList>;
export const AccountNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof AccountStackParamList;
  const handleBack = () => {
    props.navigation.goBack();
  };
  // TODO: follow up cards will make this dynamic based on the route as we add other routes
  return (
    <NativeHeader
      actionIcon={CloseIcon}
      actionLabel={getText('cancel')}
      onActionPress={handleBack}
      onBack={handleBack}
      showAction={true}
      showBack={false}
      title={getText('account')}
    />
  );
};
