import { getSpacing, makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { NotificationListItem } from './NotificationListItem';
import { Text } from '../text';
import { Divider } from 'react-native-paper';
import {
  isNew,
  isOlderThanThisMonth,
  isThisMonth,
  isThisWeek,
  sortNotifications,
} from './utils';
import { getText } from '../../localization/localization';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export const NotificationList: FunctionComponent<
  PropsWithChildren<NotificationListProps>
> = ({ notifications }) => {
  const styles = useStyles();

  const sortedNotifications = sortNotifications(notifications);

  const newNotifications = sortedNotifications.filter((notification) =>
    isNew(notification),
  );

  const thisWeekNotifications = sortedNotifications.filter((notification) =>
    isThisWeek(notification),
  );

  const thisMonthNotifications = sortedNotifications.filter((notification) =>
    isThisMonth(notification),
  );

  const oldNotifications = sortedNotifications.filter((notification) =>
    isOlderThanThisMonth(notification),
  );

  const NotificationSection: FunctionComponent<{
    notifications: UserNotificationDto[];
    heading: string;
  }> = ({ notifications, heading }) => {
    if (notifications.length === 0) {
      return null;
    }

    return (
      <>
        <Text style={styles.heading}>{heading}</Text>
        <Divider style={styles.divider} />
        {notifications.map((notification, index, array) => (
          <NotificationListItem
            key={notification.id}
            type={notification.type}
            category={notification.category}
            body={notification.body}
            timestamp={new Date(notification.created_at)}
            isRead={notification.is_read}
            hideBorder={index === array.length - 1 ? true : false}
          />
        ))}
      </>
    );
  };

  return (
    <View>
      <NotificationSection
        notifications={newNotifications}
        heading={getText('new-messages')}
      />
      <NotificationSection
        notifications={thisWeekNotifications}
        heading={getText('this-week')}
      />
      <NotificationSection
        notifications={thisMonthNotifications}
        heading={getText('this-month')}
      />
      <NotificationSection
        notifications={oldNotifications}
        heading={getText('older')}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  divider: {
    marginVertical: getSpacing(2),
  },
  heading: {
    color: theme.palette.gray[900],
    ...theme.fonts.medium,
  },
}));

export interface NotificationListProps {
  notifications: UserNotificationDto[];
}

export const NotificationListTestIDs = {};
