import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View, StyleProp, TextStyle } from 'react-native';

export const CountBadge: FunctionComponent<
  PropsWithChildren<ColoredBadgeProps>
> = ({ label, color, backgroundColor, textStyle }) => {
  const styles = useStyles();
  return (
    <View style={styles.badge}>
      <Text
        style={[
          styles.badgeText,
          textStyle,
          {
            backgroundColor,
            color,
          },
        ]}
      >
        {label}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {},
  badgeText: {
    color: theme.palette.gray[900],
    backgroundColor: theme.palette.gray[200],
    paddingVertical: theme.getSpacing(0.5),
    paddingHorizontal: theme.getSpacing(0.5),
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '500',
    borderRadius: theme.roundness,
    alignSelf: 'center',
  },
}));

export interface ColoredBadgeProps {
  backgroundColor: string;
  color: string;
  label: string;
  textStyle?: StyleProp<TextStyle>;
  nativeID?: string;
}
