import React, { PropsWithChildren } from 'react';

export type MapContextType = {
  accessToken: string;
  setAccessToken: (token: string) => void;
};

export type MapProviderProp = {
  accessToken?: string;
};

export const MapContext = React.createContext<MapContextType>({
  accessToken: '',
  setAccessToken: () => {},
});

export const MapConsumer = MapContext.Consumer;

export const MapProvider: React.FunctionComponent<
  PropsWithChildren<MapProviderProp>
> = ({ children, accessToken }) => {
  const [mapAccessToken, setMapAccessToken] = React.useState<string>(
    accessToken ?? '',
  );

  const setToken = (newToken: string) => {
    setMapAccessToken((curr) => newToken);
  };

  return (
    <MapContext.Provider
      value={{ accessToken: mapAccessToken, setAccessToken: setToken }}
    >
      {children}
    </MapContext.Provider>
  );
};
