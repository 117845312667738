import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  GestureResponderEvent,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import { CameraCapturedPicture } from 'expo-camera';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';

/*
 * Show a preview of the photo the user took and present options to retake or save photo
 */
export const CameraPreview: FunctionComponent<
  PropsWithChildren<CameraPreviewProps>
> = ({ photo, onRetakePhotoPress, onSavePhotoPress }) => {
  const theme = useTheme();
  return (
    <View
      style={{
        backgroundColor: 'transparent',
        flex: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <ImageBackground
        resizeMode="cover"
        source={{ uri: photo && photo.uri }}
        style={{
          flex: 1,
          marginLeft: -1,
          height: Dimensions.get('window').height * 0.25,
          marginTop: Dimensions.get('window').height / 3,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: 0,
          }}
        >
          <SafeAreaView
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.black,
              marginTop: 50,
            }}
          >
            <TouchableOpacity onPress={onRetakePhotoPress}>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 20,
                  padding: theme.getSpacing(2),
                }}
              >
                {getText('retake')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onSavePhotoPress}>
              <Text
                style={{
                  color: '#fff',
                  fontSize: 20,
                  padding: theme.getSpacing(2),
                }}
              >
                {getText('use-photo')}
              </Text>
            </TouchableOpacity>
          </SafeAreaView>
        </View>
      </ImageBackground>
    </View>
  );
};

interface CameraPreviewProps {
  photo: CameraCapturedPicture;
  onRetakePhotoPress: (event: GestureResponderEvent) => void;
  onSavePhotoPress: (event: GestureResponderEvent) => void;
}
