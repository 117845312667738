import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import { logError } from 'assets/logging/logger';
import Constants from 'expo-constants';
import unifiedCommsService from '../api/unified-comms-service';
import { useUserState } from '../store/user-store';

export const initPushNotificationToken = () => {
  // push notifications are only applicable to actual devices, eg: no web or device emulators
  if (Platform.OS === 'web' || !Device.isDevice) {
    return;
  }
  const user = useUserState.getState().user;
  registerForPushNotificationsAsync()
    .then(async (token) => {
      if (!user || !token) {
        logError(new Error('User id or push token is undefined'));
        return;
      }
      // Leaving this here for local development for the time being
      // eslint-disable-next-line no-console
      console.log(
        `Push notification token "${token}", will be undefined if not on real iPhone or Android device`,
      );
      await unifiedCommsService.registerPushToken(user.id, token);
    })
    .catch((error: Error) => {
      logError(error);
    });
};

async function registerForPushNotificationsAsync() {
  let token;
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    logError(new Error('Failed to get push token for push notification!'));
    return;
  }
  token = (
    await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    })
  ).data;

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}
