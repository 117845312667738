import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  APP_CONFIG_API_BASE_URL,
  APP_ENV,
  DOMAIN,
  SCHEME,
} from '../common/constants';
import { DeepLinkingConfig } from 'assets/core/deep-linking';

// Cameron's config
const DeepLinkingConfigByEnv: Record<
  'development' | 'stage' | 'production',
  DeepLinkingConfig
> = {
  stage: {
    scheme: SCHEME,
    enableSmartBanner: false,
    android: {
      fallbackUrl:
        'https://play.google.com/apps/internaltest/4701542621289095766',
      package: 'com.dp.mobilepatientqe',
    },
    ios: {
      appId: '6449297884',
      fallbackUrl:
        'https://apps.apple.com/us/app/lumistry-staging/id6449297884',
      appArgument: 'https://stage-mobile.dev.lumistry.com/',
    },
  },
  production: {
    scheme: SCHEME,
    enableSmartBanner: false,
    android: {
      fallbackUrl:
        'https://play.google.com/apps/internaltest/4701318999354359901',
      package: 'com.dp.mobilepatient',
    },
    ios: {
      appId: '1639140500',
      fallbackUrl:
        'https://apps.apple.com/us/app/lumistry-production/id1639140500',
      appArgument: 'https://mobile.lumistry.com/',
    },
  },
  development: {
    scheme: SCHEME,
    enableSmartBanner: false,
    android: {
      fallbackUrl:
        'https://play.google.com/apps/internaltest/4700084026162101451',
      package: 'com.dp.mobilepatientdev',
    },
    ios: {
      appId: '6448307308',
      fallbackUrl:
        'https://apps.apple.com/us/app/lumistry-development/id6448307308',
      appArgument: DOMAIN,
    },
  },
};

export interface IAppConfigService {
  getDeepLinkingConfig: () => Promise<DeepLinkingConfig>;
}

export class AppConfigService
  extends BaseApiService
  implements IAppConfigService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  private getConfig = (): DeepLinkingConfig => {
    if (APP_ENV === 'stage') return DeepLinkingConfigByEnv['stage'];
    else if (APP_ENV === 'production')
      return DeepLinkingConfigByEnv['production'];
    else return DeepLinkingConfigByEnv['development'];
  };

  async getDeepLinkingConfig(): Promise<DeepLinkingConfig> {
    return Promise.resolve(this.getConfig());
  }
}

export default new AppConfigService(APP_CONFIG_API_BASE_URL, undefined, true);
