import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { getSpacing } from 'assets/theme';
import {
  OrderMedicationsHandler,
  OrderMedicationsProps,
} from './OrderMedicationsAlertProps';
import { Alert } from 'assets/components/alert';
import { OrderMedicationsTestID } from './OrderMedicationsTestID';
import { getText } from 'assets/localization/localization';

export const OrderMedicationsModal = forwardRef<
  OrderMedicationsHandler,
  OrderMedicationsProps
>(
  (
    { disabled = false }: OrderMedicationsProps,
    ref: ForwardedRef<OrderMedicationsHandler>,
  ) => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnSave = () => {
      handleDismissModal();
    };

    return (
      <Modal
        size="sm"
        title={getText('select-one-item')}
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          testID: OrderMedicationsTestID.cancelModalButton,
          logger: { id: OrderMedicationsTestID.cancelModalButton },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          testID: OrderMedicationsTestID.okModalButton,
          logger: { id: OrderMedicationsTestID.okModalButton },
        }}
      >
        <View>
          <Alert
            title={getText('choose-from-the-list')}
            intent="warning"
            style={{ marginBottom: getSpacing(2) }}
          />
        </View>
      </Modal>
    );
  },
);
