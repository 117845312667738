import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const ChevronsDownIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        stroke="#516874"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.5 6.5 6 9l2.5-2.5M3.5 3 6 5.5 8.5 3"
      />
    </Svg>
  );
};
