import { PlatformType } from '../../types';

export interface TypeaheadBaseItem {
  text: string;
  value: string;
}

export const customValuePrefix = 'custom:';

export interface TypeaheadBaseProps<T extends TypeaheadBaseItem | string> {
  /**
   * Array of options.
   */
  options?: Array<T>;
  /**
   * Array of options.
   */
  asyncOptions?: (search: string) => Promise<Array<T> | undefined>;
  /**
   * Array of initial selected options.
   */
  defaultValue?: Array<T>;
  /**
   * Array of initial selected options.
   */
  emptyValue?: T;
  /**
   * Display hint message if it has value
   */
  hintMessage?: string;
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Callback fired when the input value changes.
   *
   * @param {T[]} value The new value of the component.
   */
  onInputChange?: (value: any) => void;
  /**
   * If `true`, `value` must be an array and the menu will support multiple
selections.
   * @default false
   */
  multiple?: boolean;
  /**
   * Used to determine the string value to render for a given option.
   *
   * If used in free solo mode, it must accept both the type of the options
and a string.
   *
   * @param {T} option
   * @returns {string}
   * @default (option) => option.text ?? option
   */
  getOptionText?: (option: T) => string;
  /**
   * Used to determine the string value for a given option.
   *
   * If used in free solo mode, it must accept both the type of the options
and a string.
   *
   * @param {T} option
   * @returns {string}
   * @default (option) => option.value ?? option
   */
  getOptionValue?: (option: T) => string;
  /**
   * Callback fired when the user want to close manually the component.
   *
   */
  onClose?: () => void;
  /**
   * Callback fired when the user presses the return key of the keyboard
   *
   */
  onReturnKeyPressed?: () => void;

  /**
   * Prefix applied to the value of the default option provided when there are no suggested results.
   * i.e. 'custom:' would yield an entry for { text: 'your text', value: 'custom:your text'}
   *
   */
  useCustomNoOptionsValuePrefix?: boolean;
}

export interface TypeaheadProps<T extends TypeaheadBaseItem | string>
  extends TypeaheadBaseProps<T> {
  /**
   * Environment of the running platform.
   */
  platform?: PlatformType;
  /**
   * Label of the component.
   */
  label?: string;
  /**
   * Callback fired when the value changes.
   *
   * @param {T[]} value The new value of the component.
   */
  onChange?: (value: T[]) => void;
  /*
   * Placeholder text
   */
  placeholder?: string;
  /*
   *
   */
  disableNoOptionHandler?: boolean;
}
