import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Platform } from 'react-native';
import {
  CancelAppointmentBottomSheet,
  CancelAppointmentBottomSheetProps,
} from './CancelAppointmentBottomSheet';
import {
  CancelAppointmentModal,
  CancelAppointmentModalProps,
} from './CancelAppointmentModal';

export const CancelAppointmentDialog: ForwardRefRenderFunction<
  CancelAppointmentHandler,
  CancelAppointmentProps
> = (props, ref: ForwardedRef<CancelAppointmentHandler>) => {
  const nativeRef = useRef<CancelAppointmentHandler>(null);

  useImperativeHandle(ref, () => ({
    show: () => nativeRef.current?.show(),
    hide: () => nativeRef.current?.hide(),
  }));

  const handleCancelAppointment = () => {
    props.onCancel();
  };

  return (
    <>
      {Platform.OS === 'web' ? (
        <CancelAppointmentWeb
          onConfirm={handleCancelAppointment}
          ref={nativeRef}
          {...props}
        />
      ) : (
        <CancelAppointmentMobile
          onConfirm={handleCancelAppointment}
          ref={nativeRef}
          {...props}
        />
      )}
    </>
  );
};

export const CancelAppointment = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentProps
>(CancelAppointmentDialog);

export const CancelAppointmentMobile = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentBottomSheetProps
>(CancelAppointmentBottomSheet);

export const CancelAppointmentWeb = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentModalProps
>(CancelAppointmentModal);

export interface CancelAppointmentProps {
  onCancel: () => void;
}

export interface CancelAppointmentHandler {
  show: () => void;
  hide: () => void;
}
