import create from 'zustand';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

import { ApiError, AsyncStatus } from '../../store/types';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';

export interface AddPrescriptionState {
  status: AsyncStatus;
  error?: ApiError;
  patientRecord?: PatientRecordDto;
  locationPatientRecordId?: string;
  originated: 'onboarding' | 'medication';
  prescriptions: PrescriptionDto[];
}

export const defaultAddPrescriptionState: AddPrescriptionState = {
  status: 'idle',
  error: undefined,
  patientRecord: undefined,
  locationPatientRecordId: undefined,
  originated: 'onboarding',
  prescriptions: [],
};

export const useAddPrescriptionStore = create<AddPrescriptionState>(
  () => defaultAddPrescriptionState,
);
