import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Circle, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const MedicationTransferIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg width={size} height={20} color={color} fill="none">
      <Path
        stroke="#9CAFB9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M2.167 6.053h11.666m-11.666 0V17.5c0 .46.373.833.833.833h10c.46 0 .834-.373.834-.833V6.053m-11.667 0a.833.833 0 0 1-.833-.834V2.5c0-.46.373-.833.833-.833h11.666c.46 0 .834.373.834.833v2.72c0 .46-.373.833-.833.833M8 9.583v2.5m0 0v2.5m0-2.5H5.5m2.5 0h2.5"
      />
    </Svg>
  );
};
