import create from 'zustand';
import { MessagesState } from './types';

export const useMessagesState = create<MessagesState>(() => ({
  showModal: false,
  selectedConversation: null,
  viewedConversations: [],
  messagesGroups: [],
  selectedMessages: [],
  rawConversations: [],
  initialSubject: undefined,
  isOpenNewChatModal: false,
  disabled: false,
  count: {},
  socket: undefined,
  typingMember: null,
}));
