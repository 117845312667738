export const PatientInfoTestIDs = {
  firstNameInput: 'patient-info-first-name-input',
  lastNameInput: 'patient-info-last-name-input',
  dateOfBirthInput: 'patient-info-first-name-input',
  emailAddress: 'patient-info-email-address',
  phoneNumberMobileInput: 'patient-info-phone-number-mobile-input',
  registerButton: 'patient-info-login-button',
};

export const MedicalInfoTestIDs = {
  allergyInput: 'medical-info-allergy-input',
  medicalConditionsInput: 'medical-info-medical-conditions-input',
  otcMedicationsInput: 'medical-info-otc-medications-input',
};

export const PatientPreferencesTestIDs = {
  language: 'patient-pref-language-input',
  prescriptionCommunication: 'patient-pref-prescription-communication',
  otherCommunication: 'patient-pref-other-communication',
  sameTime: 'patient-pref-same-time',
  additionalInfo: 'patient-pref-additional-info',
};

export const PatientFeedbackSuccessTestIDs = {
  submit: 'patient-feedback-submit',
};

export const PatientInsuranceTestIDS = {};
