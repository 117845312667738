import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { UpdatePatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

import { Form, ScreenContainer } from 'assets/layout';
import { makeStyles } from 'assets/theme';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import { getText } from 'assets/localization/localization';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';

import { RefillStackParamList } from './RefillNavigation';
import { RefillTestIDs } from './RefillTestIDs';
import { useForm } from 'react-hook-form';
import { useRefillMedicationsStore } from './refill-store';
import patientService from '../../api/patient-service';

export const RefillDetails: FunctionComponent<
  PropsWithChildren<RefillDetailsProps>
> = ({ navigation }) => {
  const { selectedPatientRecord: patientRecord, updateMedicationsData } =
    useRefillMedicationsStore();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({
    defaultValues: {
      prefers_med_sync: patientRecord?.prefers_med_sync,
      prefers_easy_open_bottle_caps:
        patientRecord?.prefers_easy_open_bottle_caps,
    },
  });

  useEffect(() => {
    if (!patientRecord) return;

    if (patientRecord.prefers_med_sync !== null) {
      form.setValue('prefers_med_sync', patientRecord.prefers_med_sync);
    }

    if (patientRecord.prefers_easy_open_bottle_caps !== null) {
      form.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }
  }, [patientRecord]);

  const handleOnPressDone = async () => {
    if (!patientRecord || !updateMedicationsData) return;

    const updatePatientRecord: UpdatePatientRecordDto = {
      gender: patientRecord.gender,
      allergies: patientRecord.allergies,
      medical_conditions: patientRecord.medical_conditions,
      prefers_med_sync: form.getValues().prefers_med_sync === true,
      prefers_easy_open_bottle_caps:
        form.getValues().prefers_easy_open_bottle_caps === true,
    };

    const updatedPR = await patientService.updatePatientRecord(
      patientRecord.id,
      updatePatientRecord,
    );

    updateMedicationsData({
      selectedPatientRecord: {
        ...updatedPR,
      },
    });

    setIsLoading(false);

    navigation.navigate('refill-choose-prescription-method');
  };

  const isRequiredBoolean = (value: string | null | undefined) => {
    if (value === '' || value === undefined || value === null)
      return getText('field-required');

    return true;
  };

  return (
    <ScreenContainer>
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText('prescription-ready-each-month')}
              rules={{
                validate: {
                  isRequiredBoolean,
                },
              }}
              name="prefers_med_sync"
              values={[
                {
                  text: getText('yes'),
                  value: true,
                },
                {
                  text: getText('no'),
                  value: false,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText('easy-open-bottle-caps-confirm')}
              rules={{
                validate: {
                  isRequiredBoolean,
                },
              }}
              name="prefers_easy_open_bottle_caps"
              values={[
                {
                  text: getText('yes-easy-caps'),
                  value: true,
                },
                {
                  text: getText('no-safety-caps'),
                  value: false,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.paragraph}>
              {getText('easy-open-bottle-caps-description')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              onPress={form.handleSubmit(handleOnPressDone)}
              disabled={isLoading}
              testID={RefillTestIDs.refillOrderDetailsSubmit}
              logger={{ id: RefillTestIDs.refillOrderDetailsSubmit }}
            >
              {getText('next')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

export type RefillDetailsProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-details'
>;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  paragraph: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
}));
