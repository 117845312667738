import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const NewMessageIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.667 3.333H2.834A1.667 1.667 0 0 0 1.167 5v11.666a1.666 1.666 0 0 0 1.667 1.667H14.5a1.666 1.666 0 0 0 1.667-1.666v-5.834m-1.25-8.75a1.768 1.768 0 0 1 2.5 2.5L9.5 12.5l-3.333.833L7 10l7.917-7.917Z"
      />
    </Svg>
  );
};
