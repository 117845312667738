import React, {
  ForwardedRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { View } from 'react-native';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { CloseIcon } from 'assets/icons';
import {
  CancelAppointmentHandler,
  CancelAppointmentProps,
} from './CancelAppointment';
import { useAppointmentDetailsState } from '../appointment-details-store';
import { TextInput } from 'react-native-gesture-handler';

export const CancelAppointmentBottomSheet: ForwardRefRenderFunction<
  CancelAppointmentHandler,
  CancelAppointmentBottomSheetProps
> = ({ onConfirm }, ref: ForwardedRef<CancelAppointmentHandler>) => {
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = useRef<BottomSheetModal>(null);
  const { cancelAppointmentStatus } = useAppointmentDetailsState();

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <View>
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={'30%'}
        onDismiss={handleBottomSheetDismiss}
        hideHandle={true}
        contentContainerStyle={{ flex: 1 }}
        headerContent={
          <View
            style={{
              position: 'relative',
              marginBottom: theme.getSpacing(1),
            }}
          >
            <View style={styles.sheetTitleContainer}>
              <Text style={styles.sheetTitle}>
                {getText('cancel-appointment')}
              </Text>
            </View>
            <View style={styles.sheetIconContainer}>
              <IconButton
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={24}
                color={theme.palette.gray[500]}
                logger={{ id: 'cancel-appointment-bottom-sheet-close' }}
              />
            </View>
          </View>
        }
      >
        {/*
          This TextInput is a fix for the bottom sheet not opening correctly.
          This is a bug in the bottom sheet library and it looks like adding an invisible TextInput component fixes the issue. No idea why.
          Read more - https://github.com/gorhom/react-native-bottom-sheet/issues/987
        */}
        <TextInput style={{ display: 'none' }} />
        <Text>{getText('cancel-appointment-confirmation')}</Text>
        <View style={styles.sheetButtons}>
          <Button
            hierarchy="secondary-gray"
            size="medium"
            onPress={handleBottomSheetDismiss}
            logger={{ id: `cancel-appointment-keep` }}
            style={styles.sheetButton}
          >
            {getText('keep')}
          </Button>
          <Button
            hierarchy="destructive-secondary"
            size="medium"
            onPress={handleConfirm}
            logger={{ id: `cancel-appointment-cancel` }}
            style={styles.sheetButton}
            loading={cancelAppointmentStatus === 'loading'}
          >
            {getText('cancel-appointment')}
          </Button>
        </View>
      </BottomSheet>
    </View>
  );
};

export interface CancelAppointmentBottomSheetProps
  extends CancelAppointmentProps {
  onConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
  sheetIconContainer: {
    position: 'absolute',
    right: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  sheetButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 'auto',
    marginBottom: theme.getSpacing(3),
  },
  sheetButton: {
    width: '48%',
  },
}));
