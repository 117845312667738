import { getText } from 'assets/localization/localization';
import React from 'react';
import { FunctionComponent } from 'react';
import { Modal } from 'assets/components/modal';
import { ChatBoxActions } from './ChatBoxActions';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export const ChatBoxActionsModal: FunctionComponent<
  ChatBoxActionModalProps
> = ({ show, setShow, conversation, onClose }) => {
  return (
    <Modal
      size="sm"
      show={show}
      okButtonProps={{
        onPress: () => setShow(false),
        logger: { id: 'ok-button-modal-chat-box-actions' },
        text: getText('done'),
      }}
      dismissButtonProps={{
        onPress: () => setShow(false),
        logger: { id: 'dismiss-button-modal-chat-box-actions' },
      }}
      isScrollable
    >
      <ChatBoxActions conversation={conversation} onClose={onClose} />
    </Modal>
  );
};

export interface ChatBoxActionModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  conversation: DirectMessagePatientDto;
  onClose: () => void;
}
