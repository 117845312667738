import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { AppleIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { notImplementedAlert } from 'assets/utils/alert';

export const AppleSignInButton: React.FunctionComponent<
  AppleSignInButtonProps
> = ({ text = getText('apple-signup'), style = {} }) => {
  const styles = useStyles();
  return (
    <Button
      icon={AppleIcon}
      disabled={!Request}
      hierarchy={'secondary-gray'}
      logger={{ id: 'apple-login-button' }}
      style={[styles.button, style]}
      onPress={notImplementedAlert}
    >
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
}));

interface AppleSignInButtonProps {
  text?: string;
  style?: StyleProp<ViewStyle>;
}
