import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { usePatientRecordState } from './patient-store';
import { useAppStateStore } from '../../../store/app-store';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native';
import { resetRecordUnderCareState } from './patient-actions';

export const AddPatientUnderCareRequest: FunctionComponent<
  PropsWithChildren<AddPatientUnderCareRequestProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const profileNav = useNavigation<NavigationProp<ParamListBase>>();
  const { status, error, data } = usePatientRecordState();
  const { pharmacyName } = useAppStateStore();

  const onPress = () => {
    resetRecordUnderCareState();
    navigation.navigate('add-patient-under-care-info');
  };

  const handleGoHome = () => {
    resetRecordUnderCareState();
    profileNav.navigate('app', {
      screen: 'home',
    });
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
        }}
      >
        <Text
          style={{
            marginBottom: theme.getSpacing(1),
            fontSize: 16,
            lineHeight: 24,
            fontWeight: '600',
          }}
        >
          {getText('add-patient-request-submit')}
        </Text>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form.Alert
          title={getText('request-sent', {
            firstName: data?.first_name,
            lastName: data?.last_name,
          })}
          intent="success"
          visible={true}
        />
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 14,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            marginStart: theme.getSpacing(0.5),
          }}
        >
          {getText('person-has-an-account', { pharmacyName: pharmacyName })}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 14,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            marginStart: theme.getSpacing(0.5),
          }}
        >
          {getText('approval-needed')}
        </Text>
        <View style={{ margin: theme.getSpacing(1) }}>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={onPress}
              hierarchy="primary"
              logger={{
                id: 'add-patient-under-care-add-another-person-button',
              }}
              loading={status === 'loading'}
            >
              {getText('add-another-person')}
            </Button>
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={handleGoHome}
              hierarchy="secondary"
              logger={{ id: 'add-patient-under-care-go-home-button' }}
              loading={status === 'loading'}
            >
              {getText('done')}
            </Button>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

type AddPatientUnderCareRequestProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'add-patient-under-care-confirmation'
>;
