import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const CalendarCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 37 36"
      fill="none"
      color={color}
      testID={testID}
    >
      <Rect x="0.5" width="36" height="36" rx="18" fill="#9CAFB9" />
      <Path
        d="M21.8333 9.66699V13.0003M15.1667 9.66699V13.0003M11 16.3337H26M12.6667 11.3337H24.3333C25.2538 11.3337 26 12.0799 26 13.0003V24.667C26 25.5875 25.2538 26.3337 24.3333 26.3337H12.6667C11.7462 26.3337 11 25.5875 11 24.667V13.0003C11 12.0799 11.7462 11.3337 12.6667 11.3337Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
