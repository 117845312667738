import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const MessagesNotificationIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Circle cx={24} cy={24} r={24} fill="#EAF1F4" stroke="transparent" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M33 26a2 2 0 0 1-2 2H19l-4 4V16a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10Z"
      />
    </Svg>
  );
};
