import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { getSpacing, makeStyles } from 'assets/theme';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import BaseRadioButton from 'assets/components/radio-button-group/BaseRadioButton';

export const NewMessageRadioButton: FunctionComponent<
  PropsWithChildren<NewMessageRadioButtonProps>
> = ({ selected, text, value, onPress }) => {
  const styles = useStyles();
  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress(value)}>
      <View style={styles.radioButtonContainer}>
        <BaseRadioButton selected={selected} disabled={false} />
      </View>
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{text}</Text>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: getSpacing(2),
  },
  radioButtonContainer: {
    marginRight: getSpacing(2),
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  labelContainer: {
    flex: 1,
  },
}));

interface NewMessageRadioButtonProps {
  selected: boolean;
  text: string;
  value: string;
  onPress: (value: string) => void;
}
