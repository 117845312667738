import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';
export const AppleIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 19 20"
      color={color}
      testID={testID}
    >
      <Path
        d="M17.7026 6.82173C17.6071 6.87744 15.3338 8.0531 15.3338 10.6598C15.441 13.6326 18.2026 14.6752 18.25 14.6752C18.2026 14.7309 17.8331 16.0954 16.7383 17.5257C15.8696 18.7578 14.9053 20 13.441 20C12.0481 20 11.5481 19.1788 9.94097 19.1788C8.21504 19.1788 7.72668 20 6.40525 20C4.94096 20 3.90524 18.6912 2.98909 17.4707C1.79887 15.8732 0.787222 13.3664 0.751508 10.9593C0.72744 9.68384 0.989862 8.43004 1.65601 7.36507C2.59623 5.87831 4.27481 4.86905 6.10789 4.83577C7.51239 4.79164 8.7624 5.73434 9.61954 5.73434C10.441 5.73434 11.9767 4.83577 13.7143 4.83577C14.4643 4.83649 16.4643 5.04703 17.7026 6.82173ZM9.50075 4.5811C9.25075 3.41629 9.94097 2.25148 10.5838 1.50846C11.4053 0.609897 12.7026 0 13.8214 0C13.8928 1.16481 13.4402 2.30719 12.6312 3.1392C11.9053 4.03777 10.6553 4.71422 9.50075 4.5811Z"
        fill="#101828"
      />
    </Svg>
  );
};
