import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'react-native-paper';
import { TouchableOpacity, View, Image } from 'react-native';
import { Text } from 'assets/components/text';
import {
  MedicalInfoForm,
  PatientUnderCareForm,
  ResourceItem,
} from '../../patient-actions';
import { UseFormReturn, useForm } from 'react-hook-form';
import { PatientUnderCareEditDetailsEnum } from '../../patient-store';
import { makeStyles } from 'assets/theme';
import {
  findAllergiesById,
  findConditionsById,
} from '../../../../../components/medical-info/medical-info-actions';

export const MedicalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    record?: RecordUnderCareDto;
    onEditPress: (
      editDetail: PatientUnderCareEditDetailsEnum,
      methods: UseFormReturn<MedicalInfoForm>,
    ) => void;
    form?: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ onEditPress, patientRecord, record, form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<MedicalInfoForm>({});
  const [medicalConditions, setMedicalConditions] = useState<ResourceItem[]>();
  const [allergies, setAllergies] = useState<ResourceItem[]>();

  useEffect(() => {
    void (async () => {
      const allAllergies = await findAllergiesById(patientRecord.allergies);
      setAllergies(allAllergies);
    })();
  }, [patientRecord.allergies]);

  useEffect(() => {
    void (async () => {
      const allMedicalConditions = await findConditionsById(
        patientRecord.medical_conditions,
      );
      setMedicalConditions(allMedicalConditions);
    })();
  }, [patientRecord.medical_conditions]);

  return (
    <View>
      <View style={styles.requestTextContainer}>
        <Text style={styles.title}>
          {getText('medical-info').toUpperCase()}
        </Text>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={() =>
            onEditPress(PatientUnderCareEditDetailsEnum.MedicalInfo, methods)
          }
        >
          <Text style={[styles.pressableTextRevoke, styles.leftSpacing]}>
            {getText('edit')}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('allergies-colon')}</Text>
        <Text style={styles.boldSubtitle}>
          {allergies && allergies.length > 0
            ? allergies.map((v) => v.text).join(', ')
            : getText('none')}
        </Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('medical-conditions')}</Text>

        <Text style={styles.boldSubtitle}>
          {medicalConditions && medicalConditions.length > 0
            ? medicalConditions.map((v) => v.text).join(', ')
            : getText('none')}
        </Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('easy-open-bottle-caps')}</Text>
        <Text style={styles.boldSubtitle}>
          {patientRecord.prefers_easy_open_bottle_caps
            ? getText('yes')
            : getText('no')}
        </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    ...theme.fonts.medium,
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));
