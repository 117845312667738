import React from 'react';
import { DocumentView } from '../document-view';
import { VUCA_API_BASE_URL } from '../../common/constants';

export const MedicationGuideDetails = ({ medication }: any) => {
  return (
    medication &&
    medication.medication_guides && (
      <DocumentView
        src={`${VUCA_API_BASE_URL}${medication.medication_guides_pdf_download_uri}`}
        streaming={true}
        style={{ flex: 1 }}
      />
    )
  );
};
