import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import theme, { makeStyles } from 'assets/theme';

export const MedicationsEmptyStateSmall: FunctionComponent<
  MedicationsEmptyStateSmallProps
> = ({ firstParagraph, secondParagraph, buttonText, onPress }) => {
  const styles = useStyles();

  return (
    <View>
      <View style={{ marginTop: theme.getSpacing(1) }}>
        <Text style={styles.description}>{firstParagraph}</Text>
        <Text style={styles.description}>{secondParagraph}</Text>
      </View>
      <View style={styles.screenContainer}>
        <Button
          hierarchy="secondary"
          labelStyle={styles.buttonText}
          onPress={onPress}
          testID={'medications-empty-state-small-button'}
          logger={{ id: 'medications-empty-state-small-button' }}
        >
          {buttonText}
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.getSpacing(2),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.gray[900],
    marginVertical: theme.getSpacing(2),
  },
  description: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'left',
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
  },
  buttonText: {
    ...theme.fonts.regular,
    fontWeight: '500',
    fontSize: 14,
    height: 20,
  },
}));

interface MedicationsEmptyStateSmallProps {
  firstParagraph: string;
  secondParagraph: string;
  buttonText: string;
  onPress: () => {};
}
