import React, { FunctionComponent, PropsWithChildren } from 'react';
import { PlatformType } from '../types';
import {
  Platform,
  GestureResponderEvent,
  TouchableOpacity,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { Text } from '../text';
import { makeStyles, useTheme } from '../../theme';

/**
 * Display CTA button styled for native header. Displays text for iOS and icon for Android/Web
 */
export const NativeHeaderActionButton: FunctionComponent<
  PropsWithChildren<NativeHeaderActionButtonProps>
> = ({
  platform = Platform.OS,
  icon,
  label,
  onPress,
  size = 24,
  testID,
  accessibilityLabel = 'Action Button',
}) => {
  const theme = useTheme();
  const styles = useStyles();
  if (platform === 'ios') {
    return (
      <TouchableOpacity
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
        style={styles.iosButton}
        testID={testID}
      >
        <Text style={styles.iosTextButton}>{label}</Text>
      </TouchableOpacity>
    );
  }
  if (platform === 'android') {
    return (
      <TouchableOpacity
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
        style={styles.androidButton}
        testID={testID}
      >
        <Text style={styles.androidTextButton}>{label}</Text>
      </TouchableOpacity>
    );
  }
  return (
    <IconButton
      accessibilityLabel={accessibilityLabel}
      icon={icon as any}
      color={theme.palette.white}
      onPress={onPress}
      size={size}
      testID={testID}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  iosTextButton: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
  },
  iosButton: { height: 48, justifyContent: 'center' },
  androidTextButton: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    textTransform: 'uppercase',
  },
  androidButton: {
    height: 48,
    justifyContent: 'center',
    marginTop: theme.getSpacing(0.5),
  },
}));

export interface NativeHeaderActionButtonProps {
  platform?: PlatformType;
  /**
   * Icon displayed if not ios
   */
  icon: FunctionComponent;
  /**
   * Label displayed if ios
   */
  label: string;
  onPress: (event: GestureResponderEvent) => void;
  size?: number;
  testID?: string;
  accessibilityLabel?: string;
}
