import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useRegisterState = create<RegisterState>((set) => ({
  status: 'idle',
  data: undefined,
  updateData: (data: RegisterState) => {
    set({ ...data });
  },
}));

interface RegisterState {
  status: AsyncStatus;
  error?: ApiError;
}
