import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import {
  AccountNotFoundProps,
  AuthStackNavigationProp,
} from '../../navigation/AuthNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';

export const AccountNotFound: FunctionComponent<
  PropsWithChildren<AccountNotFoundProps>
> = ({ route }) => {
  const styles = useStyles();
  const navigation = useNavigation<AuthStackNavigationProp>();
  const isSSO = route.params?.isSSO?.toString() === 'true';

  const onPress = async () => {
    navigation.navigate('intro');
  };

  return (
    <ScreenContainer>
      <Text style={styles.heading}>{getText('account-not-found-heading')}</Text>
      <Text style={styles.sudHeading}>
        {isSSO
          ? getText('account-not-found-subheading-sso')
          : getText('account-not-found-subheading')}
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          onPress={onPress}
          hierarchy="tertiary"
          logger={{ id: 'account-not-found-back-to-login-button' }}
        >
          {getText('return-to-login')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.gray[900],
    fontSize: 25,
    marginTop: '40%',
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  sudHeading: {
    color: theme.palette.gray[600],
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.getSpacing(2),
  },
}));
