import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { Menu } from 'react-native-material-menu';
import { IconButton } from 'assets/components/icon-button';
import { BellIcon } from 'assets/icons';
import { NotificationList } from 'assets/components/notifications';
import { ScrollView } from 'react-native-gesture-handler';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { logError } from 'assets/logging/logger';

export const NotificationsListWeb: FunctionComponent<
  PropsWithChildren<NotificationsListWebProps>
> = ({ notifications }) => {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const styles = useStyles();
  const { refreshNotifications } = useAppStateStore();

  const handleOpenMenu = async () => {
    try {
      await refreshNotifications();
    } catch {
      logError(new Error('Failed to get notifications'));
    }
    setShowMenu(true);
  };

  return (
    <Menu
      visible={showMenu}
      onRequestClose={() => setShowMenu(false)}
      style={styles.menu}
      anchor={
        <IconButton
          icon={BellIcon}
          logger={{ id: 'notifications-list-web-icon-button' }}
          onPress={handleOpenMenu}
          color={theme.palette.white}
        />
      }
    >
      <ScrollView style={styles.list}>
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} />
        ) : (
          <NotificationsEmptyState />
        )}
      </ScrollView>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 65,
    maxHeight: 700,
    minHeight: 200,
    minWidth: 400,
    borderRadius: theme.roundness,
    overflow: 'hidden',
    boxShadow:
      ' 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  },
  list: {
    paddingVertical: getSpacing(2),
    paddingHorizontal: getSpacing(2),
  },
}));

export interface NotificationsListWebProps {
  notifications: UserNotificationDto[];
}
