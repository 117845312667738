import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import {
  ImageBackground,
  Platform,
  View,
  useWindowDimensions,
} from 'react-native';
import { getText } from 'assets/localization/localization';
import { useNavigation } from '@react-navigation/native';
import { AddMedicationsIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { Surface } from 'react-native-paper';
import { Button } from 'assets/components/button';
import { useForm } from 'assets/form';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { AddMeds } from '../../register/register-actions';
import { PropsWithChildren } from 'react';
import { notImplementedAlert } from '../../../utils';
import { useUserState } from '../../../store/user-store';
import { AccountStackNavigationProp } from '../../account/AccountNavigation';
import patientService from '../../../api/patient-service';
import { InitLocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { StackHeaderProps } from '@react-navigation/stack';
import { useAddPrescriptionStore } from '../../find-prescription-flow/add-prescription-store';
import refillService from '../../refill/refill-service';
import { resetAddPrescriptionState } from '../../find-prescription-flow/add-prescription-actions';

export const AddMedications: FunctionComponent<AddMedicationsProps> = ({
  navigation,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const nav = useNavigation<AccountStackNavigationProp>();
  const methods = useForm<AddMeds>({
    defaultValues: {
      option: 'prescriptions-at-file',
    },
  });
  const { watch } = methods;
  const [option] = watch(['option']);

  const { width } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);

  const setFindMedsScreenAsSeen = () => {
    void updateUser({ hasSeenFindMedsScreen: true });
  };

  const initLocationPatientRecord = async () => {
    if (!user?.preferredPharmacyLocationId || !user.patientRecordId) return;

    const initLocationPatientRecordDto: InitLocationPatientRecordDto = {
      location_id: user.preferredPharmacyLocationId,
    };

    await patientService.ensureLocationPatientRecordExist(
      user.patientRecordId,
      initLocationPatientRecordDto,
    );
  };

  const handleSubmit = async () => {
    const formValues = methods.getValues();

    // user choose Skip
    if (formValues.option === 'skip') {
      setFindMedsScreenAsSeen();
      await initLocationPatientRecord();
      navigation.navigate('app', { screen: 'home' });
    }
    // user choose Transfer
    else if (formValues.option === 'request-to-transfer') {
      setFindMedsScreenAsSeen();
      notImplementedAlert();
    }
    // user choose Add medication
    else if (formValues.option === 'prescriptions-at-file') {
      if (user && user.preferredPharmacyLocationId) {
        const isLocationIntegrated = await refillService.isLocationIntegrated(
          user.preferredPharmacyLocationId,
        );

        if (isLocationIntegrated) {
          navigation.navigate('automatic-prescription-flow');
        } else {
          navigation.navigate('manual-prescription-flow');
        }
      }
    } else {
      alert('Option not implemented');
    }
  };

  return (
    <>
      <ScreenContainer style={{ flex: 1 }}>
        <View style={styles.container}>
          <Text style={styles.textTitle}>{getText('add-medications')}</Text>
        </View>
        <View style={styles.cardContainer}>
          <View>
            <InfoCard width={buttonWidth} height={104}>
              <View style={styles.cardView}>
                <View>
                  <AddMedicationsIcon
                    size={80}
                    color={theme.palette.warning[50]}
                  />
                </View>
                <View style={styles.textContainer}>
                  <View style={{ marginTop: theme.getSpacing(2) }}>
                    <Text style={[styles.text]}>
                      {getText('add-meds-text')}
                    </Text>
                  </View>
                </View>
              </View>
            </InfoCard>
          </View>
          <Form methods={methods}>
            <Form.Row>
              <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
                <RadioButtonGroupField
                  name="option"
                  testID={''}
                  values={[
                    {
                      text: getText('prescriptions-at-file'),
                      value: 'prescriptions-at-file',
                    },
                    {
                      text: getText('request-to-transfer'),
                      value: 'request-to-transfer',
                    },
                    {
                      text: getText('skip-this-step'),
                      value: 'skip',
                      description: getText('do-this-medications-tab'),
                    },
                  ]}
                />
              </Form.Column>
            </Form.Row>
          </Form>
        </View>
        <View>
          <Button
            onPress={handleSubmit}
            hierarchy="primary"
            logger={{ id: 'add-person-submit-button' }}
          >
            {getText('next')}
          </Button>
        </View>
      </ScreenContainer>
    </>
  );
};

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
    width?: string | number;
    height?: string | number;
  }>
> = ({ uri, width = 100, height = 100, children }) => {
  const theme = useTheme();
  const styles = useStyles();
  const innerContent = uri ? (
    <ImageBackground
      source={{ uri: uri }}
      resizeMode="cover"
      style={{ height: height }}
    />
  ) : (
    children
  );

  return (
    <Surface
      style={[
        styles.button,
        {
          elevation: 2,
          borderRadius: theme.roundness,
          height: height,
          overflow: 'hidden',
          backgroundColor: theme.palette.warning[50],
          minHeight: height,
        },
      ]}
    >
      {innerContent}
    </Surface>
  );
};

export type AddMedicationsProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  textTitle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
  },
  cardContainer: {
    marginTop: theme.getSpacing(1),
    alignItems: 'stretch',
    flex: 1,
  },
  cardView: {
    justifyContent: 'center',
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
    marginTop: theme.getSpacing(1),
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  text: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    fontSize: 14,
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.getSpacing(2),
    flexWrap: 'wrap',
    flex: 1,
  },
}));
