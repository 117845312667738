import {
  AuthorType,
  ClientAttachmentDto,
  DirectMessageDto,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { SubjectOptionsEnum } from './MessageProps';
import { IMessage } from 'react-native-gifted-chat';
import { Socket } from 'socket.io-client';

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export interface EmittedMessage {
  id: string;
  location_patient_id: string;
  conversation_id: string;
  author_id: string;
  author_type: AuthorType;
  content: string;
  created_at: Date;
  updated_at: Date;
}

interface IDBAttachment extends ClientAttachmentDto {
  id: string;
  updated_at: string;
  created_at: string;
}

export interface MessagesState {
  showModal: boolean;
  selectedConversation: string | null;
  viewedConversations: string[];
  messagesGroups: DirectMessagePatientDto[];
  selectedMessages: IMessage[];
  rawConversations: DirectMessagePatientDto[];
  isOpenNewChatModal: boolean;
  initialSubject: SubjectOptionsEnum | undefined;
  disabled: boolean;
  count: {
    unread?: number;
  };
  socket?: Socket;
  typingMember: UserTyping | null;
}
export interface DirectMessageExtended extends DirectMessageDto {
  attachments: IDBAttachment[];
}

export interface EmittedUpdatedUserStatus {
  patient_viewed_all_messages: boolean;
  pharmacy_viewed_all_messages: boolean;
  conversation_id: string;
}

export interface UserTyping {
  id: string;
  name: string;
  conversation_id: string;
  author_type: string;
  location_id: string;
  location_patient_id: string;
}
