import {
  AppointmentGroupDto,
  AppointmentTypeDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';

export const useAppointmentTypesState = create<AppointmentTypesState>(() => ({
  status: 'idle',
  appointmentTypes: [],
  appointmentGroups: [],
}));

interface AppointmentTypesState {
  status: AsyncStatus;
  error?: ApiError;
  appointmentTypes: AppointmentTypeDto[];
  appointmentGroups: AppointmentGroupDto[];
}
