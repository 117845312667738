import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  ServiceApi as AppointmentServiceClient,
  ServiceBookingsApi as BookingsServiceClient,
  ServiceTypeApi as AppointmentTypeServiceClient,
  AppointmentTypeDto,
  AppointmentTypeSlotDto,
  CreateBookingDto,
  BookingPageDto,
  BookingDto,
  UpdateBookingDto,
  SortOrder,
  BookingStatus,
  AppointmentGroupLocationDto,
  AppointmentGroupDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { APPOINTMENT_API_BASE_URL } from '../common/constants';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IAppointmentService {
  findAppointmentTypes(
    pharmacyId: string,
    locationId: string,
  ): Promise<AppointmentTypeDto[]>;

  getAvailableAppointmentSlots(
    pharmacyId: string,
    locationId: string,
    appointmentTypeId: string,
    startDate: string,
    endDate: string,
  ): Promise<AppointmentTypeSlotDto[]>;

  createBooking(
    pharmacyId: string,
    locationId: string,
    booking: CreateBookingDto,
  ): Promise<BookingDto>;

  findBookings(
    pharmacyId: string,
    offset: number,
    limit: number,
    orderBy: SortOrder,
    filters?: FindBookingsFilters,
  ): Promise<BookingPageDto>;

  findBookingById(
    pharmacyId: string,
    locationId: string,
    id: string,
  ): Promise<BookingDto>;

  findAppointmentTypeById(
    pharmacyId: string,
    locationId: string,
    appointmentTypeId: string,
  ): Promise<AppointmentTypeDto>;

  cancelBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
  ): Promise<BookingDto>;

  findAvailableLocations(
    pharmacyId: string,
    appointmentTypeId: string,
  ): Promise<AppointmentGroupLocationDto[]>;

  findAppointmentGroups(pharmacyId: string): Promise<AppointmentGroupDto[]>;
}

export class AppointmentService
  extends BaseApiService
  implements IAppointmentService
{
  private appointmentServiceClient: AppointmentServiceClient;
  private bookingsServiceClient: BookingsServiceClient;
  private appointmentTypeServiceClient: AppointmentTypeServiceClient;

  public getAppointmentServiceUrl: string;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.appointmentServiceClient = new AppointmentServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.bookingsServiceClient = new BookingsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.appointmentTypeServiceClient = new AppointmentTypeServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.getAppointmentServiceUrl = `${baseUrl}/appointment`;
  }

  async findAppointmentTypes(
    pharmacyId: string,
    locationId: string,
  ): Promise<AppointmentTypeDto[]> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFind(
      pharmacyId,
      locationId,
    );

    return data;
  }

  async findAppointmentTypeById(
    pharmacyId: string,
    locationId: string,
    appointmentTypeId: string,
  ): Promise<AppointmentTypeDto> {
    const { data } = await this.appointmentServiceClient.appointmentTypeFindOne(
      pharmacyId,
      locationId,
      appointmentTypeId,
    );

    return data;
  }

  async getAvailableAppointmentSlots(
    pharmacyId: string,
    locationId: string,
    appointmentTypeId: string,
    startDate: string,
    endDate: string,
  ): Promise<AppointmentTypeSlotDto[]> {
    const { data } =
      await this.appointmentServiceClient.appointmentTypeGetAvailableSlots(
        pharmacyId,
        locationId,
        appointmentTypeId,
        startDate,
        endDate,
      );

    return data;
  }

  async createBooking(
    pharmacyId: string,
    locationId: string,
    booking: CreateBookingDto,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingCreate(
      pharmacyId,
      locationId,
      booking,
    );

    return data;
  }
  async findBookings(
    pharmacyId: string,
    offset: number,
    limit: number,
    orderBy: SortOrder,
    filters?: FindBookingsFilters,
  ): Promise<BookingPageDto> {
    const { data } = await this.bookingsServiceClient.bookingFindFind(
      pharmacyId,
      offset,
      limit,
      orderBy,
      filters?.locationId,
      filters?.appointmentTypeId,
      filters?.appointmentGroupId,
      filters?.patientId,
      filters?.patientRecordId,
      filters?.minStartDate,
      filters?.maxStartDate,
      filters?.minEndDate,
      filters?.maxEndDate,
      filters?.status,
      filters?.withAllCanceled,
    );

    return data;
  }

  async findBookingById(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingFindOne(
      pharmacyId,
      locationId,
      bookingId,
    );

    return data;
  }

  async updateBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
    booking: UpdateBookingDto,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingUpdate(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    return data;
  }

  async cancelBooking(
    pharmacyId: string,
    locationId: string,
    bookingId: string,
  ): Promise<BookingDto> {
    const { data } = await this.bookingsServiceClient.bookingCancel(
      pharmacyId,
      locationId,
      bookingId,
    );

    return data;
  }

  async findAvailableLocations(
    pharmacyId: string,
    appointmentGroupId: string,
  ): Promise<AppointmentGroupLocationDto[]> {
    const { data } =
      await this.appointmentTypeServiceClient.appointmentGroupFindAvailableLocations(
        pharmacyId,
        appointmentGroupId,
      );

    return data;
  }

  async findAppointmentGroups(
    pharmacyId: string,
  ): Promise<AppointmentGroupDto[]> {
    const { data } =
      await this.appointmentTypeServiceClient.appointmentGroupFind(pharmacyId);

    return data;
  }
}

export interface FindBookingsFilters {
  appointmentTypeId?: string;
  appointmentGroupId?: string;
  patientId?: string;
  patientRecordId?: string;
  minStartDate?: string;
  maxStartDate?: string;
  minEndDate?: string;
  maxEndDate?: string;
  status?: BookingStatus;
  withAllCanceled?: boolean;
  locationId?: string;
}

export default new AppointmentService(
  APPOINTMENT_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
);
