import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const NotificationsEmptyStateIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 71 80"
      fill="none"
      stroke="currentColor"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M.5 0h70v80H.5z" stroke="transparent" />
      <Path
        fill={color}
        fillRule="evenodd"
        d="M49.358 52.302H54.5l.008 4.386h-5.15v-4.386ZM20.044 35v11.21H11.5V35h8.544Zm25.65 0v8.518h-.743a4.4 4.4 0 0 0-4.056 2.691H37.15V35h8.544Zm-12.701 0v11.21H24.2V35h8.793ZM64.5 44.017v5.848h-3.419v-5.848H64.5ZM49.358 59.614h6.514c.7.556 1.114.871 2.03 1.093V64h-8.544v-4.386Z"
        clipRule="evenodd"
      />
      <Path
        fill={'#374D58'}
        stroke={'#374D58'}
        fillRule="evenodd"
        d="M6.399 60.782h34.543v6.24H4.949c-.8 0-1.449-.644-1.449-1.439V28.438c0-.794.649-1.438 1.45-1.438h48.31c.801 0 1.45.644 1.45 1.438v15.567h-2.899v-14.13H6.4v30.907ZM58.824 40.65h8.227c.797 0 1.449.648 1.449 1.438v14.62c0 .787-.656 1.438-1.45 1.438h-8.226c-.797 0-1.45-.648-1.45-1.438v-14.62c0-.792.651-1.438 1.45-1.438Zm0 14.14h8.226V42.088h-8.226V54.79Zm0-12.702Zm3.147 14.859a.722.722 0 0 1-.725-.72c0-.396.325-.718.725-.718h1.933c.4 0 .724.322.724.718 0 .398-.324.72-.724.72H61.97ZM45.29 46.88h9.445v2.876h-7.996v20.612h14.252v-9.347h2.899v10.785c0 .794-.649 1.438-1.45 1.438H45.29c-.8 0-1.45-.644-1.45-1.438V48.319c0-.794.65-1.438 1.45-1.438ZM17.03 79c-.8 0-1.45-.644-1.45-1.438 0-.794.65-1.438 1.45-1.438h8.21v-6.947h7.73v6.947h8.21c.8 0 1.45.644 1.45 1.438 0 .794-.65 1.438-1.45 1.438H17.03Zm9.176-14.142a.963.963 0 0 1-.966-.959c0-.529.433-.958.966-.958h5.798c.533 0 .966.429.966.958 0 .53-.433.96-.966.96h-5.798Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
