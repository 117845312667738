import { useState } from 'react';
import { useLocation } from '../../../hooks/useLocation';
import { PermissionStatus } from 'expo-location';
import { Distance, LocationInfo, LocationSearch } from '../../../types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { isZipCode } from '../utils';
import { useDebounceEffect } from 'assets/hooks';

export type StoresWithLocationInfo = {
  stores: Distance<PharmacyLocationDto>[];
  locationInfo: LocationInfo;
};

export const useStoresWithDistanceInfo = (
  stores: PharmacyLocationDto[] = [],
  searchCriteria?: string,
  searchAction?: (
    search?: LocationSearch,
  ) => Promise<Distance<PharmacyLocationDto>[]>,
): StoresWithLocationInfo => {
  const [storesWithDistanceInfo, setStoresWithDistanceInfo] =
    useState<StoresWithLocationInfo>({
      stores: stores,
      locationInfo: { status: PermissionStatus.UNDETERMINED, granted: false },
    });

  const {
    latitude,
    longitude,
    status, // Default is 'UNDETERMINED'
    granted,
  } = useLocation();

  useDebounceEffect(() => {
    void (async () => {
      if (!searchCriteria && status === PermissionStatus.UNDETERMINED) return;

      if (searchCriteria || status === PermissionStatus.GRANTED) {
        const _isZipCode = isZipCode(searchCriteria);

        if (searchCriteria && !_isZipCode) return;

        if (!searchCriteria && !latitude) return;

        const locations =
          (await searchAction?.({
            latitude,
            longitude,
            searchCriteria,
          })) || [];

        if (locations && locations.length > 0) {
          const newSortedStores: Distance<PharmacyLocationDto>[] =
            locations.reduce((acc, curr) => {
              const nextStore = stores.find((x) => x.id === curr.id);
              if (nextStore)
                return [
                  ...acc,
                  {
                    ...nextStore,
                    distance: curr.distance,
                    distanceUnit: 'mi',
                  },
                ];
              else return acc;
            }, [] as Distance<PharmacyLocationDto>[]);

          setStoresWithDistanceInfo({
            stores: newSortedStores,
            locationInfo: { latitude, longitude, status, granted },
          });
        }
      }
    })();
  }, [status, stores, latitude, longitude, searchCriteria]);

  return storesWithDistanceInfo;
};
