import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const NearMeIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 21 21" testID={testID}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.607.55.624 7.584l8.856 4.045 3.815 8.739L20.655.604l.11-.11-.08.029.03-.08-.108.107Z"
        fill={color}
      />
    </Svg>
  );
};
