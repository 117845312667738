import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
  Text,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { TextInput } from 'react-native-paper';
import { makeStyles, useTheme } from '../../theme';
import { VerificationTestIDs } from './VerificationTestIDs';
import { getText } from '../../localization/localization';

export const VerificationCode: FunctionComponent<
  PropsWithChildren<VerificationCodeProps>
> = ({ length = 6, disabled = false, label = undefined, onSubmit }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { width } = useWindowDimensions();

  const webWidth = theme.webMaxWidth > width ? width : theme.webMaxWidth;

  const inputWidth =
    Platform.OS === 'web' ? (webWidth / length) * 0.7 : (width / length) * 0.7;
  const inputHeight =
    Platform.OS === 'web' ? (webWidth / length) * 0.8 : width / length;

  const refs = [...Array(length).keys()].map(() => React.useRef<any>(null));

  const methods = useForm({
    defaultValues: Array(length)
      .fill('')
      .reduce((previousValue, currentValue, index) => {
        previousValue[`digit${index + 1}`] = ``;
        return previousValue;
      }, {}),
  });

  const getInputString = (): string => {
    return Object.values(methods.getValues()).join('');
  };

  const onChangeText = (value: string, index: number) => {
    if (value.length === length) {
      const paste = value.split('');
      for (let i = 0; i < paste.length && i < length; i++) {
        methods.setValue(`digit${i + 1}` as any, paste[i]);
      }
      (
        refs[paste.length > length ? length - 1 : paste.length - 1]
          .current as any
      ).focus();
      onSubmit(getInputString());
    } else if (value.length > 1 && value.length !== length) {
      for (let i = index; i < length; i++) {
        methods.setValue(`digit${i + 1}` as any, '');
      }
    } else {
      for (let i = 0; i < index; i++) {
        if (methods.getValues()[`digit${i + 1}`] === '') {
          methods.setValue(`digit${index + 1}` as any, '');
          refs[i].current.focus();
          return;
        }
      }

      methods.setValue(`digit${index + 1}` as any, value);

      if (value.length === 1 && index < length - 1) {
        (refs[index + 1].current as any).focus();
      }

      if (value.length === 1 && index === length - 1) {
        onSubmit(getInputString());
      }
    }
  };

  const onKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>,
    index: number,
  ) => {
    if (event.nativeEvent.key === 'Backspace' && index > 0) {
      methods.setValue(`digit${index + 1}` as any, '');
      (refs[index - 1].current as any).focus();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <View style={{ flexDirection: 'column' }}>
        {label && (
          <Text
            style={{
              marginTop: theme.getSpacing(1),
              marginBottom: theme.getSpacing(1),
            }}
          >
            {label}
          </Text>
        )}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.BoxesContainer}>
            {Array(length)
              .fill(0)
              .map((_, index) => (
                <React.Fragment key={index}>
                  <Controller
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        textContentType={
                          Platform.OS === 'ios' ? 'oneTimeCode' : undefined
                        }
                        accessibilityLabel={`${getText('input-field')} ${
                          index + 1
                        }`}
                        testID={VerificationTestIDs.input}
                        autoComplete={
                          Platform.OS === 'android'
                            ? 'sms-otp'
                            : Platform.OS === 'web'
                            ? ('one-time-code' as any)
                            : 'off'
                        }
                        ref={refs[index]}
                        onChangeText={(value) => {
                          onChangeText(value, index);
                        }}
                        mode="outlined"
                        disabled={disabled}
                        theme={{
                          colors: { text: theme.palette.gray[500] },
                        }}
                        style={[
                          index === length - 1
                            ? {
                                ...styles.LastBox,
                                height: inputHeight,
                                width: inputWidth,
                              }
                            : {
                                ...styles.Box,
                                height: inputHeight,
                                width: inputWidth,
                                marginRight: 14,
                              },
                          {
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            padding: 0,
                            margin: 0,
                          },
                        ]}
                        value={value}
                        keyboardType="numeric"
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyPress={(e) => {
                          onKeyPress(e, index);
                        }}
                        children={undefined}
                      />
                    )}
                    key={index}
                    name={`digit${index + 1}`}
                    rules={{
                      required: true,
                      pattern: /^\d+$/,
                    }}
                  />
                </React.Fragment>
              ))}
          </View>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  BoxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  Box: {
    backgroundColor: theme.palette.white,
    fontSize: 36,
  },
  LastBox: {
    backgroundColor: theme.palette.white,
    fontSize: 36,
  },
}));

export interface VerificationCodeProps {
  length?: number;
  label?: string;
  disabled?: boolean;
  onSubmit: (input: string) => void;
}

export default VerificationCode;
