import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import * as Linking from 'expo-linking';

export const LoginAttemptsExceeded: FunctionComponent<
  PropsWithChildren<LoginAttemptsExceededProps>
> = () => {
  const { currentUrl } = useDeepLinkingContext();

  const theme = useTheme();
  const styles = useStyles();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const onPress = async () => {
    navigation.navigate('reset-link');
  };

  return (
    <ScreenContainer>
      <Text style={styles.textHeader}>{getText('max-attempts-exceeded')}</Text>
      <Text style={[styles.text, styles.textMargin]}>
        {getText('account-locked-reason')}
      </Text>
      <Text style={styles.text}>{getText('security-reason')}</Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={styles.textMargin}>
          <Button
            onPress={onPress}
            hierarchy="secondary"
            logger={{ id: 'send-email-button' }}
          >
            {getText('send-me-email')}
          </Button>
          <Button
            onPress={onPress}
            hierarchy="secondary"
            logger={{ id: 'send-sms-button' }}
            style={{ marginTop: theme.getSpacing(2) }}
          >
            {getText('send-me-sms')}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface LoginAttemptsExceededProps
  extends NativeStackScreenProps<
    AuthStackParamList,
    'login-attempts-exceeded'
  > {}

const useStyles = makeStyles((theme) => ({
  textHeader: {
    color: theme.palette.gray[900],
    fontSize: 25,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  text: {
    color: theme.palette.gray[900],
    fontSize: 14,
    textAlign: 'center',
  },
  textMargin: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
}));
