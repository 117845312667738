import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { IconProps } from './types';

export const MessageSendIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      testID={testID}
    >
      <Circle cx="14" cy="14" r="14" fill="#0093E8" />
      <Path
        d="M20.8337 7.5L11.667 16.6667M20.8337 7.5L15.0003 24.1667L11.667 16.6667M20.8337 7.5L4.16699 13.3333L11.667 16.6667"
        stroke="#EAF1F4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
