import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { InsuranceBottomSheet } from './InsuranceBottomSheet';
import { InsuranceHandler, InsuranceProps } from './InsuranceProps';
import { InsuranceModal } from './InsuranceModal';

const InsuranceComponent: ForwardRefRenderFunction<
  InsuranceHandler,
  InsuranceProps
> = (
  { platform = Platform.OS, ...rest }: InsuranceProps,
  ref: ForwardedRef<InsuranceHandler>,
) => {
  const nativeRef = React.useRef<InsuranceHandler>(null);
  const webRef = React.useRef<InsuranceHandler>(null);

  const handleShow = () => {
    if (platform === 'web') webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (platform === 'web') webRef.current?.hide();
    else nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <>
      {platform === 'web' ? (
        <InsuranceModal {...rest} ref={webRef} />
      ) : (
        <InsuranceBottomSheet {...rest} ref={nativeRef} />
      )}
    </>
  );
};

export const Insurance = forwardRef<InsuranceHandler, InsuranceProps>(
  InsuranceComponent,
);
