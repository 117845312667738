import React from 'react';
import { FunctionComponent } from 'react';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { ChatBoxActions } from './ChatBoxActions';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export const ChatBoxActionBottomSheet: FunctionComponent<
  ChatBoxActionBottomSheetProps
> = ({ sheetRef, conversation, onClose }) => {
  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={'25%'}
      onDismiss={handleBottomSheetDismiss}
    >
      <ChatBoxActions conversation={conversation} onClose={onClose} />
    </BottomSheet>
  );
};

export interface ChatBoxActionBottomSheetProps {
  sheetRef: React.RefObject<BottomSheetModalMethods>;
  conversation: DirectMessagePatientDto;
  onClose: () => void;
}
