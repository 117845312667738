import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { FlatList, Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { Button } from 'assets/components/button';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  MedicalInfoForm,
  PatientUnderCareForm,
  refreshRecordUnderCareRequestsState,
  respondToRequestUnderCare,
  revokeByPatientUnderCare,
} from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
} from './patient-store';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { Icon } from 'assets/components/icon';
import { CalendarIcon } from 'assets/icons';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Modal } from 'assets/components/modal';
import { useUserState } from '../../../store/user-store';
import {
  PatientRecordDto,
  RecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import moment from 'moment';
import patientService from '../../../api/patient-service';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RevokeAccessMobileComponent } from './components/revoke-access/RevokeAccessMobileComponent';
import { RevokeAccessWebComponent } from './components/revoke-access/RevokeAccessWebComponent';
import { formatDateToMMMD } from '../../../common/datetime-utils';
import { ScrollView } from 'react-native-gesture-handler';
import { MedicalInfoFormFields } from '../../../components/medical-info/MedicalInfoFormFields';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useMedicalInfoStore } from './medical-info-store';
import { MedicalInformation } from './components/account-information/MedicalInformation';
import { UseFormReturn } from 'react-hook-form';
import { PersonalInfoBottomSheet } from './components/record-under-care-info/PatientPersonalInfoBottomSheet';
import { PersonalInfoModal } from './components/record-under-care-info/PatientPersonalInfoModal';

export const MedicalInfo: FunctionComponent<
  PropsWithChildren<MedicalInfoProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordUnderCareRequests, caregivers } = usePatientRecordState();
  const isWeb = Platform.OS === 'web';
  const [isCaregiverApprovalOpen, setIsCaregiverApprovalOpen] = useState(false);
  const caregiverApprovalBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { pharmacyName } = useAppStateStore();
  const [isApproved, setIsApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const revokeAccessBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const user = useUserState.getState().user;
  const medicalForm = useForm<PatientUnderCareForm>({});
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [selectedRequest, setSelectedRequest] =
    useState<RecordUnderCareRequestDto>();
  const [viewMode, switchViewMode] = useState(false);
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.MedicalInfo,
  );
  const nativeRef = React.useRef<BottomSheetModal>(null);
  const { status, error } = useMedicalInfoStore();
  const [showMedicalModal, setShowMedicalModal] = useState(false);

  if (!user) {
    throw new Error('No user found');
  }

  const patientRecordId = user.patientRecordId;
  if (!patientRecordId)
    throw new Error('Logged in user has no patient record ID');

  const refreshPatientRecord = async () => {
    const pr = await patientService.findPatientRecord(patientRecordId);
    setPatientRecord(pr);
  };

  useEffect(() => {
    void refreshRecordUnderCareRequestsState();
    void refreshPatientRecord();
  }, [isApproved, showModal]);

  useEffect(() => {
    if (!patientRecord) return;

    medicalForm.setValue('allergies', patientRecord.allergies);
    medicalForm.setValue(
      'medical_conditions',
      patientRecord.medical_conditions,
    );

    if (patientRecord.prefers_easy_open_bottle_caps != null) {
      medicalForm.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }
  }, [patientRecord]);

  if (!patientRecord) {
    return <LoadingIndicator />;
  }

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    whichDetailsToEdit(editDetail);
    setVisibility(true);
    methods.setValue('medical_conditions', patientRecord.medical_conditions);
    methods.setValue('allergies', patientRecord.allergies);
    if (patientRecord.prefers_easy_open_bottle_caps) {
      methods.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }
    whichDetailsToEdit(editDetail);

    return methods;
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    setWhichEditDetails(editType);
  };

  const setVisibility = (visible: boolean) => {
    changeViewMode(true);
    if (Platform.OS === 'web') {
      setShowMedicalModal(visible);
    } else {
      if (!visible) {
        nativeRef.current?.dismiss();
      } else {
        nativeRef.current?.present();
      }
    }
  };

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  const handleShowModal = (show: boolean) => {
    if (Platform.OS === 'web') {
      setShowModal(show);
    } else {
      if (!show) {
        revokeAccessBottomSheetRef.current?.dismiss();
      } else {
        revokeAccessBottomSheetRef.current?.present();
      }
    }
  };

  const handleOnPressRevoke = async (recordId: string, id: string) => {
    await revokeByPatientUnderCare(id, recordId);
    handleShowModal(false);
  };

  const openCaregiverApprovalAgreement = (
    show: boolean,
    request: RecordUnderCareRequestDto,
  ) => {
    setSelectedRequest(request);
    setIsApproved(show);
    if (Platform.OS === 'web') {
      setIsCaregiverApprovalOpen(show);
    } else {
      if (!show) {
        caregiverApprovalBottomSheetRef.current?.dismiss();
      } else {
        caregiverApprovalBottomSheetRef.current?.present();
      }
    }
  };

  const respondToCaregiverAgreement = async (
    request: RecordUnderCareRequestDto,
    isApproved: boolean,
  ) => {
    const updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto = {
      status: isApproved
        ? RecordUnderCareRequestStatus.Approved
        : RecordUnderCareRequestStatus.Denied,
    };
    await respondToRequestUnderCare(
      request.id,
      updateRecordUnderCareRequestDto,
    );
    openCaregiverApprovalAgreement(false, request);
  };

  const handleSubmit = () => {
    useMedicalInfoStore.setState({
      status: 'loading',
      error: undefined,
    });
    const { allergies, prefers_easy_open_bottle_caps, medical_conditions } =
      medicalForm.getValues();

    updatePatientRecord({
      allergies,
      prefers_easy_open_bottle_caps,
      medical_conditions,
    })
      .then(() => {
        useMedicalInfoStore.setState({
          status: 'success',
          error: undefined,
        });
      })
      .catch(() => {
        useMedicalInfoStore.setState({
          status: 'error',
          error: { message: getText('error-updating-patient-record') },
        });
      });
    setVisibility(false);
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    setPatientRecord(
      await patientService.updatePatientRecord(patientRecord.id, updatePR),
    );
  };

  return (
    <ScreenContainer>
      <Form methods={medicalForm}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <MedicalInformation
          patientRecord={patientRecord}
          onEditPress={() => {
            onEditPress(
              PatientUnderCareEditDetailsEnum.MedicalInfo,
              medicalForm,
            );
          }}
        ></MedicalInformation>
        {Platform.OS === 'web' ? (
          <PersonalInfoModal
            title={getText('medical-info')}
            showModal={showMedicalModal}
            setVisibility={setVisibility}
            handleRemovePatientUnderCare={() => {}}
            patientRecord={patientRecord}
            changeViewMode={changeViewMode}
            viewMode={viewMode}
            handleSave={handleSubmit}
            onEditPress={onEditPress}
            handleSubmit={() => {}}
            whichDetailsToEdit={whichDetailsToEdit}
            editDetails={editDetails}
            form={medicalForm}
          ></PersonalInfoModal>
        ) : (
          <PersonalInfoBottomSheet
            title={getText('medical-info')}
            nativeRef={nativeRef}
            setVisibility={setVisibility}
            handleRemovePatientUnderCare={() => {}}
            changeViewMode={changeViewMode}
            viewMode={viewMode}
            patientRecord={patientRecord}
            handleSave={handleSubmit}
            onEditPress={onEditPress}
            whichDetailsToEdit={whichDetailsToEdit}
            editDetails={editDetails}
            form={medicalForm}
          ></PersonalInfoBottomSheet>
        )}
      </Form>

      {(recordUnderCareRequests.length > 0 || caregivers.length > 0) && (
        <View style={styles.lineStyle}>
          <Text style={styles.requestHeaderStyle}>
            {getText('authorized-caregivers').toUpperCase()}
          </Text>
        </View>
      )}
      {caregivers.map((record) => (
        <View style={styles.requestContainer} key={record.id}>
          <View style={styles.requestTextContainer}>
            <Text style={styles.nameText}>
              {`${record.caregiver_first_name} ${record.caregiver_last_name}`.trim()}{' '}
            </Text>
            <TouchableOpacity
              style={styles.textContainer}
              onPress={() => handleShowModal(true)}
            >
              <Text
                style={[
                  styles.pressableTextRevoke,
                  styles.leftSpacing,
                  isWeb && styles.pointer,
                ]}
              >
                {getText('revoke-access')}
              </Text>
            </TouchableOpacity>
          </View>
          {Platform.OS === 'web' ? (
            <RevokeAccessWebComponent
              handleCancelOnPress={handleShowModal}
              handleRevokeOnPress={handleOnPressRevoke}
              showModal={showModal}
              record={record}
            ></RevokeAccessWebComponent>
          ) : (
            <RevokeAccessMobileComponent
              revokeAccessBottomSheetRef={revokeAccessBottomSheetRef}
              dismissBottomSheet={handleShowModal}
              handleRevokeOnPress={handleOnPressRevoke}
              record={record}
            ></RevokeAccessMobileComponent>
          )}
          <View style={styles.rowDirection}>
            <Text style={styles.emailTextStyle}>{record.caregiver_email}</Text>
          </View>

          <View style={styles.authorizationContainer}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={CalendarIcon}
                color={theme.palette.gray[500]}
                size={14}
              ></Icon>
            </View>
            <Text style={styles.requestAuthorizedStyle}>
              {getText('authorized-on', {
                authorizedDate: formatDateToMMMD(record.created_at),
              })}
            </Text>
          </View>
        </View>
      ))}
      {recordUnderCareRequests.map((request) => (
        <View key={request.id}>
          <View style={styles.requestContainer}>
            <View style={styles.requestTextContainer}>
              <Text style={styles.nameText}>
                {`${request.caregiver_first_name} ${request.caregiver_last_name}`.trim()}
              </Text>
              <TouchableOpacity
                onPress={() => openCaregiverApprovalAgreement(true, request)}
                style={styles.textContainer}
              >
                <Text
                  style={[
                    styles.pressableText,
                    styles.leftSpacing,
                    isWeb && styles.pointer,
                  ]}
                >
                  {getText('review-request')}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.rowDirection}>
              <Text style={styles.emailTextStyle}>
                {request.caregiver_email}
              </Text>
            </View>

            <View style={styles.authorizationContainer}>
              <View style={styles.rightSpacing}>
                <Icon
                  icon={CalendarIcon}
                  color={theme.palette.warning[500]}
                  size={14}
                ></Icon>
              </View>
              <Text style={styles.requestPendingStyle}>
                {getText('request-status', {
                  submittedDate: formatDateToMMMD(request.created_at),
                })}
              </Text>
            </View>
          </View>
        </View>
      ))}
      {selectedRequest &&
        (Platform.OS === 'web' ? (
          <WebComponent
            isCaregiverApprovalOpen={isCaregiverApprovalOpen}
            pharmacyName={pharmacyName}
            request={selectedRequest}
            openCaregiverApprovalAgreement={() =>
              openCaregiverApprovalAgreement(false, selectedRequest)
            }
            respondToCaregiverAgreement={respondToCaregiverAgreement}
          ></WebComponent>
        ) : (
          <MobileComponent
            caregiverApprovalBottomSheetRef={caregiverApprovalBottomSheetRef}
            request={selectedRequest}
            openCaregiverApprovalAgreement={() =>
              openCaregiverApprovalAgreement(false, selectedRequest)
            }
            pharmacyName={pharmacyName}
            respondToCaregiverAgreement={respondToCaregiverAgreement}
          ></MobileComponent>
        ))}
    </ScreenContainer>
  );
};

const WebComponent: FunctionComponent<
  PropsWithChildren<{
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    isCaregiverApprovalOpen: boolean;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    respondToCaregiverAgreement: (
      request: RecordUnderCareRequestDto,
      isApproved: boolean,
    ) => void;
  }>
> = ({
  request,
  pharmacyName,
  isCaregiverApprovalOpen,
  openCaregiverApprovalAgreement,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Modal
      show={isCaregiverApprovalOpen}
      okButtonProps={{
        onPress: () => respondToCaregiverAgreement(request, true),
        logger: {
          id: 'caregiver-agreement-authorize-button-modal',
        },
        text: getText('authorize'),
      }}
      dismissButtonProps={{
        onPress: () => openCaregiverApprovalAgreement(false),
        logger: { id: 'caregiver-agreement-close-button-modal' },
      }}
      cancelButtonProps={{
        onPress: () => respondToCaregiverAgreement(request, false),
        logger: { id: 'caregiver-agreement-decline-button-modal' },
        text: getText('decline'),
        hierarchy: 'destructive-secondary',
      }}
      isScrollable
      height={800}
    >
      <Text style={styles.headerTitle}>
        {getText('caregiver-approval-agreement')}
      </Text>
      <View style={styles.contentAlignmentCenter}>
        <Text style={styles.modalText}>
          {getText('puc-permission-access-to-records', {
            firstName: request.caregiver_first_name,
            lastName: request.caregiver_last_name,
          })}
        </Text>
        <View style={styles.lineStyle}>
          <Text style={styles.listItemTitle}>
            {getText('if-you-authorize-person')}
          </Text>
        </View>
        <View style={styles.leftSpacing}>
          <FlatList
            data={[
              { key: getText('manage-prescriptions') },
              { key: getText('order-refills-prescriptions') },
              { key: getText('pick-up-prescriptions') },
              {
                key: getText('discuss-care', {
                  pharmacyName: pharmacyName,
                }),
              },
              {
                key: getText('manage-health'),
              },
            ]}
            renderItem={({ item }) => {
              return (
                <View style={{ marginBottom: 6 }}>
                  <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                </View>
              );
            }}
          />
        </View>
        <Text style={styles.listItemTitle}>{getText('access-validity')}</Text>
        <Text style={[styles.modalText, { marginTop: theme.getSpacing(1) }]}>
          {getText('revoke-access-in-settings')}
        </Text>
      </View>
    </Modal>
  );
};

const MobileComponent: FunctionComponent<
  PropsWithChildren<{
    caregiverApprovalBottomSheetRef: React.RefObject<BottomSheetModal>;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    respondToCaregiverAgreement: (
      request: RecordUnderCareRequestDto,
      isApproved: boolean,
    ) => void;
  }>
> = ({
  caregiverApprovalBottomSheetRef,
  openCaregiverApprovalAgreement,
  request,
  pharmacyName,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <BottomSheet
      bottomSheetRef={caregiverApprovalBottomSheetRef}
      height={'100%'}
      onDismiss={() => openCaregiverApprovalAgreement(false)}
      footerContent={
        <SafeAreaView style={styles.buttonContainer}>
          <Button
            hierarchy="destructive-secondary"
            logger={{
              id: 'decline-bottom-sheet-button',
            }}
            style={styles.declineButtonStyle}
            onPress={() => respondToCaregiverAgreement(request, false)}
          >
            {getText('decline')}
          </Button>
          <Button
            hierarchy="primary"
            logger={{
              id: 'authorize-bottom-sheet-button',
            }}
            style={{ width: '50%' }}
            onPress={() => respondToCaregiverAgreement(request, true)}
          >
            {getText('authorize')}
          </Button>
        </SafeAreaView>
      }
    >
      <ScrollView>
        <Text style={styles.headerTitle}>
          {getText('caregiver-approval-agreement')}
        </Text>
        <View style={styles.contentAlignmentCenter}>
          <Text style={styles.modalText}>
            {getText('puc-permission-access-to-records', {
              firstName: request.caregiver_first_name,
              lastName: request.caregiver_last_name,
            })}
          </Text>
          <View style={styles.lineStyle}>
            <Text style={styles.listItemTitleMobile}>
              {getText('if-you-authorize-person')}
            </Text>
          </View>
          <View style={styles.leftSpacing}>
            <FlatList
              data={[
                { key: getText('manage-prescriptions') },
                { key: getText('order-refills-prescriptions') },
                { key: getText('pick-up-prescriptions') },
                {
                  key: getText('discuss-care', {
                    pharmacyName: pharmacyName,
                  }),
                },
                {
                  key: getText('manage-health'),
                },
              ]}
              renderItem={({ item }) => {
                return (
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                  </View>
                );
              }}
            />
          </View>
          <Text
            style={[
              styles.modalHeader,
              {
                marginTop: theme.getSpacing(2),
                fontFamily: theme.fonts.medium.fontFamily,
              },
            ]}
          >
            {getText('access-validity')}
          </Text>
          <Text style={styles.modalText}>
            {getText('revoke-access-in-settings')}
          </Text>
        </View>
      </ScrollView>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '700',
    fontFamily: 'Lato_700Bold',
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.error[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: {
    marginLeft: theme.getSpacing(1),
    fontFamily: theme.fonts.medium.fontFamily,
  },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineStyle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  requestHeaderStyle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[900],
  },
  emailTextStyle: {
    color: theme.palette.gray[900],
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
  },
  requestContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '700',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  listItemTitle: {
    lineHeight: 20,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },

  listItemTitleMobile: {
    lineHeight: 20,
    fontFamily: 'Lato_700Bold',
    fontSize: 18,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  declineButtonStyle: {
    marginEnd: theme.getSpacing(2),
    width: '50%',
    borderWidth: 1,
  },
  authorizationContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(3),
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  requestAuthorizedStyle: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  contentAlignmentCenter: { alignContent: 'center' },
  rowDirection: { flexDirection: 'row' },
  headerTitle: {
    fontFamily: theme.fonts.medium.fontFamily,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 28,
    marginBottom: theme.getSpacing(1),
    textAlign: 'center',
  },
}));

type MedicalInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  'medical-info'
>;
