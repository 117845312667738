import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons';
import { StoreSelectorHandler, StoreSelectorProps } from './types';
import { StoreSelectorList } from './list';
import { Distance } from '../../types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorInput } from './input/StoreSelectorInput';

const StoreSelectorBottomSheetComponent: ForwardRefRenderFunction<
  StoreSelectorHandler,
  StoreSelectorProps
> = (
  {
    options = [],
    defaultOption,
    onChange = (value: PharmacyLocationDto) => {},
    disabled = false,
    onInfoButtonPress,
    searchAction,
  }: StoreSelectorProps,
  ref: ForwardedRef<StoreSelectorHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  // initializations
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [inputValue, setInputValue] = useState('');

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const [selectedValue, setSelectedValue] = React.useState(
    defaultOption || options[0],
  );

  // actions
  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
    setInputValue('');
  };

  const handleInputChange = (value: string) => {
    // placeholder if we want to implement the server side querying
    setInputValue(value);
  };

  const handleStoreItemChange = (item: Distance<PharmacyLocationDto>) => {
    setSelectedValue(item as PharmacyLocationDto);
  };

  const handleSelect = () => {
    onChange(selectedValue);
    handleBottomSheetDismiss();
  };

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={'90%'}
      onDismiss={handleBottomSheetDismiss}
      headerContent={
        <View
          style={{
            marginLeft: theme.getSpacing(1),
            marginRight: theme.getSpacing(1),
          }}
        >
          <View style={{ flexDirection: 'row', margin: theme.getSpacing(1) }}>
            <View style={styles.bottomSheetViewTitle}>
              <Text>{getText('store-selector-change-store')}</Text>
            </View>
            <View style={{ justifyContent: 'flex-end' }}>
              <IconButton
                accessibilityLabel={getText('dismiss')}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
          <StoreSelectorInput
            onInputChange={handleInputChange}
            useBottomSheetInput
          />
        </View>
      }
      footerContent={
        <View
          style={{
            marginLeft: theme.getSpacing(1),
            marginRight: theme.getSpacing(1),
          }}
        >
          <Button
            hierarchy="primary"
            onPress={handleSelect}
            logger={{ id: 'store-selector-select-button' }}
          >
            {getText('store-selector-select')}
          </Button>
        </View>
      }
    >
      <View style={{ flexGrow: 1, margin: theme.getSpacing(1) }}>
        <StoreSelectorList
          options={options}
          selectedOption={selectedValue}
          defaultOption={defaultOption}
          disabled={disabled}
          searchCriteria={inputValue}
          onChange={handleStoreItemChange}
          onInfoButtonPress={onInfoButtonPress}
          searchAction={searchAction}
        />
      </View>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  changeButton: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

export const StoreSelectorBottomSheet = forwardRef<
  StoreSelectorHandler,
  StoreSelectorProps
>(StoreSelectorBottomSheetComponent);
