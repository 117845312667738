import React, { FunctionComponent } from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const FacebookCircleIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg width={35} height={34} fill="none" testID={testID} color={color}>
      <Circle cx={17.5} cy={17} r={16} stroke="#fff" strokeWidth={2} />
      <Path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.5 10.334h-2a3.333 3.333 0 0 0-3.333 3.333v2h-2v2.667h2v5.333h2.666v-5.334h2l.667-2.666h-2.667v-2A.667.667 0 0 1 19.5 13h2v-2.666Z"
      />
    </Svg>
  );
};
