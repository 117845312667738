import {
  AppointmentGroupDto,
  AppointmentTypeDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import React, { FunctionComponent } from 'react';
import { AppointmentType } from './AppointmentType';

export const AppointmentTypesList: FunctionComponent<
  AppointmentTypesListProps
> = ({ types, groups }) => {
  const getAppointmentGroup = (groupId: string) => {
    return groups.find((group) => group.id === groupId);
  };
  return (
    <>
      {types.map((type) => (
        <AppointmentType
          key={type.id}
          type={type}
          group={getAppointmentGroup(type.appointment_group_id)}
        />
      ))}
    </>
  );
};

export interface AppointmentTypesListProps {
  types: AppointmentTypeDto[];
  groups: AppointmentGroupDto[];
}
