import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useResetPasswordState = create<ResetState>((set, get) => ({
  status: 'idle',
  data: undefined,
  updateData: (data: Partial<ResetState>) => {
    const actualResetState = get();
    set({ ...actualResetState, ...data });
  },
}));

interface ResetState {
  status: AsyncStatus;
  error?: ApiError;
  email?: string;
  data: any;
  updateData: (data: Partial<ResetState>) => void;
  resetPasswordLinkId?: string;
}
