import { TouchableOpacity } from '@gorhom/bottom-sheet';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Icon } from 'assets/components/icon';
import { makeStyles, useTheme } from 'assets/theme';

export const AppointmentInfo: FunctionComponent<
  PropsWithChildren<AppointmentInfoProps>
> = ({ icon, touchable, children, onPress }) => {
  const theme = useTheme();
  const styles = useStyles();

  const Wrapper = ({ children }: PropsWithChildren<{}>) => {
    if (touchable) {
      return (
        <TouchableOpacity onPress={onPress} style={styles.row}>
          {children}
        </TouchableOpacity>
      );
    }

    return <View style={styles.row}>{children}</View>;
  };

  return (
    <Wrapper>
      <View style={styles.icon}>
        <Icon
          icon={icon}
          size={18}
          color={
            touchable ? theme.palette.primary[600] : theme.palette.gray[700]
          }
        />
      </View>
      <View style={styles.content}>{children}</View>
    </Wrapper>
  );
};

export interface AppointmentInfoProps {
  icon: FunctionComponent;
  touchable?: boolean;
  onPress?: () => void;
}

const useStyles = makeStyles((theme) => ({
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(2),
  },
  icon: {
    marginRight: theme.getSpacing(1),
  },
  content: {
    marginTop: 1, // to align it better with the icon
  },
}));
