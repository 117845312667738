import { View, Image, Platform } from 'react-native';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { filterXSS } from 'xss';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import moment from 'moment';
import { getText } from 'assets/localization/localization';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getSubmissionById } from './patient-actions';
import { usePatientSubmissionsState } from './patient-store';
import { formatDate } from '../../../common/datetime-utils';
import { AnswerDto } from '@digitalpharmacist/forms-service-client-axios';

export const PatientSubmission: FunctionComponent<
  PropsWithChildren<SubmissionProps>
> = ({ submissionId, formId, locationId }) => {
  const styles = useStyles();
  const { focusedSubmission, focusedSubmissionStatus } =
    usePatientSubmissionsState();

  useEffect(() => {
    getSubmissionById(formId, submissionId, locationId);
  }, [formId, submissionId, locationId]);

  const displayImage = (src: string) => (
    <Image
      resizeMode="contain"
      source={{ uri: src }}
      style={{
        width: 200,
        height: 200,
      }}
    />
  );

  const displayAnswer = ({
    type: answerType,
    answer,
    answerJson,
  }: AnswerDto) => {
    answer = answerType.includes('datetime') ? formatDate(answer) : answer;

    if (
      (answerType === 'Signature' || answerType === 'ImageUpload') &&
      answer.length
    ) {
      return displayImage(answer.split(',')[0]);
    }

    if (answerType === 'DateOfBirth') {
      return moment(answer).format('MM/DD/YYYY');
    }

    if (answerType === 'InputTable') {
      if (Platform.OS === 'web') {
        return (
          <div
            className="answer-input-table"
            dangerouslySetInnerHTML={{ __html: filterXSS(answer.toString()) }}
          ></div>
        );
      } else {
        if (answerJson) {
          return (
            <View style={styles.table}>
              {Object.entries(answerJson!).map(([key, value]) => (
                <View style={styles.tableRow}>
                  <Text style={[styles.tableText, styles.tableTextLabel]}>
                    {key}:
                  </Text>
                  <Text style={styles.tableText}>{value}</Text>
                </View>
              ))}
            </View>
          );
        }
      }
    }

    return answer.length
      ? answer.toString().replaceAll('<br>', '\n')
      : getText('not-entered');
  };

  return focusedSubmission && focusedSubmissionStatus !== 'loading' ? (
    <View style={styles.container}>
      <View>
        <View style={styles.field}>
          <Text style={styles.label} selectable>
            {getText('submitted-on')}:
          </Text>
          <Text style={styles.answer} selectable>
            {focusedSubmission.created_at}
          </Text>
        </View>

        {focusedSubmission.answers.map((answer, index) => (
          <View key={index} style={styles.field}>
            <Text style={styles.label} selectable>
              {answer.title}:
            </Text>
            <Text
              selectable={true}
              style={
                answer.answer.length
                  ? styles.answer
                  : [styles.answer, styles.greyedOut]
              }
            >
              {displayAnswer(answer)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  ) : (
    <LoadingIndicator />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginHorizontal: theme.getSpacing(1),
    marginBottom: theme.getSpacing(4),
    marginTop: theme.getSpacing(2),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
    gap: theme.getSpacing(2),
  },
  title: {
    marginLeft: theme.getSpacing(2),
    fontSize: 25,
    flexGrow: 1,
  },
  answer: {
    fontSize: 16,
    lineHeight: 24,
    overflow: 'hidden',
  },
  greyedOut: {
    color: theme.palette.gray['500'],
  },
  field: {
    fontSize: 16,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    gap: theme.getSpacing(4),
  },
  label: {
    ...theme.fonts.regular,
    minWidth: 250,
    maxWidth: 250,
    color: theme.palette.gray['700'],
    fontSize: 15,
    marginBottom: theme.getSpacing(0.5),
  },
  submissionContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    paddingTop: theme.getSpacing(4),
  },
  table: {
    paddingTop: theme.getSpacing(0.5),
  },
  tableRow: {
    marginBottom: theme.getSpacing(1),
  },
  tableTextLabel: {
    ...theme.fonts.medium,
  },
  tableText: {
    fontSize: 16,
  },
}));

interface SubmissionProps {
  submissionId: string;
  formId: string;
  locationId: string;
}
