import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import formsService from '../../api/forms-service';
import patientService from '../../api/patient-service';
import { AsyncStatus } from '../../store/types';
import { useUserState } from '../../store/user-store';
import { useFillInFormStore } from './fill-in-form-store';
import { getText } from 'assets/localization/localization';

export const getFormContent = async (
  formId: string,
  locationId: string,
  patientRecordId: string,
): Promise<void> => {
  useFillInFormStore.setState({ status: 'loading' });

  try {
    const response = await formsService.getPrivateForm(
      locationId,
      formId,
      patientRecordId,
    );

    useFillInFormStore.setState({
      status: 'success',
      formHtmlContent: response,
    });
  } catch (e) {
    useFillInFormStore.setState({
      error: {
        message: getText('error-loading-form'),
      },
      status: 'error',
    });
  }
};

export const getForm = async (formId: string, locationId: string) => {
  useFillInFormStore.setState({
    status: 'loading',
  });

  try {
    const response = await formsService.getForm(locationId, formId);

    useFillInFormStore.setState({
      form: response,
      status: 'idle',
    });
  } catch (e) {
    useFillInFormStore.setState({
      error: {
        message: getText('error-loading-form'),
      },
      status: 'error',
    });
  }
};

export const getPatientRecord = async (patientRecordId: string) => {
  useFillInFormStore.setState({
    status: 'loading',
  });

  try {
    const response = await patientService.findPatientRecord(patientRecordId);

    useFillInFormStore.setState({
      patientRecord: response,
      status: 'idle',
    });
  } catch (e) {
    useFillInFormStore.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      status: 'error',
    });
  }
};

export const initLocationPatientRecord = async (
  patientRecordId: string,
  locationId: string,
) => {
  useFillInFormStore.setState({
    status: 'loading',
  });

  try {
    const response =
      await patientService.patientRecordInitLocationPatientRecord(
        patientRecordId,
        {
          location_id: locationId,
        },
      );

    useFillInFormStore.setState({
      patientRecord: response,
      status: 'idle',
    });
  } catch (e) {
    useFillInFormStore.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      status: 'error',
    });
  }
};

export const setStatus = (status: AsyncStatus) => {
  useFillInFormStore.setState({ status });
};
