import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const CompoundingServicesIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    testID={testID}
    viewBox="0 0 70 80"
  >
    <Path
      fill={color}
      d="m46.993 62.45 2.81 6.622a1.386 1.386 0 0 1-1.275 1.923V71H20.259a1.39 1.39 0 0 1-1.226-2.046l2.76-6.504a17.466 17.466 0 0 1-6.483-3.978c-3.068-2.976-5.015-7.068-5.015-11.567v-9.276c0-.767.622-1.39 1.39-1.39h45.416c.767 0 1.39.623 1.39 1.39v9.276c0 4.5-1.948 8.591-5.015 11.567a17.46 17.46 0 0 1-6.483 3.978Zm-1.837 2.76H23.63l-1.278 3.01h24.08l-1.277-3.01ZM27.21 60.573h2.51c-.562-.66-.874-1.645-.888-3.012h16.666c4.588 0 6.793-2.03 8.409-4.085a13.006 13.006 0 0 0 1.801-6.346H28.832v-3.708H55.71V39.02H13.075v4.402h8.343v3.708h-8.34c.054 3.079 1.217 5.922 3.094 8.193l-.03-.08c1.423 1.343 3.523 2.19 5.276 2.371.349 1.152 1.544 2.264 3.367 2.746a13.81 13.81 0 0 0 2.425.213Z"
    />
    <Path
      fill={colorSecondary}
      fillRule="evenodd"
      d="m53.197 25.02-3.776 8.902H38.423l8.528-12.38a5.56 5.56 0 1 1 6.246 3.478Z"
      clipRule="evenodd"
    />
  </Svg>
);
