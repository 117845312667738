import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { StorageKeys } from '../../../enums/storage-keys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DocumentViewProp } from './types';
import { generateUrl } from './utils';

export const DocumentView: FunctionComponent<DocumentViewProp> = ({
  src,
  auth = false,
  streaming = false,
  platform = Platform.OS,
  style,
}) => {
  const props: DocumentViewProp = { src, auth, streaming, style, platform };

  return Platform.OS === 'web' ? (
    <DocumentViewWeb {...props} />
  ) : (
    <DocumentViewNative {...props} />
  );
};

export const DocumentViewNative: FunctionComponent<DocumentViewProp> = ({
  src,
  style,
  auth,
  streaming,
  platform,
}) => {
  const [tokenValue, setTokenValue] = useState<string | null>();

  useEffect(() => {
    if (auth)
      void (async () => {
        const value = await AsyncStorage.getItem(StorageKeys.AccessToken);
        setTokenValue(value);
      })();
  }, [auth]);

  const render = () => {
    const loadingComponent = <LoadingIndicator />;
    if (!src) return loadingComponent;

    if (src && auth && !tokenValue) return loadingComponent;

    const url = generateUrl({ src, streaming, auth, platform });

    return (
      <View
        style={
          style ?? {
            width: '100%',
            height: '100%',
          }
        }
      >
        <WebView
          style={{
            outline: 'none',
            boxSizing: 'border-box',
            boxShadow: 'none',
          }}
          height="100%"
          width="100%"
          source={{
            uri: url,
          }}
          injectedJavaScriptBeforeContentLoaded={`
            localStorage.setItem("TokenKey", "${StorageKeys.AccessToken}");
            localStorage.setItem("${StorageKeys.AccessToken}", "${
              tokenValue ?? ''
            }");
          `}
          javaScriptEnabled
          javaScriptCanOpenWindowsAutomatically
          originWhitelist={['*']}
          allowFileAccess
          allowFileAccessFromFileURLs
          allowsLinkPreview
          domStorageEnabled
          useWebKit={true}
          scrollEnabled={true}
          nestedScrollEnabled
        />
      </View>
    );
  };

  return render();
};

export const DocumentViewWeb: FunctionComponent<DocumentViewProp> = ({
  src,
  style,
  auth,
  streaming,
  platform,
}) => {
  // dynamic ID since we can have 2 document views at the same parent view
  const [id] = useState(`${Date.now()}`);

  const renderIframe = async () => {
    const url = generateUrl({ src, streaming, auth, platform });

    const iframe = document.createElement('iframe');
    iframe.id = `${id}-document-frame`; // frame id
    // TODO: base url
    iframe.src = url;
    iframe.width = '100%';
    iframe.height = '100%';
    document.getElementById(id)?.appendChild(iframe);
    iframe.contentWindow?.localStorage.setItem(
      'TokenKey',
      StorageKeys.AccessToken,
    );
  };

  useEffect(() => {
    if (!src) return;
    renderIframe();
  }, [src]);

  return (
    <View
      style={
        style ?? {
          width: '100%',
          height: '100%',
        }
      }
      nativeID={id}
      testID={id}
    >
      {!src ?? <LoadingIndicator />}
    </View>
  );
};
