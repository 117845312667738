import React, { FunctionComponent, PropsWithChildren, useRef } from 'react';
import {
  NavigationContainer as ReactNavigationNavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { navigationTheme } from '../common/theme';
import { setCurrentScreen } from 'assets/logging/logger';
import * as Linking from 'expo-linking';
import { useBottomTabNavigationContextRef } from 'assets/components/bottom-tabs';
import { StackNavigationOptions } from '@react-navigation/stack';
import { Platform } from 'react-native';
import { PHARMACY_NAME } from './constants';

/*
 * Wrapper for React Navigation's Navigation Container with extended functionality for tracking screen changes
 * Reference: https://reactnavigation.org/docs/screen-tracking/#example
 */
export const NavigationContainer: FunctionComponent<
  PropsWithChildren<NavigationContainerProps>
> = ({ children }) => {
  const bottomTabNavigationContextRef = useBottomTabNavigationContextRef();
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string>();
  const prefix = Linking.createURL('/');
  const linking = {
    prefixes: [prefix],
  };

  return (
    <ReactNavigationNavigationContainer
      linking={linking}
      ref={navigationRef}
      theme={navigationTheme}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name ?? 'Loading'} - ${PHARMACY_NAME}`,
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;
        const currentOptions: StackNavigationOptions =
          navigationRef.getCurrentOptions() as StackNavigationOptions;

        if (previousRouteName !== currentRouteName) {
          await setCurrentScreen(currentRouteName);
        }

        // TODO: review logic with overall app behavior
        if (Platform.OS !== 'web')
          if (currentOptions.presentation === 'modal')
            bottomTabNavigationContextRef.current?.hide();
          else bottomTabNavigationContextRef.current?.show();

        routeNameRef.current = currentRouteName;
      }}
    >
      {children}
    </ReactNavigationNavigationContainer>
  );
};

interface NavigationContainerProps {}
