import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from 'react-native-paper';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { PatientUnderCareForm } from '../../patient-actions';
import { UseFormReturn, useForm } from 'react-hook-form';
import { makeStyles } from 'assets/theme';

export const ContactInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    record?: RecordUnderCareDto;
    onEditPress: () => void;
    form?: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ onEditPress, patientRecord, record, form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<PatientUnderCareForm>({});
  const email = patientRecord.email;
  const phone = patientRecord.phone;

  return (
    <View>
      <View style={styles.requestTextContainer}>
        <Text style={styles.title}>
          {getText('contact-info').toUpperCase()}
        </Text>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={() => onEditPress()}
        >
          <Text style={[styles.pressableTextRevoke, styles.leftSpacing]}>
            {getText('edit')}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('email-address')}</Text>
        <Text style={styles.boldSubtitle}>{email}</Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(3) }}>
        <Text style={styles.subtitle}>{getText('phone')}</Text>
        <Text style={styles.boldSubtitle}>{phone}</Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    ...theme.fonts.medium,
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));
