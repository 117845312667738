import React, { FunctionComponent } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  MedicalConditionTypeahead,
  MedicalConditionTypeaheadProps,
} from './MedicalConditionTypeahead';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';

export const MedicalConditionTypeaheadField: FunctionComponent<
  MedicalConditionTypeaheadFieldProps
> = ({ name, rules, componentProps }) => {
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error(
      'MedicalConditionTypeaheadField must have a parent form context',
    );
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <MedicalConditionTypeahead
            {...componentProps}
            onChange={(e) => {
              onChange(e);
            }}
            medicalConditionIds={value}
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <Text
          testID={MedicalConditionTypeaheadFieldTestIDs.error}
          style={styles.errorMessage}
        >
          {error.message}
        </Text>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface MedicalConditionTypeaheadFieldProps {
  name: string;
  rules?: RegisterOptions;
  componentProps?: MedicalConditionTypeaheadProps;
}

export const MedicalConditionTypeaheadFieldTestIDs = {
  error: 'medicalCondition-typeahead-formControl-error',
};
