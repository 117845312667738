import {
  CreateRecordUnderCareRequestDto,
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';
import {
  SubmissionCoreMetadataDto,
  SubmissionFullDataDto,
} from '@digitalpharmacist/forms-service-client-axios';
import { PatientRecordItem } from '../../medications/Medications';

export const usePatientIntakeState = create<PatientIntakeState>((set) => ({
  status: 'idle',
  data: undefined,
  isRefill: false,
  updateData: (data: PatientIntakeState) => {
    set({ ...data });
  },
}));

export const usePatientRecordState = create<PatientRecordState>((set) => ({
  status: 'idle',
  data: undefined,
  patientRecord: undefined,
  caregiverRequests: [],
  recordUnderCareRequests: [],
  caregivers: [],
  recordsUnderCare: [],
  patientRecordItems: [],
  updateData: (data: PatientRecordState) => {
    //looks like this is duplicated
    set({ ...data });
  },
  updatePatientRecord: (patientRecord: PatientRecordState) => {
    set({ ...patientRecord });
  },
}));

export const usePatientSubmissionsState = create<PatientSubmissionsState>(
  () => ({
    submissions: [],
    focusedSubmissionStatus: 'idle',
    status: 'idle',
    submissionPdfStatus: 'idle',
  }),
);

interface PatientIntakeState {
  data: any;
  selectedStore?: PharmacyLocationDto;
  isRefill: boolean;
  updateData?: (data: PatientIntakeState) => void;
  status: AsyncStatus;
  error?: ApiError;
}

interface PatientRecordState {
  data: CreateRecordUnderCareRequestDto | undefined;
  patientRecord: PatientRecordDto | undefined;
  updatePatientRecord: (patientRecord: PatientRecordState) => void;
  updateData: (data: PatientRecordState) => void;
  status: AsyncStatus;
  error?: ApiError;
  caregiverRequests: RecordUnderCareRequestDto[];
  recordUnderCareRequests: RecordUnderCareRequestDto[];
  caregivers: RecordUnderCareDto[];
  recordsUnderCare: RecordUnderCareDto[];
  patientRecordItems: PatientRecordItem[];
}

interface PatientSubmissionsState {
  submissions: SubmissionCoreMetadataDto[];
  latestSubmission?: string;
  focusedSubmission?: SubmissionFullDataDto;
  focusedSubmissionStatus: AsyncStatus;
  submissionPdfStatus: AsyncStatus;
  status: AsyncStatus;
  error?: ApiError;
}

export enum PatientUnderCareEditDetailsEnum {
  PersonalInfo = 'personal',
  MedicalInfo = 'medical',
  IdAndInsuranceInfo = 'id',
}
