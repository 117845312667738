import { PROXY_APP_BASE_URL } from '../../../common/constants';
import { VideoModel } from '../types';

export const generateUrl = (videoInfo: VideoModel, auth?: boolean) => {
  const urlSearchParams = new URLSearchParams({
    video_id: videoInfo.videoId,
    account_id: videoInfo.accountId,
    player_id: videoInfo.playerId,
    auth: `${auth}`,
  });

  return (
    PROXY_APP_BASE_URL + '/brightcove-player?' + urlSearchParams.toString()
  );
};
