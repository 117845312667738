import { ResourceItem } from '../../screens/account/patient/patient-actions';
import { findAllergiesById } from './medical-info-actions';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  TypeaheadWithTagsAndEmpty,
  TypeaheadWithTagsAndEmptyProps,
} from 'assets/components/typeahead-with-tags-and-empty';
import medicationService, {
  noKnownAllergiesOption,
} from '../../api/medication-service';
import { getText } from 'assets/localization/localization';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';

export type AllergyTypeaheadProps = {
  allergyIds?: string[];
  onChange?: (allergyIds: string[]) => void;
};

export const AllergyTypeahead: FunctionComponent<
  PropsWithChildren<AllergyTypeaheadProps>
> = ({ allergyIds, onChange: parentOnChange }) => {
  const [allergies, setAllergies] = useState<ResourceItem[]>();
  const [allergyTags, setAllergyTags] = useState<TypeaheadBaseItem[]>();

  // Load suggested allergy tags
  useEffect(() => {
    void (async () => {
      setAllergyTags(await medicationService.getTopAllergyHints());
    })();
  }, []);

  useEffect(() => {
    if (!allergyIds) return;
    void (async () => {
      if (allergyIds.length > 0) {
        const loadedAllergies = await findAllergiesById(allergyIds);
        setAllergies(loadedAllergies);
      }
    })();
  }, [allergyIds]);

  const handleOnChange = (value: (string | TypeaheadBaseItem)[]) => {
    parentOnChange?.(
      value.map((val) => {
        if (typeof val === 'string') {
          return val;
        }

        return val.value;
      }),
    );
  };

  const allergiesTypeaheadProps: TypeaheadWithTagsAndEmptyProps = {
    multiple: true,
    asyncOptions: async (searchTerm: string) => {
      if (!searchTerm) return Promise.resolve([]);
      return medicationService.allergySearch(searchTerm);
    },
    hintMessage: getText('your-allergies'),
    explicitNotEmptyLabel: getText('known-allergies'),
    explicitEmptyLabel: getText('no-known-allergies'),
    emptyValue: noKnownAllergiesOption,
    defaultValue: allergies,
    tags: allergyTags,
    onChange: handleOnChange,
    useCustomNoOptionsValuePrefix: true,
  };

  return <TypeaheadWithTagsAndEmpty {...allergiesTypeaheadProps} />;
};
