import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { View } from 'react-native';
import { Divider } from 'react-native-paper';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';

import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { Text } from 'assets/components/text/Text';
import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { LoadingIndicator } from 'assets/components/loading-indicator';

import { PrescriptionCard } from '../../components/prescription-card';
import { StoreSelector } from '../../components/store-selector';
import { MapSize } from '../../components/store-selector/types';
import { useAppStateStore } from '../../store/app-store';
import refillService from './refill-service';
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from './refill-store';
import { RefillStackParamList } from './RefillNavigation';
import { RefillTestIDs } from './RefillTestIDs';
import { useUserState } from '../../store/user-store';
import orderService from '../medications/medication-orders/order-service';
import { useOrderRefillStore } from '../medications/medication-orders/order-store';
import { usePatientRecordState } from '../account/patient/patient-store';

export const RefillReview: FunctionComponent<
  PropsWithChildren<RefillReviewProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const { user } = useUserState();
  const styles = useStyles();
  const { pharmacyId, stores, getStores } = useAppStateStore();
  const {
    selectedLocationId,
    selectedMedications,
    selectedPatient,
    selectedPatientRecord: patientRecord,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const {
    methodValue,
    noteValue,
    isUrgent,
    isToday,
    updateReviewMethodData,
    submitRefill,
  } = useRefillReviewMethodStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMethodError, setShowMethodError] = useState<boolean>(false);
  const { recordsUnderCare } = usePatientRecordState();
  const [otherInformationText, setOtherInformationText] = useState<string>('');

  useEffect(() => {
    if (!stores.length) void getStores();

    if (!selectedLocationId || !selectedPatient || !updateReviewMethodData)
      return;

    if (methodValue === '' || methodValue === 'Not selected') {
      void refillService
        .getLastRefill(selectedLocationId, selectedPatient.id)
        .then((data) => {
          if (!data?.fulfillment_method) return;

          updateReviewMethodData({
            methodValue: data.fulfillment_method,
          });
        });
    }
  }, [selectedPatient]);

  useEffect(() => {
    void loadOtherInformationText();
  }, [patientRecord]);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId,
    );
    setSelectedStore(selectedStore);
  }, [stores, selectedLocationId]);

  const handleOnPressDone = async () => {
    if (methodValue === '' || methodValue === 'Not selected') {
      setShowMethodError(true);
      return;
    }

    if (
      !submitRefill ||
      !selectedPatient ||
      !selectedLocationId ||
      !patientRecord ||
      !user ||
      !updateMedicationsData ||
      !selectedMedications
    )
      return;

    setIsLoading(true);

    await submitRefill(
      pharmacyId,
      selectedLocationId,
      selectedPatient.id,
      selectedMedications,
      user.id,
      isToday,
      isUrgent,
    );

    navigation.navigate('refill-confirmation');

    const allOrders = await orderService.getPatientOrders(
      selectedLocationId,
      recordsUnderCare,
      patientRecord,
    );

    useOrderRefillStore.setState({
      patientOrders: allOrders.sort((po1, po2) =>
        po1.order.created_at < po2.order.created_at ? 1 : -1,
      ),
    });

    const prescriptions = await refillService.getPatientPrescriptions(
      selectedLocationId,
      selectedPatient.id,
    );

    updateMedicationsData({
      selectedMedications: prescriptions.map((x) => ({
        ...x,
        checked: false,
      })),
    });

    setIsLoading(false);
  };

  const handleOtherInformationNavigation = () => {
    navigation.navigate('refill-other-information');
  };

  const handlePrescriptionMethodNavigation = () => {
    navigation.navigate('refill-prescription-method');
  };

  const handleRefillNotesNavigation = () => {
    navigation.navigate('refill-notes');
  };

  const loadOtherInformationText = async () => {
    if (!patientRecord) return;

    setOtherInformationText(getText('loading'));

    try {
      const otherInformationText =
        await refillService.getPatientMedicalInfoText(patientRecord);

      setOtherInformationText(otherInformationText);
    } catch (error) {
      setOtherInformationText('Error ' + getText('loading'));
    }
  };

  const removeMedications = (item: PrescriptionDto) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedMedications: selectedMedications?.filter(
        (x) => x.rx_number !== item.rx_number,
      ),
      unableToSubmitMedications: unableToSubmitMedications?.filter(
        (med) => med.rx_number !== item.rx_number,
      ),
    });
  };

  return (
    <>
      <ScreenContainer>
        <View>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={styles.textTitle}>{getText('medications')}</Text>
          </View>
          {selectedMedications &&
            selectedMedications.map((item) => (
              <View key={item.rx_number}>
                <View style={{ paddingVertical: theme.getSpacing(2) }}>
                  <Divider />
                </View>
                <PrescriptionCard
                  key={item.rx_number}
                  patient={selectedPatient}
                  prescription={item}
                  selectable={false}
                  removable={selectedMedications.length > 1}
                  onRemove={() => removeMedications(item)}
                  check={item.checked}
                />
              </View>
            ))}
        </View>
        <View>
          <View style={styles.container}>
            <View style={{ paddingVertical: theme.getSpacing(1) }}>
              <Text style={styles.textTitle}>{getText('other-details')}</Text>
              <Divider />
            </View>
            <View style={styles.container}>
              {selectedStore && (
                <StoreSelector
                  options={stores}
                  defaultOption={selectedStore}
                  mapProps={{ size: MapSize.md }}
                  changeButtonShown={false}
                />
              )}
            </View>
          </View>
          <Divider />
          <ListMenu style={styles.container}>
            <ListItemLink
              onPress={handleOtherInformationNavigation}
              showIcon={false}
            >
              <Text style={styles.textTitle}>
                {getText('other-information')}
              </Text>
              <Text>{otherInformationText}</Text>
            </ListItemLink>
            <ListItemLink
              onPress={handlePrescriptionMethodNavigation}
              showIcon={false}
            >
              <Text style={styles.textTitle}>{getText('method')}</Text>
              <Text style={{ textTransform: 'capitalize' }}>{methodValue}</Text>
            </ListItemLink>
            <ListItemLink
              onPress={handleRefillNotesNavigation}
              showIcon={false}
            >
              <Text style={styles.textTitle}>{getText('notes')}</Text>
            </ListItemLink>
            {showMethodError && (
              <Text style={{ color: 'red' }}>{getText('field-required')}</Text>
            )}
          </ListMenu>
        </View>
        <View style={styles.noteForPharmacyContainer}>
          <Text style={styles.noteForPharmacyTitle}>
            {getText('note-for-pharmacy-not-optional')}
          </Text>
          <Text>{noteValue}</Text>
        </View>

        <View>
          <Button
            hierarchy="primary"
            onPress={handleOnPressDone}
            testID={RefillTestIDs.refillSubmitButton}
            logger={{ id: RefillTestIDs.refillSubmitButton }}
            disabled={selectedMedications?.length === 0}
            loading={isLoading}
          >
            {getText('submit')}
          </Button>
        </View>
      </ScreenContainer>
      {isLoading && (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )}
    </>
  );
};

export type RefillReviewProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-review'
>;

const useStyles = makeStyles((theme) => ({
  displayContent: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    fontWeight: '400',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  root: {
    flex: 1,
  },
  rowDisplay: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  medicationTitle: {
    ...theme.fonts.medium,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  descriptionTitle: {
    ...theme.fonts.medium,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
    marginVertical: theme.getSpacing(0.5),
  },
  medicationDetailsText: {
    ...theme.fonts.medium,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  noteForPharmacyTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginBottom: theme.getSpacing(1),
  },
  viewItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: '700',
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
    margin: theme.getSpacing(0.5),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  otherInformationModal: {
    padding: theme.getSpacing(2),
  },
  methodModal: {
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
  },
  methodOptions: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    margin: theme.getSpacing(1),
    padding: theme.getSpacing(1),
    borderRadius: theme.roundness,
  },
  insuranceModal: {
    padding: theme.getSpacing(2),
  },
  noteForPharmacyContainer: {
    borderWidth: 1,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[100],
    backgroundColor: theme.palette.gray[100],
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
  displayTitleContent: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  loadingIndicator: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.getSpacing(2),
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -20,
  },
}));
