import { PROXY_APP_BASE_URL } from '../../../common/constants';
import { DocumentModel } from '../types';

export const generateUrl = ({
  src,
  streaming,
  auth,
  platform,
}: DocumentModel) => {
  const urlSearchParams = new URLSearchParams({
    url: encodeURIComponent(src),
    auth: `${auth ?? 'false'}`,
    streaming: `${streaming ?? 'false'}`,
    platform: `${platform ?? 'web'}`,
  });

  return PROXY_APP_BASE_URL + '/documents?' + urlSearchParams.toString();
};
