export const RootNavigationTestIDs = {
  dashboard: 'primary-navigation-dashboard',
  medications: 'primary-navigation-medications',
  messages: 'primary-navigation-messages',
  appointments: 'primary-navigation-appointments',
  account: 'primary-navigation-account',
  forms: 'primary-navigation-fill-in-form',
  appointment: 'primary-navigation-appointment-details',
  'order-details': 'primary-navigation-order-details',
};
