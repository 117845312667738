import { getText } from 'assets/localization/localization';
import { useUserState } from '../../../store/user-store';
import {
  usePatientIntakeState,
  usePatientSubmissionsState,
  usePatientRecordState,
} from './patient-store';
import patientService from '../../../api/patient-service';
import {
  CreatePatientRecordDto,
  CreateRecordUnderCareRequestDto,
  Gender,
  PatientUnderCareRelationship,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
  UpdateRecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import moment from 'moment';
import FileStorageService from '../../../api/file-storage-service';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { uuid } from 'assets/utils/uuid';
import formsService from '../../../api/forms-service';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import axios from 'axios';
import { PatientRecordItem } from '../../medications/Medications';
import { logError, logInfo } from 'assets/logging/logger';

export const updatePatientIntake = (
  partial:
    | PatientInfoForm
    | PatientPreferencesForm
    | PatientFeedback
    | PatientInsuranceForm,
) => {
  usePatientIntakeState.setState((state) => {
    return { data: { ...state, ...partial } };
  });
};

export const updatePatientUnderCareForm = (partial: PatientUnderCareForm) => {
  usePatientRecordState.setState((state) => {
    return { data: { ...state, ...partial } };
  });
};

export const submitPatientUnderCareRequest = async (
  values: PatientUnderCareForm,
): Promise<void> => {
  usePatientRecordState.setState({ error: undefined, status: 'loading' });
  try {
    const formattedDOB = moment(values.date_of_birth, 'MM-DD-YYYY').format(
      'YYYY-MM-DD',
    );
    const userId = useUserState.getState().user?.id;

    if (!userId) throw new Error("Patient record id doesn't exist");
    const createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto = {
      ...values,
      date_of_birth: formattedDOB,
    };
    if (!userId) {
      throw new Error('');
    }
    const response = patientService.createRecordUnderCareRequest(
      userId,
      createRecordUnderCareRequestDto,
    );
    await refreshRecordUnderCareRequestsState();
    usePatientRecordState.setState({ status: 'success' });
  } catch (error) {
    logInfo('Error in submitPatientUnderCareRequest');
    logError(error as Error);
    usePatientRecordState.setState({
      error: { message: getText('unable-to-submit-people-under-care-request') }, //TODO sync with Aurea and Curtis for error messages
      status: 'error',
    });
  }
};

export const refreshRecordUnderCareRequestsState = async (): Promise<void> => {
  usePatientRecordState.setState({
    status: 'loading',
  });
  try {
    usePatientRecordState.setState({
      status: 'idle',
    });
    const patientRecordId = useUserState.getState().user?.patientRecordId;
    if (!patientRecordId) {
      throw new Error('Patient id not found');
    }
    const recordUnderCareRequests: RecordUnderCareRequestDto[] =
      await patientService.findPendingRequestsByRecordUnderCare(
        patientRecordId,
      );
    const caregivers: RecordUnderCareDto[] =
      await patientService.recordUnderCareFindByRecordUnderCare(
        patientRecordId,
      );
    usePatientRecordState.setState({
      recordUnderCareRequests: recordUnderCareRequests,
      caregivers: caregivers,
      status: 'success',
    });
  } catch (error) {
    logInfo('Error in refreshRecordUnderCareRequestsState');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patients-under-care'),
      },
      status: 'error',
    });
  }
};

export const respondToRequestUnderCare = async (
  requestId: string,
  updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto,
): Promise<void> => {
  const userId = useUserState.getState().user?.id;
  if (!userId) throw new Error('User id not found');
  try {
    await patientService.patchRequestsRecordUnderCare(
      userId,
      requestId,
      updateRecordUnderCareRequestDto,
    );
    await refreshRecordUnderCareRequestsState();
  } catch (error) {
    logInfo('Error in respondToRequestUnderCare');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patients-under-care'),
      },
      status: 'error',
    });
  }
};

export const revokeByPatientUnderCare = async (
  recordId: string,
  id: string,
): Promise<void> => {
  try {
    await patientService.revokeByPatientUnderCare(recordId, id);
    await refreshRecordUnderCareRequestsState();
  } catch (error) {
    logInfo('Error in revokeByPatientUnderCare');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patients-under-care'),
      },
      status: 'error',
    });
  }
};

export const loadPatientRecordItems = () => {
  const recordsUnderCare = usePatientRecordState.getState().recordsUnderCare;
  const patientRecord = usePatientRecordState.getState().patientRecord;
  if (!patientRecord) throw Error(getText('error-loading-patient-record'));
  const patientRecordItems: PatientRecordItem[] = [
    {
      patientRecordId: patientRecord.id,
      fullName: getText('myself'),
      location_patient_record: patientRecord.location_patient_records,
    },
    ...recordsUnderCare.map((puc) => ({
      patientRecordId: puc.record_under_care.id,
      fullName: `${puc.record_under_care.first_name} ${puc.record_under_care.last_name}`,
      location_patient_record: puc.location_patient_records,
    })),
  ];
  usePatientRecordState.setState({ patientRecordItems });
};

export const refreshCaregiverRequestsState = async (): Promise<void> => {
  usePatientRecordState.setState({
    status: 'loading',
  });

  try {
    usePatientRecordState.setState({
      status: 'idle',
    });
    const userId = useUserState.getState().user?.id;
    if (!userId) throw new Error("User id doesn't exist");
    const requestsByCaregiver: RecordUnderCareRequestDto[] =
      await patientService.findPendingRequestsByCaregiver(userId);
    const recordsUnderCare: RecordUnderCareDto[] =
      await patientService.findRecordUnderCareByPatientUser(userId);
    usePatientRecordState.setState({
      caregiverRequests: requestsByCaregiver,
      recordsUnderCare: recordsUnderCare,
      status: 'success',
    });
    loadPatientRecordItems();
  } catch (error) {
    logInfo('Error in refreshCaregiverRequestsState');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patients-under-care'),
      },
      status: 'error',
    });
  }
};

export const refreshPatientRecordState = async (
  patientRecordId: string,
): Promise<void> => {
  usePatientRecordState.setState({
    status: 'loading',
  });
  try {
    const patientRecord =
      await patientService.findPatientRecord(patientRecordId);
    usePatientRecordState.setState({
      patientRecord: patientRecord,
      status: 'success',
    });
  } catch (error) {
    logInfo('Error in refreshPatientRecordState');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      status: 'error',
    });
  }
};

export const removeRecordUnderCare = async (
  caregiverId: string,
  recordId: string,
): Promise<void> => {
  usePatientRecordState.setState({
    status: 'loading',
  });
  try {
    await patientService.removeRecordUnderCare(caregiverId, recordId);
    await refreshCaregiverRequestsState();
  } catch (error) {
    logInfo('Error in refreshPatientRecordState');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      status: 'error',
    });
  }
};

export const createPatientRecord = async (
  createPatientRecord: CreatePatientRecordDto,
): Promise<void> => {
  usePatientRecordState.setState({
    status: 'loading',
  });
  try {
    await patientService.patientRecordCreate(createPatientRecord);
    await refreshCaregiverRequestsState();
  } catch (error) {
    logInfo('Error in refreshPatientRecordState');
    logError(error as Error);
    usePatientRecordState.setState({
      error: {
        message: getText('error-loading-patient-record'),
      },
      status: 'error',
    });
  }
};

export const userHasAccessToRecord = (
  selectedPatientRecordId: string,
): boolean => {
  const patientRecordItems =
    usePatientRecordState.getState().patientRecordItems;
  return (
    patientRecordItems.find(
      (item) => item.patientRecordId === selectedPatientRecordId,
    ) !== undefined
  );
};

export function getFilename(patientId: string) {
  return `${uuid()}_${patientId}.png`;
}

// handle image (generic)
export async function uploadFile(
  category: PharmacyCategory,
  filename: string,
  uri: string,
  pharmacyId: string,
): Promise<string> {
  // Get a singed link
  const responseWriteUrl = await FileStorageService.writeUrl(
    category,
    filename,
    pharmacyId,
  );

  try {
    if (Platform.OS === 'web') {
      // uploadAsync is not available on web
      const blob = await fetch(uri).then((r) => r.blob());
      await axios.put(responseWriteUrl.url, blob, {
        headers: { 'content-type': 'application/octet-stream' },
      });
    } else {
      await FileSystem.uploadAsync(responseWriteUrl.url, uri, {
        httpMethod: 'PUT',
        uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
        headers: { 'content-type': 'application/octet-stream' },
      });
    }
  } catch (error) {
    console.error('error uploading file:', error);
    usePatientIntakeState.setState({
      error: { message: getText('insurance-card-upload-error') },
      status: 'error',
    });
  }

  return filename;
}

export const getUserSubmissionsByPatientRecordId = async (): Promise<void> => {
  const patientRecordId = useUserState.getState().user?.patientRecordId;

  if (!patientRecordId) return;

  usePatientSubmissionsState.setState({
    status: 'loading',
  });

  try {
    const response = await formsService.getUserSubmissions(patientRecordId);

    usePatientSubmissionsState.setState({
      submissions: response,
      status: 'success',
    });
  } catch (e) {
    usePatientSubmissionsState.setState({
      error: { message: getText('insurance-card-upload-error') },
      status: 'error',
    });
  }
};

export const getLatestUserSubmissionsByPatientRecordId =
  async (): Promise<void> => {
    const patientRecordId = useUserState.getState().user?.patientRecordId;

    if (!patientRecordId) return;

    usePatientSubmissionsState.setState({
      status: 'loading',
    });

    try {
      const limit = 1;

      const response = await formsService.getUserSubmissions(
        patientRecordId,
        limit,
      );

      usePatientSubmissionsState.setState({
        latestSubmission: [...response].shift()?.created_at,
        status: 'success',
      });
    } catch (e) {
      usePatientSubmissionsState.setState({
        error: { message: getText('insurance-card-upload-error') },
        status: 'error',
      });
    }
  };
export const getSubmissionById = async (
  formId: string,
  submissionId: string,
  locationId: string,
) => {
  usePatientSubmissionsState.setState({
    error: undefined,
    focusedSubmissionStatus: 'loading',
  });

  try {
    const response = await formsService.getUserSubmissionById(
      locationId,
      formId,
      submissionId,
    );

    usePatientSubmissionsState.setState({
      focusedSubmission: response,
      focusedSubmissionStatus: 'success',
    });
  } catch (error: any) {
    usePatientSubmissionsState.setState({
      error: { message: getText('insurance-card-upload-error') },
      focusedSubmissionStatus: 'error',
    });
  }
};

export const getSubmissionPDF = async (
  formId: string,
  submissionId: string,
  locationId: string,
) => {
  try {
    usePatientSubmissionsState.setState({
      error: undefined,
      submissionPdfStatus: 'loading',
    });

    const response = await formsService.getSubmissionPDF(
      locationId,
      formId,
      submissionId,
    );

    usePatientSubmissionsState.setState({
      submissionPdfStatus: 'success',
    });
    return response;
  } catch (error: any) {
    usePatientSubmissionsState.setState({
      error: { message: getText('insurance-card-upload-error') },
      focusedSubmissionStatus: 'error',
    });
  }
};

export const resetRecordUnderCareState = () => {
  usePatientRecordState.setState({
    data: undefined,
  });
};

export interface PatientInfoForm {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  gender?: string;
  preferredPharmacyLocationId: string;
}

export interface MedicalInfoForm {
  allergies: string[];
  prefers_easy_open_bottle_caps?: boolean;
  medical_conditions: string[];
}

export interface PatientPreferencesForm {
  appNotifications: boolean;
  automatedVoiceCall: boolean;
  email: boolean;
  textMessage: boolean;
}

export interface PatientInsuranceForm {
  frontPhoto: Blob;
  backPhoto: Blob;
}

export interface PatientFeedback {
  target: 'app' | 'location';
  feedback: string;
  allowContact: boolean;
  pharmacyId: string;
  locationId?: string;
  patientId: string;
  osVersion: string;
  appVersion: string;
  appName: string;
  device: string;
}

export interface PatientStore {
  target: 'app' | 'location';
  feedback: string;
  allowContact: boolean;
  pharmacyId: string;
  locationId?: string;
  patientId: string;
  osVersion: string;
  appVersion: string;
  appName: string;
  device: string;
}

export interface PatientUnderCareForm extends MedicalInfoForm {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  relationship: PatientUnderCareRelationship;
  email?: string;
  requester_id: string;
  guardianship_approved: boolean;
  gender?: Gender;
  prefers_med_sync?: string;
  patient_record_id?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  state?: string;
  postal_code?: string;
}

export interface ResourceItem {
  value: string;
  text: string;
}
