import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { ConversationBox } from './ConversationBox';

const Stack = createStackNavigator<ConversationStackParamList>();

export const ConversationNavigation: FunctionComponent = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: ConversationNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen name="conversation-box" component={ConversationBox} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

type HeaderParam = {
  title: string;
};

export type ConversationStackParamList = {
  'conversation-box': {
    conversationId: string;
    locationPatientRecordId: string;
    title: string;
  };
  'new-message': undefined;
};

export type ConversationStackNavigationProps =
  StackNavigationProp<ConversationStackParamList>;

export const ConversationNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const handleBack = () => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      props.navigation.navigate('app', { screen: 'messages' });
    }
  };

  const handleActionPress = () => {
    props.navigation.navigate('app');
  };

  const getTitle = () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const params = props.route.params as HeaderParam;
    return params.title;
  };

  // TODO: follow up cards will make this dynamic based on the route as we add other routes
  return (
    <NativeHeader
      onBack={handleBack}
      onActionPress={handleActionPress}
      showAction={false}
      showBack={true}
      title={getTitle()}
    />
  );
};
