import { Form, ScreenContainer } from 'assets/layout';
import theme, { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ScrollView, View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { PrescriptionStackParamList } from '../FindPrescriptionNavigation';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Divider } from 'react-native-paper';
import { Button } from 'assets/components/button';
import { FindPrescriptionFlowTestIDs } from '../FindPrescriptionFlowTestIDs';
import { getText } from 'assets/localization/localization';
import refillService from '../../refill/refill-service';
import { PrescriptionCard } from '../../../components/prescription-card';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { useAddPrescriptionStore } from '../add-prescription-store';
import { Alert } from 'assets/components/alert';
import { useUserState } from '../../../store/user-store';
import { resetAddPrescriptionState } from '../add-prescription-actions';
import patientService from '../../../api/patient-service';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { useRefillMedicationsStore } from '../../refill/refill-store';

export const IntegratedAllPrescriptions: FunctionComponent<
  PropsWithChildren<IntegratedAllPrescriptionsProps>
> = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const { locationPatientRecordId, originated } = useAddPrescriptionStore();
  const [prescriptions, setPrescriptions] = useState<PrescriptionDto[]>([]);
  const [locationPatientRecord, setLocationPatientRecord] =
    useState<LocationPatientRecordDto>();
  const { updateMedicationsData } = useRefillMedicationsStore();

  const selectedLocationId = user?.preferredPharmacyLocationId;

  const setFindMedsScreenAsSeen = () => {
    void updateUser({ hasSeenFindMedsScreen: true });
  };

  useEffect(() => {
    if (!selectedLocationId || !locationPatientRecordId) return;

    void refillService
      .getPatientPrescriptions(selectedLocationId, locationPatientRecordId)
      .then((response) => {
        setPrescriptions(response);
        useAddPrescriptionStore.setState({ prescriptions: response });
      });

    void patientService
      .findLocationPatientRecord(selectedLocationId, locationPatientRecordId)
      .then((response) => {
        setLocationPatientRecord(response);
      });
  }, [selectedLocationId, locationPatientRecordId]);

  const handleOnPressDone = () => {
    setFindMedsScreenAsSeen();

    if (originated === 'medication' && updateMedicationsData) {
      updateMedicationsData({
        allMedications: prescriptions.map((p) => ({ ...p, checked: false })),
      });

      navigation.navigate('app', { screen: 'medications' });
    }

    resetAddPrescriptionState();
  };

  return (
    <ScreenContainer>
      <Alert
        title={getText('we-found-your-medications-alert-title')}
        intent={'success'}
      />
      <Text style={styles.textTitle}>{getText('your-prescriptions')}</Text>
      <Divider />
      <ScrollView style={styles.scrollView}>
        {prescriptions.map((item) => (
          <PrescriptionCard
            key={item.rx_number}
            patient={locationPatientRecord}
            prescription={item}
            selectable={false}
          />
        ))}
      </ScrollView>
      <View style={{ marginTop: theme.getSpacing(0.5) }}>
        <Form.Actions>
          <Button
            hierarchy="primary"
            onPress={handleOnPressDone}
            testID={FindPrescriptionFlowTestIDs.nextButton}
            logger={{ id: 'add-prescription' }}
          >
            {getText('done')}
          </Button>
        </Form.Actions>
        <Button
          hierarchy="tertiary"
          onPress={() => navigation.navigate('manual-prescription-flow')}
          testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
          logger={{ id: 'add-prescription' }}
        >
          {getText('other-meds')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export type IntegratedAllPrescriptionsProps = NativeStackScreenProps<
  PrescriptionStackParamList,
  'success-automatic-prescription-flow'
>;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(2),
  },
  scrollView: {
    height: 20,
  },
}));
