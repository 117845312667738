import {
  AddPrescriptionDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';

import patientService from '../../api/patient-service';
import {
  defaultAddPrescriptionState,
  useAddPrescriptionStore,
} from './add-prescription-store';
import { useRefillMedicationsStore } from '../refill/refill-store';
import refillService from '../refill/refill-service';
import { useUserState } from '../../store/user-store';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';

export const patientRecordAddPrescription = async (
  addPrescription: AddPrescriptionDto,
  navigation: NavigationProp<ParamListBase>,
) => {
  const patientRecordId = useAddPrescriptionStore.getState().patientRecord?.id;
  //const navigation = useNavigation<NavigationProp<ParamListBase>>();

  if (!patientRecordId) return;

  useAddPrescriptionStore.setState({ status: 'loading' });

  try {
    const patientResponse = await patientService.patientRecordAddPrescription(
      patientRecordId,
      {
        location_id: addPrescription.location_id,
        rx_number: addPrescription.rx_number,
        last_name: addPrescription.last_name,
        date_of_birth: addPrescription.date_of_birth,
      },
    );

    const createdLocationPatientRecordId =
      patientResponse.location_patient_records.find(
        (lpr) => lpr.location_id === addPrescription.location_id,
      )?.id;

    if (!createdLocationPatientRecordId) {
      useAddPrescriptionStore.setState({
        status: 'error',
        error: {
          message: getText('prescription-find-server-error'),
        },
      });
      return;
    }

    useAddPrescriptionStore.setState({
      status: 'idle',
      error: undefined,
      patientRecord: patientResponse,
      locationPatientRecordId: createdLocationPatientRecordId,
    });
    navigation.navigate('success-automatic-prescription-flow');
  } catch (error) {
    // 404 show a not found prescription to user
    // this is a big hack as the HttpErrorUtil is preprocessing the request
    if (
      error === 'The requested resource was not found' ||
      error === 'Bad Request'
    ) {
      useAddPrescriptionStore.setState({
        status: 'error',
        error: {
          message: getText(
            'unable-to-locate-your-prescription-alert-description',
          ),
        },
      });
    } else {
      useAddPrescriptionStore.setState({
        status: 'error',
        error: {
          message: getText('prescription-find-server-error'),
        },
      });
    }
  }
};

export const getPatientRecord = async (patientRecordId: string) => {
  useAddPrescriptionStore.setState({ status: 'loading' });

  try {
    const patientRecord =
      await patientService.findPatientRecord(patientRecordId);
    useAddPrescriptionStore.setState({
      status: 'idle',
      patientRecord: patientRecord,
    });
  } catch (e) {
    useAddPrescriptionStore.setState({
      status: 'error',
      error: {
        message: getText('error-loading-patient-record'),
      },
    });
  }
};

export const resetAddPrescriptionState = () => {
  useAddPrescriptionStore.setState(defaultAddPrescriptionState);
};

export const refreshPrescriptions = async () => {
  const { locationPatientRecordId } = useAddPrescriptionStore.getState();
  const { user } = useUserState.getState();

  if (!user?.preferredPharmacyLocationId) return alert('Missing Location');
  if (!locationPatientRecordId) return alert('Missing Location Patient Record');

  const prescriptions = await refillService.getPatientPrescriptions(
    user.preferredPharmacyLocationId,
    locationPatientRecordId,
  );

  useRefillMedicationsStore.setState({
    allMedications: prescriptions.map((prx) => ({
      ...prx,
      checked: false,
    })),
  });
};

export const ensureLocationPatientRecordExist = async (
  locationId: string,
  patientRecordId: string,
): Promise<string> => {
  const { patientRecord } = useAddPrescriptionStore.getState();

  const getLPRIdFromPR = (prDto: PatientRecordDto): string | undefined => {
    return prDto.location_patient_records.find(
      (lpr) => lpr.location_id === locationId,
    )?.id;
  };

  let currentPR = patientRecord;

  if (!currentPR) {
    // load PatientRecord if not on state;
    currentPR = await patientService.findPatientRecord(patientRecordId);
  }

  let lprId = getLPRIdFromPR(currentPR);

  // Init location patient record as it was not found
  if (!lprId) {
    currentPR = await patientService.patientRecordInitLocationPatientRecord(
      patientRecordId,
      { location_id: locationId },
    );

    lprId = getLPRIdFromPR(currentPR);

    if (!lprId) throw Error('Patient Record on location not initialized');
  }

  useAddPrescriptionStore.setState({
    locationPatientRecordId: lprId,
    patientRecord: currentPR,
  });

  return lprId;
};
