import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Platform } from 'react-native';
import { CloseConfirmationBottomSheet } from './CloseConfirmationBottomSheet';
import { CloseConfirmationModal } from './CloseConfirmationModal';

export const CloseConfirmationDialog: ForwardRefRenderFunction<
  CloseConfirmationHandler,
  CloseConfirmationProps
> = (props, ref: ForwardedRef<CloseConfirmationHandler>) => {
  const nativeRef = useRef<CloseConfirmationHandler>(null);

  useImperativeHandle(ref, () => ({
    show: () => nativeRef.current?.show(),
    hide: () => nativeRef.current?.hide(),
  }));

  return (
    <>
      {Platform.OS === 'web' ? (
        <CloseConfirmationWeb ref={nativeRef} {...props} />
      ) : (
        <CloseConfirmationMobile ref={nativeRef} {...props} />
      )}
    </>
  );
};

export const CloseConfirmation = forwardRef<
  CloseConfirmationHandler,
  CloseConfirmationProps
>(CloseConfirmationDialog);

export const CloseConfirmationMobile = forwardRef<
  CloseConfirmationHandler,
  CloseConfirmationProps
>(CloseConfirmationBottomSheet);

export const CloseConfirmationWeb = forwardRef<
  CloseConfirmationHandler,
  CloseConfirmationProps
>(CloseConfirmationModal);

export interface CloseConfirmationProps {
  onConfirm: () => void;
}

export interface CloseConfirmationHandler {
  show: () => void;
  hide: () => void;
}
