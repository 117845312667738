import { FunctionComponent, PropsWithChildren } from 'react';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { NotificationsEmptyStateIcon } from 'assets/icons';

export const NotificationsEmptyState: FunctionComponent<
  PropsWithChildren<NotificationsEmptyStateProps>
> = ({}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Icon
        icon={NotificationsEmptyStateIcon}
        size={80}
        color={theme.colors.brandedPrimary}
      />
      <Text style={styles.title}>{getText('no-notifications')}</Text>
      <Text>{getText('no-notifications-subtitle')}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    ...(Platform.OS !== 'web' && {
      marginTop: '50%',
    }),
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 18,
    paddingVertical: getSpacing(2),
  },
  text: {},
}));

export interface NotificationsEmptyStateProps {}
