import React, {
  FunctionComponent,
  ComponentType,
  createContext,
  useContext,
  useState,
  useRef,
} from 'react';
import {
  createBottomTabNavigator,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs';
import { Animated } from 'react-native';
import { useTheme } from '../../theme';

const BottomTabNavigator = createBottomTabNavigator();

export const BottomTabs: FunctionComponent<BottomTabsProps> = ({ screens }) => {
  const theme = useTheme();

  const hideTabs = () => {
    setShow(false);
    showTiming();
  };
  const showTabs = () => {
    setShow(true);
    hideTiming();
  };
  const [show, setShow] = useState(true);

  const animatedValue = useRef(new Animated.Value(0)).current;
  const showTiming = () => {
    // Will change fadeAnim value to 50 in 0 seconds
    Animated.timing(animatedValue, {
      toValue: 50,
      duration: 0,
      useNativeDriver: true,
    }).start();
  };
  const hideTiming = () => {
    // Will change fadeAnim value to 0 in 200 milliseconds
    Animated.timing(animatedValue, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  const BottomTabNavigationContextRef = useBottomTabNavigationContextRef();

  // renders only the first time
  React.useEffect(() => {
    BottomTabNavigationContextRef.current = {
      hide: hideTabs,
      show: showTabs,
    };
  }, []);
  return (
    <BottomTabNavigationContext.Provider
      value={BottomTabNavigationContextRef.current}
    >
      <BottomTabNavigator.Navigator
        screenOptions={() => {
          return {
            tabBarStyle: {
              shadowColor: theme.palette.gray[900],
              shadowOpacity: 0.1,
              shadowOffset: { width: 0, height: -2 },
              shadowRadius: 4,
              borderTopWidth: 0,
              /* TODO: maybe we can find another better effect using bottom with timing animation
               * using this example
               * @example
               *   Animated.timing({}).start(({ finished }) => {
               *    // completion callback
               *   });
               */
              display: !show ? 'none' : undefined,
              transform: [{ translateY: animatedValue }],
            },
            tabBarActiveTintColor: theme.colors.brandedPrimary,
            headerShown: false,
          };
        }}
      >
        {screens.map((screen, index) => (
          <BottomTabNavigator.Screen
            key={index}
            name={screen.name}
            component={screen.component}
            options={{
              ...screen.options,
              tabBarIcon: screen.icon,
              tabBarTestID: screen.testID,
              title: screen.label,
            }}
          />
        ))}
      </BottomTabNavigator.Navigator>
    </BottomTabNavigationContext.Provider>
  );
};

export interface BottomTabsProps {
  screens: Screen[];
}

interface Screen {
  component: ComponentType<any>;
  name: string;
  label: string;
  icon?: (props: {
    focused: boolean;
    color: string;
    size: number;
  }) => React.ReactNode;
  testID?: string;
  options?: BottomTabNavigationOptions;
}

export interface BottomTabNavigationContextProps {
  hide: () => void;
  show: () => void;
}

const BottomTabNavigationContext =
  createContext<BottomTabNavigationContextProps | null>(null);

let BottomTabNavigationContextRef:
  | React.MutableRefObject<BottomTabNavigationContextProps | null>
  | undefined = undefined;

export const useBottomTabNavigationContext = () => {
  return useContext(BottomTabNavigationContext);
};

export const useBottomTabNavigationContextRef = () => {
  if (!BottomTabNavigationContextRef)
    BottomTabNavigationContextRef =
      React.useRef<BottomTabNavigationContextProps | null>(null);
  return BottomTabNavigationContextRef;
};
