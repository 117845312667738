import { AxiosRequestConfig } from 'axios';
import { BaseApiService } from 'assets/core/api';
import { VUCA_API_BASE_URL } from '../../common/constants';

export interface IMedicationService {
  getMedicationInfo(locationId: string, ndc: string): Promise<any> | null;
}

export class MedicationService
  extends BaseApiService
  implements IMedicationService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
  ) {
    super(baseUrl, config, enableAuth);
  }

  async getMedicationInfo(ndc: string): Promise<MedicationInfo> {
    const { data } = await this.axiosInstance.post(
      `${VUCA_API_BASE_URL}/api/v2/medications/${ndc}/info`,
    );
    return data;
  }

  async getPDFInfo(url: string) {
    const { data } = await this.axiosInstance.get(`${VUCA_API_BASE_URL}${url}`);
    return data;
  }
}

export interface MedicationInfo {
  locale: string;
  locales: Locale[];
  medications: Medication[];
  viewer_id: any;
}

export interface Locale {
  name: string;
  value: string;
}

export interface Medication {
  name: string;
  short_name: string;
  ndc9: string;
  ndc11: any;
  patient_educationsheet: PatientEducationsheet;
  patient_educationsheet_pdf_download_uri: string;
  medication_guides: string[];
  medication_guides_pdf_download_uri: string;
  medication_video: MedicationVideo;
  administration_videos: any[];
  condition_videos: any[];
  images: Image[];
  disclaimer: string;
}

export interface Image {
  title: string;
  url: string;
}

export interface PatientEducationsheet {
  title: string;
  sections: Section[];
  copyright: string;
}

export interface Section {
  title: string;
  content: string;
}

export interface MedicationVideo {
  type: string;
  name: string;
  description: string;
  data: Data;
}

export interface Data {
  video_id: string;
  account_id: string;
  player_id: string;
  transcript: string;
}

export default new MedicationService(VUCA_API_BASE_URL, undefined, true);
