import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { CloseIcon } from 'assets/icons';
import { Orders } from './Orders';
import { OrderDetails } from './OrderDetails';

const Stack = createStackNavigator<OrderStackParamList>();

export const OrderNavigation: FunctionComponent<
  PropsWithChildren<OrderNavigationProps>
> = ({}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: OrderNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="order"
          component={Orders}
          options={{ title: getText('order') }}
        />
        <Stack.Screen
          name="order-details"
          component={OrderDetails}
          options={{ title: getText('order-details') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface OrderNavigationProps {}

export type OrderStackParamList = {
  order: undefined;
  'order-details': undefined;
};

export type OrderStackNavigationProp = StackNavigationProp<OrderStackParamList>;

export const OrderNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof OrderStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const handleActionPress = (event: GestureResponderEvent) => {
    props.navigation.navigate('order');
  };

  return (
    <>
      {routeName === 'order-details' && (
        <NativeHeader
          title={getText('order-details')}
          actionLabel={getText('cancel')}
          actionIcon={CloseIcon}
          onActionPress={handleActionPress}
          onBack={handleBack}
          showAction={true}
          showBack={true}
        />
      )}
    </>
  );
};
