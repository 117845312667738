import { Form, ScreenContainer } from 'assets/layout';
import theme, { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { RefillStackParamList } from '../refill/RefillNavigation';
import { RefillTestIDs } from '../refill/RefillTestIDs';
import { useRefillMedicationsStore } from './refill-store';

export const CheckSecondInsurance: FunctionComponent<
  PropsWithChildren<CheckSecondInsuranceProps>
> = ({ navigation }) => {
  const styles = useStyles();

  const { isFirstTimeRegister } = useRefillMedicationsStore();

  const form = useForm({
    defaultValues: {
      secondInsurance: '',
    },
  });

  const handleOnPressNext = async () => {
    if (form.getValues().secondInsurance === 'yes') {
      navigation.navigate('edit-patient-card', {
        cardType: CardType.InsuranceSecondary,
        nextNavigation: isFirstTimeRegister
          ? 'refill-details'
          : 'refill-review',
      });
      return;
    }
    return isFirstTimeRegister
      ? navigation.navigate('refill-details')
      : navigation.navigate('refill-review');
  };

  return (
    <ScreenContainer>
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText('have-second-insurance')}
              rules={{
                required: getText('field-required'),
              }}
              name="secondInsurance"
              values={[
                {
                  text: getText('yes'),
                  value: 'yes',
                },
                {
                  text: getText('no'),
                  value: 'no',
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <View style={styles.buttonContainer}>
          <Form.Actions>
            <View style={styles.buttonContainer}>
              <Button
                hierarchy="primary"
                onPress={form.handleSubmit(handleOnPressNext)}
                testID={RefillTestIDs.refillOrderDetailsSubmit}
                logger={{ id: RefillTestIDs.refillOrderDetailsSubmit }}
                style={styles.nextButton}
              >
                {getText('next')}
              </Button>
            </View>
          </Form.Actions>
        </View>
      </Form>
    </ScreenContainer>
  );
};

export type CheckSecondInsuranceProps = NativeStackScreenProps<
  RefillStackParamList,
  'check-second-insurance'
>;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  nextButton: {
    alignItems: 'center',
    width: '100%',
    marginTop: theme.getSpacing(1),
  },
}));
