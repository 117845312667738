import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const HomeMedicationsIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 53 53" testID={testID}>
      <Path
        fill="#405975"
        d="M53 26.5C53 11.864 41.136 0 26.5 0S0 11.864 0 26.5 11.864 53 26.5 53 53 41.136 53 26.5Z"
      />
      <Path
        fill="#2A3A4C"
        d="M26.947 52.988 12.31 38.351a5.282 5.282 0 0 0 3.59 1.401h15.9a5.3 5.3 0 1 0 0-10.6H15.9h3.118l-1.338-1.338a5.314 5.314 0 0 0 3.526 1.338c.899 0 1.81-.23 2.645-.711l6.885-3.976 6.885-3.974a5.3 5.3 0 0 0 1.227-8.205l14.15 14.15.002.064V26.713l-.001.013V26.814l-.001.004v.064l-.001.018v.045l-.001.018v.024l-.001.02v.022l-.003.105c0 .001 0 0 0 0-.333 14.194-11.828 25.618-26.045 25.854ZM10.6 34.488Zm0-.018Zm6.112-7.8Z"
      />
      <Path
        fill="#E7584A"
        d="M31.8 29.152h-7.95v10.6h7.95a5.3 5.3 0 1 0 0-10.6Z"
      />
      <Path
        fill="#C0D1DD"
        d="M15.9 29.152a5.3 5.3 0 1 0 0 10.6h7.95v-10.6H15.9Z"
      />
      <Path
        fill="#C0D1DD"
        d="M18.551 19.26a5.3 5.3 0 1 0 5.3 9.18l6.885-3.974-5.3-9.18-6.885 3.975Z"
      />
      <Path
        fill="#486FBD"
        d="M39.56 13.251a5.3 5.3 0 0 0-7.24-1.94l-6.884 3.975 5.3 9.18 6.885-3.975a5.3 5.3 0 0 0 1.94-7.24Z"
      />
      <Path
        fill="#E7584A"
        d="M14.84 37.035a1.06 1.06 0 1 0 2.12 0 1.06 1.06 0 0 0-2.12 0ZM12.058 34.452a1.06 1.06 0 1 0 2.12 0 1.06 1.06 0 0 0-2.12 0ZM20.074 34.452a1.06 1.06 0 1 0 2.12 0 1.06 1.06 0 0 0-2.12 0Z"
      />
      <Path
        fill="#486FBD"
        d="M20.074 26.503a1.06 1.06 0 1 0 2.12-.001 1.06 1.06 0 0 0-2.12 0ZM25.436 23.786a1.06 1.06 0 1 0 2.12 0 1.06 1.06 0 0 0-2.12 0Z"
      />
      <Path
        fill="#D3DFE7"
        d="M16.611 26.501a5.297 5.297 0 0 1 1.94-7.24l6.885-3.975 2.65 4.59L16.61 26.5Zm2.005-3.775a1.06 1.06 0 1 0 0 2.12 1.06 1.06 0 0 0 0-2.12Zm2.518-2.584a1.06 1.06 0 1 0 0 2.12 1.06 1.06 0 0 0 0-2.12Z"
      />
      <Path
        fill="#7F9AD1"
        d="m28.086 19.876-2.65-4.59 6.885-3.976a5.298 5.298 0 0 1 7.24 1.94l-11.475 6.626ZM18.616 24.846a1.06 1.06 0 1 1 0-2.12 1.06 1.06 0 0 1 0 2.12ZM21.134 22.262a1.06 1.06 0 1 1 0-2.12 1.06 1.06 0 0 1 0 2.12Z"
      />
      <Path
        fill="#EE8A81"
        d="M37.1 34.452H23.85v-5.3h7.95a5.3 5.3 0 0 1 5.3 5.3Z"
      />
      <Path
        fill="#D3DFE7"
        d="M23.85 34.452h-1.656a1.06 1.06 0 0 0-2.12 0h-5.897a1.06 1.06 0 0 0-2.12 0H10.6a5.3 5.3 0 0 1 5.3-5.3h7.95v5.3Zm-5.234-3.71a1.06 1.06 0 1 0 0 2.12 1.06 1.06 0 0 0 0-2.12Z"
      />
      <Path
        fill="#EE8A81"
        d="M14.178 34.452h-2.12a1.06 1.06 0 1 1 2.12 0ZM18.616 32.862a1.06 1.06 0 1 1 0-2.12 1.06 1.06 0 0 1 0 2.12ZM22.194 34.452h-2.12a1.06 1.06 0 1 1 2.12 0Z"
      />
    </Svg>
  );
};
