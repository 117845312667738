import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useResetPasswordState } from './reset-password-store';
import { getText } from 'assets/localization/localization';
import { useAppStateStore } from '../../store/app-store';
import usersService from '../../api/users-service';
import { ampli } from '../../common/ampliPatient';

export const sendResetLink = async (
  resetPasswordForm: ResetPasswordForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.resetPassword({
      ...resetPasswordForm,
      pharmacy_id: pharmacyId,
    });

    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link Sent',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link sending failed',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    if (e === 'The requested resource was not found') {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const resendResetLink = async (
  email: string,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.resetPassword({ email, pharmacy_id: pharmacyId });

    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link re-sent',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link re-send failed',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    if (e === 'The requested resource was not found') {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const resetPassword = async (
  values: ResetPasswordModel,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });

  try {
    await usersService.confirmPassword({
      pharmacy_id: pharmacyId,
      email: values.email,
      password: values.password,
      confirmation_code: values.confirmationCode,
      reset_password_link_id: values.resetPasswordLinkId,
    });
    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Password reset successful',
      passwordResetTime: new Date().toISOString(),
      resetLinkSentTime: '',
    });
    navigation.navigate('reset-successful');
  } catch (e) {
    ampli.resetPassword({
      passwordResetStatus: 'Password reset failed',
      passwordResetTime: new Date().toISOString(),
      resetLinkSentTime: '',
    });
    navigation.navigate('reset-unsuccessful');
  }
};

export enum SendOptions {
  email = 'email',
  sms = 'sms',
}

export interface ResetPasswordForm {
  email: string;
}

export interface ConfirmPasswordForm {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordModel
  extends ConfirmPasswordForm,
    Omit<ResetPasswordForm, 'passwordConfirm'> {
  confirmationCode: string;
  resetPasswordLinkId: string;
}
