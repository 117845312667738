import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { TouchableOpacity, View, Platform } from 'react-native';
import { Icon } from '../icon';
import { Text } from '../text';
import {
  AccountNotificationIcon,
  MedicationsNotificationIcon,
  MessagesNotificationIcon,
  ChevronsDownIcon,
  ChevronsUpIcon,
  NotificationTypeIcon,
  XIcon,
  ClockIcon,
} from '../../icons';
import { Tag } from '../tag';
import { IconButton } from '../icon-button';
import { formatDateTimeAsString } from '../../utils/dateTime';
import { getText } from '../../localization/localization';
import {
  NotificationCategory,
  NotificationType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { IconProps } from '../../icons/types';

export const NotificationListItem: FunctionComponent<
  PropsWithChildren<NotificationListItemProps>
> = ({
  type,
  category,
  body,
  timestamp,
  isRead = false,
  hideBorder = false,
}) => {
  const styles = useStyles();
  const [showMore, setShowMore] = useState(true);
  const theme = useTheme();

  const categoryIconMap: Record<
    NotificationCategory,
    React.FunctionComponent<React.PropsWithChildren<IconProps>>
  > = {
    medications: MedicationsNotificationIcon,
    messages: MessagesNotificationIcon,
    account: AccountNotificationIcon,
  };

  const typeIconColorMap: Record<NotificationType, string> = {
    positive_neutral: theme.palette.success[400],
    time_sensitive: theme.palette.primary[600],
    negative: theme.palette.warning[500],
  };

  const categoryIcon = categoryIconMap[category];
  const typeIconColor = typeIconColorMap[type];

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const isLongMessage = body.length > 60;

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          // TODO: Handle navigation with onPress
          alert('Under construction. Navigation coming soon!');
        }}
        style={styles.notificationListItemContainer}
        testID={NotificationListItemTestIDs.button}
      >
        <View style={styles.notificationListItem}>
          <Icon
            size={10}
            icon={NotificationTypeIcon}
            color={isRead ? theme.palette.gray[200] : typeIconColor}
          />
          <View style={styles.notificationMessageContainer}>
            {type === 'time_sensitive' && (
              <View testID={NotificationListItemTestIDs.timeSensitiveTag}>
                <Tag
                  icon={ClockIcon}
                  label={getText('time-sensitive')}
                  style={styles.typeTag}
                  labelColor={theme.palette.primary[600]}
                />
              </View>
            )}
            <View style={styles.notificationMessageInnerContainer}>
              <Text
                ellipsizeMode="tail"
                numberOfLines={isLongMessage && showMore ? 2 : undefined}
                style={{
                  ...styles.readMoreText,
                  color: isRead
                    ? theme.palette.gray[700]
                    : theme.palette.gray[900],
                }}
              >
                {body}
              </Text>
              {isLongMessage && (
                <View testID={NotificationListItemTestIDs.showMoreButton}>
                  <Tag
                    style={styles.showMoreButton}
                    icon={showMore ? ChevronsDownIcon : ChevronsUpIcon}
                    label={
                      showMore ? getText('show-more') : getText('show-less')
                    }
                    onPress={toggleShowMore}
                  />
                </View>
              )}
            </View>
            <View>
              <Text style={styles.timestamp}>
                {formatDateTimeAsString(timestamp)}
              </Text>
            </View>
          </View>
          {categoryIcon && (
            <Icon
              size={48}
              icon={categoryIcon}
              color={
                isRead ? theme.palette.gray[500] : theme.colors.brandedPrimary
              }
            />
          )}
          {Platform.OS === 'web' && (
            <IconButton
              size={24}
              icon={XIcon}
              color={theme.palette.gray[500]}
              onPress={() => {
                //TODO: Handle hiding notification
                alert('Under construction. Hiding notifications coming soon!');
              }}
              logger={{
                id: 'web-hide-notification-button',
              }}
            />
          )}
        </View>
      </TouchableOpacity>
      {!hideBorder && <View style={styles.borderBottom}></View>}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  notificationListItemContainer: {
    backgroundColor: theme.palette.transparent,
  },
  notificationListItem: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  borderBottom: {
    height: 1,
    backgroundColor: theme.palette.gray[300],
    marginBottom: theme.getSpacing(2),
  },
  notificationMessageContainer: {
    flex: 1,
    marginRight: getSpacing(2),
    marginLeft: getSpacing(2),
  },
  notificationMessageInnerContainer: {
    marginBottom: getSpacing(1),
  },
  typeTag: {
    marginBottom: getSpacing(1),
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    backgroundColor: 'transparent',
    marginTop: -getSpacing(0.5),
  },
  readMoreText: {
    marginTop: -getSpacing(0.5),
  },
  timestamp: {
    color: theme.palette.gray[700],
  },
  showMoreButton: {
    marginTop: getSpacing(1),
  },
}));

export interface NotificationListItemProps {
  type: NotificationType;
  category: NotificationCategory;
  body: string;
  timestamp: Date;
  isRead?: boolean;
  hideBorder?: boolean;
}

export const NotificationListItemTestIDs = {
  button: 'list-item-button',
  showMoreButton: 'show-more-button',
  timeSensitiveTag: 'time-sensitive-tag',
};
