import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { StorageKeys } from '../../../enums/storage-keys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BrightcoveVideoPlayerProp } from './types';
import { generateUrl } from './utils';

export const BrightcoveVideoPlayer: FunctionComponent<
  BrightcoveVideoPlayerProp
> = (props) => {
  return Platform.OS === 'web' ? (
    <BrightcoveVideoPlayerWeb {...props} />
  ) : (
    <BrightcoveVideoPlayerNative {...props} />
  );
};

export const BrightcoveVideoPlayerNative: FunctionComponent<
  BrightcoveVideoPlayerProp
> = ({ videoInfo, style, auth }) => {
  const [tokenValue, setTokenValue] = useState<string | null>();

  useEffect(() => {
    if (auth)
      void (async () => {
        const value = await AsyncStorage.getItem(StorageKeys.AccessToken);
        setTokenValue(value);
      })();
  }, [auth]);

  const render = () => {
    const loadingComponent = <LoadingIndicator />;
    if (!videoInfo.videoId) return loadingComponent;

    if (videoInfo.videoId && auth && !tokenValue) return loadingComponent;

    const src = generateUrl(videoInfo, auth);

    return (
      <View
        style={
          style ?? {
            width: '100%',
            height: '100%',
          }
        }
      >
        <WebView
          style={{
            outline: 'none',
            boxSizing: 'border-box',
            boxShadow: 'none',
          }}
          height="100%"
          width="100%"
          source={{
            uri: src,
          }}
          injectedJavaScriptBeforeContentLoaded={`
              localStorage.setItem("TokenKey", "${StorageKeys.AccessToken}");
              localStorage.setItem("${StorageKeys.AccessToken}", "${tokenValue}");
          `}
          javaScriptEnabled
          originWhitelist={['*']}
          useWebKit={true}
          mediaPlaybackRequiresUserAction={true}
          scrollEnabled
          nestedScrollEnabled
        />
      </View>
    );
  };

  return render();
};

export const BrightcoveVideoPlayerWeb: FunctionComponent<
  BrightcoveVideoPlayerProp
> = ({ videoInfo, style, auth }) => {
  const renderIframe = async () => {
    const src = generateUrl(videoInfo, auth);
    const iframe = document.createElement('iframe');
    iframe.id = `${videoInfo.videoId}-video-frame`; // frame id
    iframe.src = src;
    iframe.width = '100%';
    iframe.height = '100%';
    document.getElementById(videoInfo.videoId)?.appendChild(iframe);
    iframe.contentWindow?.localStorage.setItem(
      'TokenKey',
      StorageKeys.AccessToken,
    );
  };

  useEffect(() => {
    renderIframe();
  }, []);

  return (
    <View
      style={
        style ?? {
          width: '100%',
          height: '100%',
        }
      }
      nativeID={videoInfo.videoId}
      testID={videoInfo.videoId}
    >
      {!videoInfo.videoId ?? <LoadingIndicator />}
    </View>
  );
};
