import { RecordUnderCareDto } from '@digitalpharmacist/patient-service-client-axios';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Text } from 'assets/components/text';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { makeStyles } from 'assets/theme';
import { CheckboxField } from 'assets/components/checkbox';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import {
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from '../../patient-actions';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UseFormReturn } from 'react-hook-form/dist/types';

export const GuardianConfirmBottomSheetComponent: FunctionComponent<
  PropsWithChildren<{
    guardianBottomSheetRef: React.RefObject<BottomSheetModal>;
    dismissBottomSheet: (val: boolean) => void;
    legalGuardianAgreement: () => void;
    formValues: UseFormReturn<PatientUnderCareForm>;
    check: false;
  }>
> = ({
  guardianBottomSheetRef,
  dismissBottomSheet,
  legalGuardianAgreement,
  formValues,
  check = false,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [isChecked, setChecked] = useState(false);
  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {
      guardianship_approved: false,
    },
  });

  const handleOnPress = () => {
    !isChecked
      ? formValues.setValue('guardianship_approved', true)
      : formValues.setValue('guardianship_approved', false);
    updatePatientUnderCareForm(formValues.getValues());
    setChecked(!isChecked);
  };

  useEffect(() => {
    setChecked(check);
  }, [isChecked]);

  const onConfirmButtonPress = () => {
    legalGuardianAgreement();
    setChecked(false);
    dismissBottomSheet(false);
  };

  const onCloseButtonPress = () => {
    setChecked(false);
    formValues.setValue('guardianship_approved', false);
    dismissBottomSheet(false);
  };

  return (
    <BottomSheet
      bottomSheetRef={guardianBottomSheetRef}
      height={'90%'}
      footerContent={
        <SafeAreaView style={styles.buttonContainer}>
          <Button
            hierarchy="secondary-gray"
            logger={{
              id: 'cancel-bottom-sheet-button',
            }}
            style={{ marginEnd: theme.getSpacing(2), width: '50%' }}
            onPress={() => onCloseButtonPress()}
          >
            {getText('cancel')}
          </Button>
          <Button
            hierarchy="primary"
            logger={{
              id: 'confirm-bottom-sheet-button',
            }}
            style={{ width: '50%' }}
            onPress={() => onConfirmButtonPress()}
          >
            {getText('confirm')}
          </Button>
        </SafeAreaView>
      }
      onDismiss={() => onCloseButtonPress()}
      children={
        <View style={styles.mainContainer}>
          <Text style={styles.modalText}>
            {getText('legal-guardian-confirmation-for-person')}
          </Text>
          <Form methods={formValues}>
            <Form.Row>
              <Form.Column style={styles.checkBoxContainer}>
                <View>
                  <CheckboxField
                    checked={isChecked}
                    label={getText('legal-guardian-agreement', {
                      firstName: formValues.getValues().first_name,
                      lastName: formValues.getValues().last_name,
                    })}
                    name="guardianship_approved"
                    onPress={handleOnPress}
                  />
                </View>
              </Form.Column>
            </Form.Row>
          </Form>
        </View>
      }
      headerContent={
        <View style={styles.footerContent}>
          <View style={styles.headerIconStyle}>
            <IconButton
              icon={CloseIcon}
              onPress={() => dismissBottomSheet(false)}
              size={20}
              color={theme.palette.gray[500]}
              logger={{
                id: 'confirmation-close-button',
              }}
            />
          </View>
          <View style={styles.headerText}>
            <Text style={styles.modalHeader}>{getText('confirmation')}</Text>
          </View>
        </View>
      }
    ></BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: { alignSelf: 'center', flexDirection: 'column' },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  headerIconStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerContent: { justifyContent: 'center' },
  headerText: { alignSelf: 'center', marginTop: theme.getSpacing(1) },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
    padding: theme.getSpacing(1),
    alignSelf: 'center',
    marginTop: theme.getSpacing(2),
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  checkBoxContainer: {
    marginTop: theme.getSpacing(3),
    marginRight: theme.getSpacing(0.5),
    marginLeft: theme.getSpacing(0.5),
  },
}));
