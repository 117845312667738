/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 7
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'staging' | 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  staging: '6941cb51f96485466e285ddefc0c6c53',
  production: 'f3e229fbb85448826abfaea9aa6a88d2',
  development: 'f1b4aa2c6c3e9be57ae9e35a8b5023cf',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '7',
    branch: 'main',
    source: 'frontend',
    versionId: 'bb08901d-311e-4600-b500-40c3da8b8c9c',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: ReactNativeOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: ReactNativeOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: ReactNativeClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface AccountCreatedProperties {
  /**
   * Timestamp of the account created date, UTC
   */
  accountCreatedTime: string;
  /**
   * Type of sign-up flow that the user use to create account, such as email, google, apple ID
   */
  accountType: string;
}

export interface AccountVerifiedProperties {
  /**
   * Timestamp of the account verify date, UTC
   */
  accountVerifiedTime: string;
  /**
   * When the account verification code is sent
   */
  verificationCodeSentTime: string;
  /**
   * Method used to verify the account
   */
  verificationMethod: string;
  /**
   * Whether the verification is successful or not
   */
  verificationStatus: string;
}

export interface EmailVerifiedProperties {
  /**
   * THe time when the email verification code is sent
   */
  emailVerificationSentTime: string;
  /**
   * Whether the email verification is successful or not
   */
  emailVerificationStatus: string;
  /**
   * The time when the email is successfully verified
   */
  emailVerifiedTime: string;
}

export interface OnboardingCompletedProperties {
  /**
   * Timestamp of the whole onboarding processcompleted date, UTC
   */
  onBoardingCompletedTime: string;
  /**
   * Whether the onboarding is successful or not
   */
  onboardingCompleteStatus: string;
}

export interface OnboardingStepCompletedProperties {
  /**
   * The onboarding step the user has completed
   */
  onboardingStep: string;
  /**
   * Timestamp of the onboarding step completed date, UTC
   */
  onboardingStepCompletedTime: string;
  /**
   * The timezone the user sets for their account
   */
  timezone: string;
}

export interface ProfileInfoEditedProperties {
  /**
   * What did the user edit about the profile
   */
  informationEdited: string;
  /**
   * How user want to be contacted
   */
  preferredCommunication: string;
  /**
   * The language user prefer to use
   */
  preferredLanguage: string;
}

export interface ResetPasswordProperties {
  /**
   * The status of password reset
   */
  passwordResetStatus: string;
  /**
   * The time when password is reset
   */
  passwordResetTime: string;
  /**
   * The time when a password reset link is sent
   */
  resetLinkSentTime: string;
}

export interface UserLoggedInProperties {
  /**
   * Thee biometric authentication method used to login
   */
  biometricAuthenticationMethod: string;
  /**
   * Method used to log in
   */
  loginMethod: string;
  /**
   * Whether the login is successful or not
   */
  loginStatus: string;
  /**
   * The login time
   */
  loginTime: string;
  /**
   * The product version
   */
  productVersion: string;
}

export interface UserLoggedOutProperties {
  /**
   * How logout is happened? Self logout by clicking the logout button or auto logout due to inactivity.
   */
  logoutMethod: string;
  /**
   * The logout time
   */
  logoutTime: string;
}

export class AccountCreated implements BaseEvent {
  event_type = 'accountCreated';

  constructor(public event_properties: AccountCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountVerified implements BaseEvent {
  event_type = 'accountVerified';

  constructor(public event_properties: AccountVerifiedProperties) {
    this.event_properties = event_properties;
  }
}

export class EmailVerified implements BaseEvent {
  event_type = 'emailVerified';

  constructor(public event_properties: EmailVerifiedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompleted implements BaseEvent {
  event_type = 'onboardingCompleted';

  constructor(public event_properties: OnboardingCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStepCompleted implements BaseEvent {
  event_type = 'onboardingStepCompleted';

  constructor(public event_properties: OnboardingStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProfileInfoEdited implements BaseEvent {
  event_type = 'profileInfoEdited';

  constructor(public event_properties: ProfileInfoEditedProperties) {
    this.event_properties = event_properties;
  }
}

export class ResetPassword implements BaseEvent {
  event_type = 'resetPassword';

  constructor(public event_properties: ResetPasswordProperties) {
    this.event_properties = event_properties;
  }
}

export class UserLoggedIn implements BaseEvent {
  event_type = 'userLoggedIn';

  constructor(public event_properties: UserLoggedInProperties) {
    this.event_properties = event_properties;
  }
}

export class UserLoggedOut implements BaseEvent {
  event_type = 'userLoggedOut';

  constructor(public event_properties: UserLoggedOutProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * accountCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/accountCreated)
   *
   * This event tracks when a user creates a new account on our platform
   *
   * @param properties The event's properties (e.g. accountCreatedTime)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * accountVerified
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/accountVerified)
   *
   * This event is triggered when a user's account has been successfully verified
   *
   * @param properties The event's properties (e.g. accountVerifiedTime)
   * @param options Amplitude event options.
   */
  accountVerified(
    properties: AccountVerifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountVerified(properties), options);
  }

  /**
   * emailVerified
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/emailVerified)
   *
   * This event is triggered when a user's email address has been successfully verified
   *
   * @param properties The event's properties (e.g. emailVerificationSentTime)
   * @param options Amplitude event options.
   */
  emailVerified(
    properties: EmailVerifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmailVerified(properties), options);
  }

  /**
   * onboardingCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/onboardingCompleted)
   *
   * This event is triggered when a user successfully completes the onboarding process
   *
   * @param properties The event's properties (e.g. onBoardingCompletedTime)
   * @param options Amplitude event options.
   */
  onboardingCompleted(
    properties: OnboardingCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompleted(properties), options);
  }

  /**
   * onboardingStepCompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/onboardingStepCompleted)
   *
   * This event is triggered when a user completes a step in the onboarding process. It provides valuable insights into user progress and engagement with our platform
   *
   * @param properties The event's properties (e.g. onboardingStep)
   * @param options Amplitude event options.
   */
  onboardingStepCompleted(
    properties: OnboardingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepCompleted(properties), options);
  }

  /**
   * profileInfoEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/profileInfoEdited)
   *
   * This event tracks when a user edits their profile information
   *
   * @param properties The event's properties (e.g. informationEdited)
   * @param options Amplitude event options.
   */
  profileInfoEdited(
    properties: ProfileInfoEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileInfoEdited(properties), options);
  }

  /**
   * resetPassword
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/resetPassword)
   *
   * This event tracks when a user initiates the process to reset their password
   *
   * @param properties The event's properties (e.g. passwordResetStatus)
   * @param options Amplitude event options.
   */
  resetPassword(
    properties: ResetPasswordProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResetPassword(properties), options);
  }

  /**
   * userLoggedIn
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/userLoggedIn)
   *
   * This event tracks when a user successfully logs into the system
   *
   * @param properties The event's properties (e.g. biometricAuthenticationMethod)
   * @param options Amplitude event options.
   */
  userLoggedIn(
    properties: UserLoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLoggedIn(properties), options);
  }

  /**
   * userLoggedOut
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/userLoggedOut)
   *
   * This event captures when a user logs out of the application
   *
   * @param properties The event's properties (e.g. logoutMethod)
   * @param options Amplitude event options.
   */
  userLoggedOut(
    properties: UserLoggedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLoggedOut(properties), options);
  }
}

export const ampli = new Ampli();
