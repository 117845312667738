import React, { FunctionComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { MessageSquareIcon, PhoneIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { AppointmentsScreenRouteProp } from '../../../navigation/RootNavigation';
import {
  setInitialSubject,
  setShowMessageModal,
} from '../../messages/messages-actions';
import { SubjectOptionsEnum } from '../../messages/MessageProps';
import { useAppointmentsState } from '../appointments-store';

export const BookAppointmentError: FunctionComponent<
  BookAppointmentErrorProps
> = ({ navigation }) => {
  const styles = useStyles();
  const { location } = useAppointmentsState();

  const handleMessagePress = () => {
    setInitialSubject(SubjectOptionsEnum.Appointments);
    setShowMessageModal(true);
    navigation.navigate('messages');
  };

  const isWeb = Platform.OS === 'web';

  const handleCallPress = () => {
    if (!isWeb) {
      Linking.openURL(`tel:${location?.phone}`);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{getText('unable-to-process')}</Text>
      <Text style={styles.text}>{getText('try-again-later')}</Text>
      <View style={styles.buttonsContainer}>
        <Button
          hierarchy={'primary'}
          logger={{
            id: 'book-appointment-error-home-button',
          }}
          style={styles.button}
          onPress={() => navigation.navigate('home')}
        >
          {getText('home')}
        </Button>
        <Button
          hierarchy={'secondary'}
          logger={{
            id: 'book-appointment-error-message-button',
          }}
          icon={MessageSquareIcon}
          style={styles.button}
          onPress={handleMessagePress}
        >
          {getText('message-pharmacy')}
        </Button>
        {location?.phone && (
          <Button
            hierarchy={'tertiary-gray'}
            logger={{
              id: 'book-appointment-error-call-button',
            }}
            icon={PhoneIcon}
            style={styles.button}
            onPress={handleCallPress}
          >
            {getText('call-pharmacy')}
          </Button>
        )}
      </View>
    </View>
  );
};

export interface BookAppointmentErrorProps {
  navigation: AppointmentsScreenRouteProp;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 270,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    ...theme.fonts.medium,
    fontSize: 18,
    marginBottom: theme.getSpacing(2),
    color: theme.palette.gray[900],
    textAlign: 'center',
  },
  text: {
    fontSize: 14,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(3),
    textAlign: 'center',
  },
  button: {
    marginBottom: theme.getSpacing(2),
  },
  buttonsContainer: {
    paddingHorizontal: theme.getSpacing(1),
  },
}));
