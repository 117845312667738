import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { FORMS_API_BASE_URL } from '../common/constants';
import {
  FormFullDataDto,
  FormsApi as FormsServiceClient,
  SubmissionCoreMetadataDto,
  SubmissionFullDataDto,
  SubmissionsApi as SubmissionsServiceClient,
} from '@digitalpharmacist/forms-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IFormsService {}

export class FormsService extends BaseApiService implements IFormsService {
  private formsServiceClient: FormsServiceClient;
  private submissionsServiceClient: SubmissionsServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.formsServiceClient = new FormsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.submissionsServiceClient = new SubmissionsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async getForm(locationId: string, formId: string): Promise<FormFullDataDto> {
    const { data } = await this.formsServiceClient.formsGetForm(
      locationId,
      formId,
    );

    return data;
  }

  async getPrivateForm(
    locationId: string,
    formId: string,
    patientRecordId: string,
  ): Promise<string> {
    // need to cast from `unknown` as the end-point doesn't return a JSON, but rather HTML content that we want to treat as a string
    const { data } = (await this.formsServiceClient.formsGetPrivateFormContent(
      locationId,
      formId,
      patientRecordId,
    )) as unknown as { data: string };

    return data;
  }

  async getUserSubmissions(
    patientRecordId: string,
    limit?: number,
  ): Promise<SubmissionCoreMetadataDto[]> {
    const { data } =
      await this.submissionsServiceClient.submissionsGetForPatientRecordId(
        patientRecordId,
        limit,
      );

    return data;
  }

  async getUserSubmissionById(
    locationId: string,
    formId: string,
    submissionId: string,
  ): Promise<SubmissionFullDataDto> {
    const { data } =
      await this.submissionsServiceClient.submissionsGetSubmissionById(
        locationId,
        formId,
        submissionId,
      );

    return data;
  }
  async getSubmissionPDF(
    locationId: string,
    formId: string,
    submissionId: string,
  ): Promise<Blob> {
    const { data } =
      (await this.submissionsServiceClient.submissionsDownloadSubmissionPDF(
        locationId,
        formId,
        submissionId,
        { responseType: 'blob' },
      )) as unknown as { data: Blob };

    return data;
  }
}

export default new FormsService(
  FORMS_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
