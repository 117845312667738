import React, { FunctionComponent, PropsWithChildren } from 'react';
import { makeStyles } from 'assets/theme';

import { Tag } from 'assets/components/tag';
import { getOrderStatusText } from '../../utils';
import { OrderStatus } from '@digitalpharmacist/order-service-client-axios';

export const OrderStatusBadge: FunctionComponent<
  PropsWithChildren<OrderStatusBadgeProps>
> = ({ orderStatus }) => {
  const styles = useStyles();

  const badgeColorMap: Record<
    OrderStatus,
    {
      color: string;
      backgroundColor?: string;
    }
  > = {
    received: styles.badgeWarn,
    filled: styles.badgeWarn,
    out_for_delivery: styles.badgeWarn,
    ready_for_pickup: styles.badgeSuccess,
    picked_up: styles.badge,
    mailed: styles.badge,
    shipped: styles.badge,
    delivered: styles.badge,
    returned_to_stock: styles.badge,
    info_unavailable: styles.badgeWarn,
  };

  const badgeStyle = orderStatus
    ? badgeColorMap[orderStatus]
    : styles.badgeWarn;

  return <Tag style={badgeStyle} label={getOrderStatusText(orderStatus)} />;
};

export interface OrderStatusBadgeProps {
  orderStatus: OrderStatus;
}

const useStyles = makeStyles((theme) => ({
  badge: {
    color: theme.palette.gray[700],
  },
  badgeSuccess: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.success[100],
  },
  badgeWarn: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.warning[100],
  },
}));
