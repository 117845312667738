import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Text } from 'react-native';
import { Button } from 'assets/components/button';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';

/*
 * Show a message to the user that they denied camera access and need to update their system settings
 */
export const CameraAccessDenied: FunctionComponent<
  PropsWithChildren<CameraAccessDeniedProps>
> = ({ onBackPress }) => {
  const theme = useTheme();
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        padding: theme.getSpacing(1),
      }}
    >
      <Text>{getText('camera-access-denied')}</Text>
      <Button
        onPress={onBackPress}
        hierarchy={'tertiary'}
        size="medium"
        logger={{ id: 'camera-access-denied-button' }}
      >
        {getText('back')}
      </Button>
    </View>
  );
};

interface CameraAccessDeniedProps {
  onBackPress: () => void;
}
