import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Defs } from 'react-native-svg';
import Group from 'react-native-svg';
import ClipPath from 'react-native-svg';
import { IconProps } from './types';

export const RefreshSquareIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      color={color}
      testID={testID}
    >
      <Group clipPath="url(#a)">
        <Path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M11.333.667 14 3.333m0 0L11.333 6M14 3.333H4.667A2.667 2.667 0 0 0 2 6v1.333m2.667 8L2 12.667m0 0L4.667 10M2 12.667h9.333A2.667 2.667 0 0 0 14 10V8.667"
        />
      </Group>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
