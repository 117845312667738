import moment from 'moment';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';

// "New" is defined as unread and received within the last 90 days
export const isNew = (notification: UserNotificationDto) => {
  const today = moment();
  const notificationDate = moment(notification.created_at);
  const ninetyDaysAgo = today.clone().subtract(90, 'days');

  return notificationDate.isAfter(ninetyDaysAgo) && !notification.is_read;
};

// "This Week" is defined as read and received within the last 7 days
export const isThisWeek = (notification: UserNotificationDto) => {
  const today = moment();
  const notificationDate = moment(notification.created_at);
  const weekStart = today.clone().subtract(7, 'days');

  return notificationDate.isBetween(weekStart, today) && notification.is_read;
};

// "This Month" is defined as read and received within the last 8-30 days
export const isThisMonth = (notification: UserNotificationDto) => {
  const today = moment();
  const notificationDate = moment(notification.created_at);
  const monthStart = today.clone().subtract(30, 'days');
  const monthEnd = today.clone().subtract(8, 'days');

  return (
    notificationDate.isBetween(monthStart, monthEnd) && notification.is_read
  );
};

// "Older" is defined as read and received within the last >30 days
export const isOlderThanThisMonth = (notification: UserNotificationDto) => {
  const today = moment();
  const notificationDate = moment(notification.created_at);
  const monthStart = today.clone().subtract(30, 'days');

  return notificationDate.isBefore(monthStart) && notification.is_read;
};

export const sortNotifications = (notifications: UserNotificationDto[]) => {
  return notifications.sort((a, b) => {
    return moment(b.created_at).diff(moment(a.created_at));
  });
};
