import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';

interface MedicalInfoStore {
  status: AsyncStatus;
  error?: ApiError;
}
export const useMedicalInfoStore = create<MedicalInfoStore>(() => ({
  status: 'idle',
}));
