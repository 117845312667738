import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { EmailIcon, FilePlusIcon, ImageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { notImplementedAlert } from 'assets/utils/alert';
import React from 'react';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import UnifiedCommsService from '../../api/unified-comms-service';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export const ChatBoxActions: FunctionComponent<ChatBoxActionProps> = ({
  conversation,
  onClose,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();

  const markAsUnread = async () => {
    if (user?.preferredPharmacyLocationId && user.pharmacyId) {
      await UnifiedCommsService.updateUserViewedStatus(
        user.pharmacyId,
        user.preferredPharmacyLocationId,
        conversation.location_patient_id,
        conversation.conversation_id,
        {
          patient_viewed_all_messages: false,
          pharmacy_viewed_all_messages:
            conversation.pharmacy_viewed_all_messages,
        },
      );
    }

    onClose();
  };

  return (
    <View style={{ margin: theme.getSpacing(1) }}>
      <ListMenu>
        <ListItemLink onPress={notImplementedAlert} showIcon={false}>
          <View style={styles.listItemContainer}>
            <FilePlusIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('add-file')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink onPress={notImplementedAlert} showIcon={false}>
          <View style={styles.listItemContainer}>
            <ImageIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('add-image')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink
          onPress={markAsUnread}
          showIcon={false}
          showDivider={false}
        >
          <View style={styles.listItemContainer}>
            <EmailIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('mark-unread')}</Text>
          </View>
        </ListItemLink>
      </ListMenu>
    </View>
  );
};

interface ChatBoxActionProps {
  conversation: DirectMessagePatientDto;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.primary[600],
    fontSize: 16,
    fontWeight: '500',
    marginLeft: theme.getSpacing(0.5),
    marginVertical: theme.getSpacing(1),
  },

  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
