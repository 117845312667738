import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { PermissionStatus } from 'expo-location';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { TouchableOpacity } from '@gorhom/bottom-sheet';

import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { LoadingIndicator } from 'assets/components/loading-indicator';

import { Distance } from '../../../types';
import { StoreSelectorItemProps } from '../../../components/store-selector/types';

export const AvailableLocationItem: FunctionComponent<
  AvailableLocationItemProps
> = ({
  item = {} as Distance<PharmacyLocationDto>,
  onPress = (item: Distance<PharmacyLocationDto>) => {},
  status,
}: AvailableLocationItemProps) => {
  const styles = useStyles();

  const handleOnPress = () => onPress(item);

  return (
    <TouchableOpacity style={styles.container} onPress={handleOnPress}>
      <View style={styles.flexOne}>
        <View style={styles.titleContainer}>
          <View style={styles.flexOne}>
            <Text style={styles.title} ellipsizeMode="tail" numberOfLines={1}>
              {item?.name}
            </Text>
          </View>

          <View style={styles.distanceContainer}>
            {status === PermissionStatus.GRANTED && item.distance && (
              <Text style={styles.secondTitle}>
                {`${item.distance?.toFixed(2)} ${item.distanceUnit}`}
              </Text>
            )}

            {status === PermissionStatus.UNDETERMINED && (
              <LoadingIndicator size={14} />
            )}
          </View>
        </View>

        {item.address && (
          <View style={{ flexDirection: 'column' }}>
            <Text style={styles.secondTitle}>{item?.address?.address1}</Text>
            <Text style={styles.secondTitle}>
              {`${item?.address.city}, ${item.address.state} ${item.address.postal_code}`}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default AvailableLocationItem;

interface AvailableLocationItemProps
  extends Pick<StoreSelectorItemProps, 'item' | 'onPress' | 'status'> {}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 12,
    paddingBottom: 12,
    width: '100%',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
  secondTitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  flexOne: {
    flex: 1,
  },
  distanceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
