import React, { FunctionComponent } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { View, Text } from 'react-native';
import { Logo } from '../../components/logo/Logo';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import { Divider } from 'react-native-paper';
import * as Linking from 'expo-linking';
import { useAppStateStore } from '../../store/app-store';
import { FacebookIcon, InstagramIcon } from 'assets/icons';
import { formatPhoneNumber } from '../../utils';

export const GetTheApp: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();

  const { launchApp, currentUrl } = useDeepLinkingContext();

  const handleGetTheApp = () => {
    const { path, queryParams } = Linking.parse(currentUrl);
    const queryParamsStringified = new URLSearchParams(
      queryParams as Record<string, string>,
    ).toString();

    launchApp([path, queryParamsStringified].join('?'));
  };

  return (
    <ScreenContainer
      backgroundColor={theme.palette.white}
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
          marginLeft: theme.getSpacing(2),
          marginRight: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          marginTop: theme.getSpacing(1),
        }}
      >
        <View style={{ height: 80 }}>
          <Logo width={325} height={75} isOnDark={false} />
        </View>
        <Divider
          style={{
            marginTop: theme.getSpacing(1),
            marginBottom: theme.getSpacing(1),
          }}
        />
        <View style={{ marginTop: theme.getSpacing(3), alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 24,
              fontWeight: '500',
              lineHeight: 32,
              color: theme.palette.gray[800],
            }}
          >
            {getText('get-mobile-app')}
          </Text>
        </View>
        <View style={{ marginTop: theme.getSpacing(3), alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '400',
              lineHeight: 28,
              color: theme.palette.black,
              textAlign: 'center',
            }}
          >
            {getText('manage-subscription')}
          </Text>
        </View>
        <View
          style={{
            width: 240,
            alignSelf: 'center',
            marginTop: theme.getSpacing(3),
          }}
        >
          <Button
            style={[styles.button]}
            hierarchy={'primary'}
            onPress={handleGetTheApp}
            logger={{ id: 'get-the-app-button' }}
          >
            {getText('get-the-app')}
          </Button>
        </View>
        <View style={{ marginTop: 'auto' }}>
          <Footer />
        </View>
      </View>
    </ScreenContainer>
  );
};

const Footer: FunctionComponent = () => {
  const theme = useTheme();
  const { pharmacy } = useAppStateStore();

  return (
    <View
      style={{
        padding: theme.getSpacing(2),
        backgroundColor: theme.palette.gray[100],
      }}
    >
      <View
        style={{
          alignSelf: 'center',
          maxWidth: 200,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '500',
            lineHeight: 28,
            color: theme.palette.gray[700],
            textAlign: 'center',
            fontFamily: 'Lato_400Regular',
          }}
        >
          {`${pharmacy.email}`}
        </Text>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '500',
            lineHeight: 28,
            color: theme.palette.gray[700],
            textAlign: 'center',
            fontFamily: 'Lato_400Regular',
          }}
        >
          {`${formatPhoneNumber(pharmacy.phone!)}`}
        </Text>
      </View>
      <View
        style={{
          alignSelf: 'center',
          maxWidth: 200,
          flexDirection: 'row',
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(2),
        }}
      >
        <View style={{ marginRight: theme.getSpacing(1) }}>
          <FacebookIcon size={24} color={theme.palette.gray[600]} />
        </View>
        <View style={{ marginLeft: theme.getSpacing(1) }}>
          <InstagramIcon size={24} color={theme.palette.gray[600]} />
        </View>
      </View>
      <View
        style={{
          alignSelf: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '500',
            lineHeight: 28,
            color: theme.palette.gray[700],
            textAlign: 'center',
            fontFamily: 'Lato_400Regular',
          }}
        >
          {`${pharmacy.shipping_address?.address1}`}
        </Text>
        <Text
          style={{
            fontSize: 18,
            fontWeight: '500',
            lineHeight: 28,
            color: theme.palette.gray[700],
            textAlign: 'center',
            fontFamily: 'Lato_400Regular',
          }}
        >
          {`${pharmacy.shipping_address?.city}, ${pharmacy.shipping_address?.country} ${pharmacy.shipping_address?.postal_code}`}
        </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.colors.brandedPrimary,
  },
  marginBottom2: {
    marginBottom: theme.getSpacing(2),
  },
  marginTop1: {
    marginTop: theme.getSpacing(1),
  },
  marginTop3: {
    marginTop: theme.getSpacing(3),
  },
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
  text: {
    color: theme.palette.white,
    textAlign: 'center',
  },
  link: {
    ...theme.fonts.medium,
    textDecorationLine: 'underline',
    color: theme.palette.white,
  },
  thankYouText: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 30,
  },
  getStartedText: {
    fontSize: 18,
    lineHeight: 28,
    marginTop: theme.getSpacing(3),
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
}));
