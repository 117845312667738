import moment from 'moment';

export const getDateTimeFilename = (name: string, extension: string) => {
  const dateTime = moment().format('YYYY-MM-DD-hh-mm');
  return `${dateTime.toLowerCase()}-${name.replaceAll(' ', '-')}.${extension}`;
};

export const downloadFile = (data: Blob, name: string) => {
  const reader = new FileReader();
  const link = document.createElement('a');

  reader.onload = function () {
    const blobAsDataUrl = reader.result;
    link.target = '_blank';
    link.download = name;
    link.href = blobAsDataUrl as string;
    link.click();
  };

  reader.readAsDataURL(data);
};
