import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Card as PaperCard, Title, Paragraph } from 'react-native-paper';
import { Avatar } from '../avatar';
import { makeStyles, useTheme } from '../../theme';
import { View, Text, Platform } from 'react-native';
import { ChevronRightIcon } from '../../icons';
import { Icon } from '../icon';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { PlatformType } from '../types';

export const Card: FunctionComponent<PropsWithChildren<CardProps>> = ({
  title,
  content,
  name,
  navigate = undefined,
  platform = Platform.OS,
  showNavigationIcon = true,
  touchable = true,
  titleComponent,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const CardWrapper = ({ children }: PropsWithChildren<{}>) => {
    if (touchable) {
      return (
        <TouchableOpacity
          onPress={navigate}
          disabled={!navigate}
          style={styles.container}
        >
          {children}
        </TouchableOpacity>
      );
    }

    return <View style={styles.container}>{children}</View>;
  };

  return (
    <CardWrapper>
      <PaperCard>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              flex: 1,
              marginVertical: theme.getSpacing(1),
              marginLeft: theme.getSpacing(0.5),
            }}
          >
            <PaperCard.Content>
              {titleComponent ? titleComponent : <Title>{title}</Title>}
              <View>{content}</View>
            </PaperCard.Content>
            <PaperCard.Actions style={{ marginLeft: theme.getSpacing(0.5) }}>
              {name && (
                <>
                  <Avatar size={35} name={name}></Avatar>
                  <Text
                    testID={CardTestIDs.name}
                    style={{ marginLeft: theme.getSpacing(1) }}
                  >
                    {getFirstName(name)}
                  </Text>
                </>
              )}
            </PaperCard.Actions>
          </View>
          {platform === 'ios' && navigate && showNavigationIcon && (
            <View style={{ width: 32, marginTop: theme.getSpacing(2) }}>
              <Icon
                color={theme.colors.brandedPrimary}
                icon={ChevronRightIcon}
              ></Icon>
            </View>
          )}
        </View>
      </PaperCard>
    </CardWrapper>
  );
};

const getFirstName = (name: string) => {
  const words = name.split(' ');
  const firstName = words[0];
  return firstName;
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    ...(Platform.OS === 'android' && {
      elevation: 2,
      marginRight: theme.getSpacing(1),
      marginLeft: theme.getSpacing(1),
    }),
  },
}));

export interface CardProps {
  title: string;
  content: JSX.Element | string;
  name?: string;
  navigate?: () => void;
  platform?: PlatformType;
  showNavigationIcon?: boolean;
  titleComponent?: JSX.Element;
  touchable?: boolean;
}

export const CardTestIDs = {
  name: 'card-name',
};
