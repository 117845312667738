import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';
import { StyleProp, ViewStyle } from 'react-native';

export const CornerIcon: FunctionComponent<
  IconProps & { style?: StyleProp<ViewStyle> }
> = ({ color, size, testID, style }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      stroke="currentColor"
      strokeWidth={4}
      color={color}
      testID={testID}
      style={style}
    >
      <Path d="m4,38l0,-34l44,0" />
    </Svg>
  );
};
