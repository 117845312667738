import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Platform } from 'react-native';
import {
  OrderMedicationsHandler,
  OrderMedicationsProps,
} from './OrderMedicationsAlertProps';
import { OrderMedicationsModal } from './OrderMedicationsModal';
import { OrderMedicationsBottomSheet } from './OrderMedicationsBottomSheet';

const OrderMedicationsAlertComponent: ForwardRefRenderFunction<
  OrderMedicationsHandler,
  OrderMedicationsProps
> = (
  { platform = Platform.OS, ...rest }: OrderMedicationsProps,
  ref: ForwardedRef<OrderMedicationsHandler>,
) => {
  const nativeRef = React.useRef<OrderMedicationsHandler>(null);
  const webRef = React.useRef<OrderMedicationsHandler>(null);

  const handleShow = () => {
    if (platform === 'web') webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (platform === 'web') webRef.current?.hide();
    else nativeRef.current?.hide();
  };
  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <>
      {platform === 'web' ? (
        <OrderMedicationsModal {...rest} ref={webRef} />
      ) : (
        <OrderMedicationsBottomSheet {...rest} ref={nativeRef} />
      )}
    </>
  );
};

export const OrderMedicationsAlert = forwardRef<
  OrderMedicationsHandler,
  OrderMedicationsProps
>(OrderMedicationsAlertComponent);
