import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { RefillMedications } from './RefillMedications';
import { RefillReview } from './RefillReview';
import { RefillConfirmation } from './RefillConfirmation';
import { RefillMedicalInfos } from './RefillMedicalInfos';
import { RefillOrderDetails } from './RefillOrderDetails';
import { CloseIcon } from 'assets/icons';
import { ChoosePrescriptionMethod } from './components/ChoosePrescriptionMethod';
import { ChooseInsuranceType } from './components/ChooseInsuranceType';
import { OtherInformationModal } from './components/OtherInfromationModal';
import { useRefillMedicationsStore } from './refill-store';
import { RefillDetails } from './RefillDetails';
import { RefillNotes } from './RefillNotes';
import { EditPatientCard } from '../account/patient/EditPatientCard';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { CheckSecondInsurance } from './CheckSecondInsurance';
import { InsuranceAndIDCards } from '../account/patient/InsuranceAndIDCards';
import { usePatientIntakeState } from '../account/patient/patient-store';
import { useUserState } from '../../store/user-store';

const Stack = createStackNavigator<RefillStackParamList>();

export const RefillNavigation: FunctionComponent<
  PropsWithChildren<RefillNavigationProps>
> = ({}) => {
  const { showRefillMedications, isFirstTimeRegister } =
    useRefillMedicationsStore();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: RefillNavigationHeader,
      }}
    >
      <Stack.Group>
        {showRefillMedications && (
          <Stack.Screen
            name="refill-medications"
            component={RefillMedications}
            options={{ title: getText('refill-medications') }}
          />
        )}
        {isFirstTimeRegister && (
          <>
            <Stack.Screen
              name="refill-medical-infos"
              component={RefillMedicalInfos}
              options={{ title: getText('refill-medical-infos') }}
            />
            <Stack.Screen
              name="refill-details"
              component={RefillDetails}
              options={{ title: getText('refill-details') }}
            />
            <Stack.Screen
              name="refill-choose-prescription-method"
              component={ChoosePrescriptionMethod}
              options={{ title: getText('refill-prescription-method') }}
            />
          </>
        )}
        <Stack.Screen
          name="edit-patient-card"
          component={EditPatientCard}
          options={{ title: getText('patient-insurance') }}
          initialParams={{
            cardType: CardType.InsurancePrimary,
            nextNavigation: 'check-second-insurance',
          }}
        />
        <Stack.Screen
          name="check-second-insurance"
          component={CheckSecondInsurance}
          options={{ title: getText('patient-insurance') }}
        />
        <Stack.Screen
          name="change-insurance"
          component={InsuranceAndIDCards}
          options={{ title: getText('insurance-and-id-cards') }}
        />
        <Stack.Screen
          name="refill-review"
          component={RefillReview}
          options={{ title: getText('refill-review') }}
        />
        <Stack.Screen name="refill-notes" component={RefillNotes} />
        <Stack.Screen
          name="refill-other-information"
          component={OtherInformationModal}
          options={{ title: getText('refill-other-information') }}
        />
        <Stack.Screen
          name="refill-prescription-method"
          component={ChoosePrescriptionMethod}
          options={{ title: getText('refill-prescription-method') }}
        />
        <Stack.Screen
          name="refill-insurance-method"
          component={ChooseInsuranceType}
          options={{ title: getText('refill-insurance-method') }}
        />
        <Stack.Screen
          name="refill-confirmation"
          component={RefillConfirmation}
          options={{
            title: getText('refill-confirmation'),
          }}
        />
        <Stack.Screen
          name="refill-order-details"
          component={RefillOrderDetails}
          options={{ title: getText('refill-order-details') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface RefillNavigationProps {}

export type RefillStackParamList = {
  'refill-medical-infos': undefined;
  'refill-order-details': undefined;
  'refill-details': undefined;
  'refill-review': undefined;
  'refill-confirmation': undefined;
  'refill-medications': undefined;
  'refill-prescription-method': undefined;
  'refill-choose-prescription-method': undefined;
  'refill-insurance-method': undefined;
  'refill-other-information': undefined;
  'refill-notes': undefined;
  'edit-patient-card': {
    cardType: CardType;
    nextNavigation?: keyof RefillStackParamList;
  };
  'check-second-insurance': undefined;
  'change-insurance': undefined;
};

export type RefillStackNavigationProp =
  StackNavigationProp<RefillStackParamList>;

const RefillNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof RefillStackParamList;

  const { data, updateData } = usePatientIntakeState();
  const { updateMedicationsData } = useRefillMedicationsStore();

  const handleBack = (event: GestureResponderEvent) => {
    if (
      routeName === 'change-insurance' ||
      routeName === 'check-second-insurance' ||
      routeName === 'edit-patient-card'
    ) {
      if (!updateData || !updateMedicationsData) return;

      updateData({
        isRefill: false,
        data: data,
        status: 'success',
      });
      updateMedicationsData({
        selectedPatientRecord: undefined,
      });
      props.navigation.goBack();
    } else {
      props.navigation.goBack();
    }
  };

  const handleActionPress = (event: GestureResponderEvent) => {
    if (
      routeName === 'change-insurance' ||
      routeName === 'check-second-insurance' ||
      routeName === 'edit-patient-card'
    ) {
      if (!updateData || !updateMedicationsData) return;
      updateData({
        isRefill: false,
        data: data,
        status: 'success',
      });
      updateMedicationsData({
        selectedPatientRecord: undefined,
      });
      props.navigation.navigate('app');
    } else {
      props.navigation.navigate('app');
    }
  };

  const showActions = routeName !== 'refill-confirmation';

  return (
    <NativeHeader
      title={getText('medications-fill-your-prescription')}
      actionLabel={getText('cancel')}
      actionIcon={CloseIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={showActions}
      showBack={showActions}
    />
  );
};
