import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const MedicationTherapyManagementIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    testID={testID}
    viewBox="0 0 70 80"
  >
    <Path
      fill={colorSecondary}
      fillRule="evenodd"
      d="m44.16 57.4-.022-.034a3.479 3.479 0 0 0-2.133-1.549 3.496 3.496 0 0 0-4.24 2.535 3.452 3.452 0 0 0 .387 2.622l.006.015 6.003-3.588Zm4.376-17.453-.023-.035a3.48 3.48 0 0 0-2.133-1.549 3.496 3.496 0 0 0-4.24 2.535 3.452 3.452 0 0 0 .388 2.622l.005.014 6.003-3.587Zm-4.413 7.423c-3.124-.776-5.029-3.923-4.247-7.035.779-3.11 3.944-5 7.07-4.227 3.127.776 5.029 3.926 4.25 7.036-.782 3.109-3.946 5.002-7.073 4.226Zm8.785-24.865-.023-.035a3.49 3.49 0 0 0-2.132-1.55 3.5 3.5 0 0 0-4.241 2.538 3.444 3.444 0 0 0 .385 2.62l.009.014 6.002-3.587Zm-4.413 7.426c-3.127-.776-5.028-3.926-4.25-7.036.782-3.109 3.947-5.002 7.074-4.226 3.123.776 5.028 3.923 4.246 7.035-.778 3.11-3.943 5.002-7.07 4.227ZM22.087 51.926l-.024-.035a3.479 3.479 0 0 0-2.132-1.549 3.496 3.496 0 0 0-4.241 2.535 3.465 3.465 0 0 0 .385 2.622l.009.015 6.003-3.588Zm-4.413 7.423c-3.127-.773-5.03-3.923-4.25-7.032.781-3.112 3.946-5.002 7.073-4.227 3.124.773 5.029 3.924 4.247 7.033-.779 3.11-3.944 5.002-7.07 4.226Zm8.788-24.877-.024-.035a3.479 3.479 0 0 0-2.132-1.549 3.496 3.496 0 0 0-4.24 2.535c-.234.927-.07 1.86.384 2.622l.009.015 6.003-3.588Zm-4.413 7.423c-3.127-.776-5.029-3.923-4.25-7.032.782-3.112 3.946-5.002 7.073-4.226 3.124.772 5.029 3.922 4.247 7.032-.779 3.11-3.944 5.002-7.07 4.226ZM30.83 17.03l-.02-.035a3.499 3.499 0 0 0-2.133-1.55 3.505 3.505 0 0 0-4.243 2.538 3.443 3.443 0 0 0 .388 2.62l.008.014 6-3.587Zm-4.41 7.426c-3.127-.776-5.029-3.926-4.25-7.035.779-3.11 3.947-5.003 7.07-4.227 3.127.776 5.029 3.926 4.25 7.035-.779 3.11-3.943 5-7.07 4.227Zm13.327 40.368c-3.124-.773-5.029-3.923-4.247-7.032.779-3.11 3.943-5.003 7.07-4.227 3.127.773 5.029 3.923 4.25 7.033-.779 3.109-3.947 5.002-7.073 4.226Z"
      clipRule="evenodd"
    />
    <Path
      fill={color}
      d="M44.432 76.147 8.208 67.163h-.003a6.405 6.405 0 0 1-3.946-2.91 6.33 6.33 0 0 1-.732-4.816v-.003L16.795 6.509a6.37 6.37 0 0 1 2.926-3.926 6.405 6.405 0 0 1 4.842-.727l36.226 8.984a6.397 6.397 0 0 1 3.947 2.911 6.323 6.323 0 0 1 .732 4.819L52.202 71.495a6.375 6.375 0 0 1-2.928 3.926 6.405 6.405 0 0 1-4.842.726ZM9.168 63.821l36.098 8.954a2.942 2.942 0 0 0 2.217-.332 2.89 2.89 0 0 0 1.327-1.777l13.266-52.928a2.9 2.9 0 0 0-.333-2.205 2.908 2.908 0 0 0-1.788-1.321L23.73 5.228a2.942 2.942 0 0 0-2.217.333 2.89 2.89 0 0 0-1.327 1.776L6.948 60.151a2.526 2.526 0 0 1-.052.213 2.889 2.889 0 0 0 .359 2.106c.367.613.96 1.088 1.694 1.295.073.015.146.035.219.056Zm30.83-43.178a.872.872 0 0 1-1.055.636.865.865 0 0 1-.64-1.05l1.13-4.506a.875.875 0 0 1 1.055-.636c.47.117.756.586.64 1.053l-1.13 4.503Zm-8.394 33.488a.874.874 0 0 1-1.056.636.87.87 0 0 1-.639-1.05l1.2-4.786a.876.876 0 0 1 1.055-.636c.47.116.755.586.642 1.053l-1.202 4.783Zm2.117-8.447a.871.871 0 0 1-1.055.636.868.868 0 0 1-.64-1.05l1.2-4.787a.876.876 0 0 1 1.056-.635c.47.116.755.586.638 1.053l-1.199 4.783Zm2.051-8.173a.874.874 0 0 1-1.698-.414l1.196-4.772a.874.874 0 0 1 1.056-.636c.47.114.756.587.639 1.05l-1.193 4.772Zm-6.213 24.784a.874.874 0 0 1-1.056.636.865.865 0 0 1-.638-1.05l1.128-4.506a.874.874 0 0 1 1.695.417l-1.129 4.503Zm8.328-33.219a.876.876 0 0 1-1.06.636.87.87 0 0 1-.638-1.05l1.202-4.787a.871.871 0 0 1 1.056-.636.869.869 0 0 1 .639 1.05l-1.2 4.787Z"
    />
  </Svg>
);
