import { AxiosRequestConfig } from 'axios';
import { FILE_STORAGE_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';

import {
  DefaultApi as FileStorageClient,
  PharmacyCategory,
  FileUploadResponseDto,
} from '@digitalpharmacist/file-storage-service-client-axios';

export class FileStorageService extends BaseApiService {
  private fileStorageServiceClient: FileStorageClient;
  public imageExtensions: string[] = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
  ];
  static getFileExtension: any;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.fileStorageServiceClient = new FileStorageClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async writeUrl(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageWriteUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  async readUrl(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  getFileExtension(name: string): string {
    const attachmentParts = name.split('.');
    return attachmentParts[attachmentParts.length - 1];
  }

  isImage(fileName: string): boolean {
    const extension = this.getFileExtension(fileName);
    return this.imageExtensions.includes(extension);
  }
}

export default new FileStorageService(
  FILE_STORAGE_SERVICE_BASE_URL,
  undefined,
  true,
);
