import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View, Image } from 'react-native';
import { PHARMACY_ID, STATIC_DEV_LUMISTRY } from '../../common/constants';

export const Logo: FunctionComponent<PropsWithChildren<LogoProps>> = ({
  width = 241,
  height = 105,
  isOnDark = true,
}) => {
  const uri =
    STATIC_DEV_LUMISTRY +
    PHARMACY_ID +
    (isOnDark ? '/images/logo_on_dark.png' : '/images/logo_on_light.png');

  const styles = useStyles();
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImageSource = () => {
    if (imageError) {
      return isOnDark
        ? require('../../../assets/logo_on_dark.png')
        : require('../../../assets/logo_on_light.png');
    } else {
      return { uri };
    }
  };

  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        source={renderImageSource()}
        style={[styles.logo, { width: width, height: height }]}
        onError={handleImageError}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    resizeMode: 'contain',
  },
}));

interface LogoProps {
  width?: number;
  height?: number;
  isOnDark?: boolean;
}
