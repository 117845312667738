import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const BellIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    >
      <Path d="M11.73 20a2 2 0 0 1-3.46 0M16 7A6 6 0 1 0 4 7c0 7-3 9-3 9h18s-3-2-3-9Z" />
    </Svg>
  );
};
