import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Platform, View } from 'react-native';
import { Button } from 'assets/components/button';
import { UseFormReturn } from 'react-hook-form';
import { PatientUnderCareForm } from '../../patient-actions';
import { usePatientRecordState } from '../../patient-store';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { FunctionComponent, PropsWithChildren } from 'react';
import { MedicalInfoFormFields } from '../../../../../components/medical-info/MedicalInfoFormFields';

export const EditMedicalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    record?: RecordUnderCareDto;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ patientRecord, changeViewMode, handleSave, record, form }) => {
  const { status, error } = usePatientRecordState();

  const handleSubmit = () => {
    changeViewMode(false);

    const { allergies, prefers_easy_open_bottle_caps, medical_conditions } =
      form.getValues();

    handleSave({
      allergies,
      prefers_easy_open_bottle_caps,
      medical_conditions,
    });
  };

  return (
    <View>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <MedicalInfoFormFields />
          </Form.Column>
        </Form.Row>
        {(Platform.OS !== 'web' || record) && (
          <Form.Actions>
            <Button
              onPress={form.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'personal-info-save-button' }}
            >
              {getText('save')}
            </Button>
          </Form.Actions>
        )}
      </Form>
    </View>
  );
};
