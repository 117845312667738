import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { PatientUnderCareForm } from '../patient-actions';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import states from '../../../../types/states';
import { UseFormReturn } from 'react-hook-form';

export const Address: FunctionComponent<
  PropsWithChildren<{
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const address1 = form.watch('address1');
  const address2 = form.watch('address2');
  const city = form.watch('city');
  const postal_code = form.watch('postal_code');
  const country = form.watch('country');
  const state = form.watch('state');
  const actualStatesKey = country === 'CA' ? 'CA' : 'US';

  const isFormRequired = () =>
    address1 || address2 || city || postal_code ? true : false;

  const [addressFormIsRequired, setAddressFormIsRequired] = useState(
    isFormRequired(),
  );

  useEffect(() => {
    const listOfStates = states[actualStatesKey];
    const stateExistOnActualList =
      listOfStates.filter((x) => x.value === state).length > 0;
    if (!stateExistOnActualList) form.setValue('state', listOfStates[0].value);
  }, [country]);

  useEffect(() => {
    setAddressFormIsRequired(isFormRequired());
  }, [address1, address2, city, postal_code]);

  useEffect(() => {
    if (address2) {
      form.trigger(['address1', 'city', 'postal_code']);
    }
  }, [addressFormIsRequired]);

  return (
    <View>
      <Text style={{ marginBottom: theme.getSpacing(1) }}>
        {getText('address')}
      </Text>
      <TextField
        style={styles.marginBottom}
        name={'address1'}
        label={getText('street-address')}
        rules={{
          required:
            addressFormIsRequired && getText('street-address-is-required'),
        }}
      />
      <TextField
        style={styles.marginBottom}
        name={'address2'}
        label={getText('street-address-line-2')}
      />
      <View style={styles.rowDirection}>
        <View style={styles.cityAndZipCodeText}>
          <TextField
            name={'city'}
            label={getText('city')}
            rules={{
              required: addressFormIsRequired && getText('city-is-required'),
            }}
          />
        </View>
        <View style={styles.dropDownSelectContainer}>
          <DropdownSelect
            options={states[actualStatesKey]}
            fieldName="state"
            label={getText('state')}
            labelInlined={true}
          />
        </View>
      </View>
      <View style={styles.rowDirection}>
        <View style={styles.cityAndZipCodeText}>
          <TextField
            name="postal_code"
            label={getText('zip-code')}
            rules={{
              required:
                addressFormIsRequired && getText('zip-code-is-required'),
            }}
            style={styles.marginBottom}
          />
        </View>
        <View style={styles.dropDownSelectContainer}>
          <DropdownSelect
            fieldName="country"
            label={getText('country')}
            labelInlined={true}
            disabled={true}
            options={[
              {
                label: 'US',
                value: 'US',
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  dropDownSelectContainer: {
    flex: 0.5,
  },
  rowDirection: {
    flexDirection: 'row',
  },
  cityAndZipCodeText: { flex: 1, marginRight: getSpacing(2) },
  marginBottom: { marginBottom: theme.getSpacing(2) },
}));
