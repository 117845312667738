import { BookingDto } from '@digitalpharmacist/appointment-service-client-axios';
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Platform } from 'react-native';
import { CancelAppointmentResultBottomSheet } from './CancelAppointmentResultBottomSheet';
import { CancelAppointmentResultModal } from './CancelAppointmentResultModal';

export const CancelAppointmentResultDialog: ForwardRefRenderFunction<
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps
> = (props, ref: ForwardedRef<CancelAppointmentResultHandler>) => {
  const nativeRef = useRef<CancelAppointmentResultHandler>(null);

  useImperativeHandle(ref, () => ({
    show: () => nativeRef.current?.show(),
    hide: () => nativeRef.current?.hide(),
  }));

  return (
    <>
      {Platform.OS === 'web' ? (
        <CancelAppointmentResultWeb ref={nativeRef} {...props} />
      ) : (
        <CancelAppointmentResultMobile ref={nativeRef} {...props} />
      )}
    </>
  );
};

export const CancelAppointmentResult = forwardRef<
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps
>(CancelAppointmentResultDialog);

export const CancelAppointmentResultMobile = forwardRef<
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps
>(CancelAppointmentResultBottomSheet);

export const CancelAppointmentResultWeb = forwardRef<
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps
>(CancelAppointmentResultModal);

export interface CancelAppointmentResultProps {
  booking: BookingDto;
}

export interface CancelAppointmentResultHandler {
  show: () => void;
  hide: () => void;
}
