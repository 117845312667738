import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const GeneralMessageCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 37 36"
      fill="none"
      color={color}
      testID={testID}
    >
      <Rect x="0.5" width="36" height="36" rx="18" fill="#9CAFB9" />
      <Path
        d="M26 17.5834C26.0029 18.6832 25.7459 19.7683 25.25 20.75C24.662 21.9265 23.7581 22.916 22.6395 23.6078C21.521 24.2995 20.2319 24.6662 18.9167 24.6667C17.8168 24.6696 16.7318 24.4126 15.75 23.9167L11 25.5L12.5833 20.75C12.0874 19.7683 11.8305 18.6832 11.8333 17.5834C11.8338 16.2682 12.2005 14.9791 12.8923 13.8605C13.584 12.7419 14.5735 11.838 15.75 11.25C16.7318 10.7541 17.8168 10.4972 18.9167 10.5H19.3333C21.0703 10.5959 22.7109 11.329 23.9409 12.5591C25.171 13.7892 25.9042 15.4297 26 17.1667V17.5834Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
