import { makeStyles, useTheme } from 'assets/theme';
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button } from 'assets/components/button';
import { View } from 'react-native';
import { getText } from 'assets/localization/localization';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RefillStackParamList } from '../../refill/RefillNavigation';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Form } from 'assets/layout';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { useForm } from 'assets/form';
import { InsuranceTestID } from './InsuranceTestID';
import { Text } from 'assets/components/text';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import { InsuranceHandler, InsuranceProps } from './InsuranceProps';

const options = [
  {
    text: getText('yes'),
    value: 'yes',
  },
  {
    text: getText('no'),
    value: 'no',
  },
];
const InsuranceBottomSheetComponent: ForwardRefRenderFunction<
  InsuranceHandler,
  InsuranceProps
> = (
  {
    onChange = (value: string) => {},
    handleInsuranceSubmit = (value: string) => {},
  }: InsuranceProps,
  ref: ForwardedRef<InsuranceHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  const sheetRef = React.useRef<BottomSheetModal>(null);
  const methods = useForm({
    defaultValues: {
      changedInsurance: '',
    },
  });

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const handleOnPress = () => {
    handleInsuranceSubmit(methods.getValues().changedInsurance);
    handleBottomSheetDismiss();
    methods.setValue('changedInsurance', '');
  };
  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={'40%'}
      onDismiss={handleBottomSheetDismiss}
      headerContent={
        <View style={styles.bottomSheetViewTitle}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.bottomSheetViewTitle}>
              <Text style={styles.title}>{getText('insurance-card')}</Text>
            </View>
            <View style={{ justifyContent: 'flex-end' }}>
              <IconButton
                accessibilityLabel={getText('dismiss')}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        </View>
      }
      children={
        <View>
          <Form methods={methods}>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  label={getText('changed-insurance')}
                  rules={{
                    required: getText('field-required'),
                  }}
                  name="changedInsurance"
                  values={[
                    {
                      text: getText('yes'),
                      value: 'yes',
                    },
                    {
                      text: getText('no'),
                      value: 'no',
                    },
                  ]}
                />
              </Form.Column>
            </Form.Row>
            <Form.Actions>
              <View style={styles.buttonContainer}>
                <Button
                  hierarchy="primary"
                  onPress={methods.handleSubmit(handleOnPress)}
                  testID={InsuranceTestID.refillOrderDetailsSubmit}
                  logger={{ id: InsuranceTestID.refillOrderDetailsSubmit }}
                  style={styles.nextButton}
                >
                  {getText('next')}
                </Button>
              </View>
            </Form.Actions>
          </Form>
        </View>
      }
    />
  );
};

export interface PickupTodayBottomSheetProps
  extends NativeStackScreenProps<RefillStackParamList, 'refill-review'> {}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  nextButton: {
    alignItems: 'center',
    width: '100%',
    marginTop: theme.getSpacing(1),
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
}));

export const InsuranceBottomSheet = forwardRef<
  InsuranceHandler,
  InsuranceProps
>(InsuranceBottomSheetComponent);
