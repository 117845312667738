import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { PickupBottomSheet } from './PickupBottomSheet';
import { PickupHandler, PickupProps } from './PickupProps';
import { PickupModalComponent } from './PickupModalComponent';

const PickupComponent: ForwardRefRenderFunction<PickupHandler, PickupProps> = (
  { platform = Platform.OS, ...rest }: PickupProps,
  ref: ForwardedRef<PickupHandler>,
) => {
  const nativeRef = React.useRef<PickupHandler>(null);
  const webRef = React.useRef<PickupHandler>(null);

  const handleShow = () => {
    if (platform === 'web') webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (platform === 'web') webRef.current?.hide();
    else nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleOnChangePress = () => {
    handleShow();
  };

  return (
    <>
      {platform === 'web' ? (
        <PickupModalComponent {...rest} ref={webRef} />
      ) : (
        <PickupBottomSheet {...rest} ref={nativeRef} />
      )}
    </>
  );
};

export const Pickup = forwardRef<PickupHandler, PickupProps>(PickupComponent);
