import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { SendOptions } from './reset-password-actions';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import BaseRadioButton from 'assets/components/radio-button-group/BaseRadioButton';
import { makeStyles } from 'assets/theme';

export const ResetLinkRadioButton: FunctionComponent<
  PropsWithChildren<ResetLinkRadioButtonProps>
> = ({ selected, disabled, text, value, description, onPress }) => {
  const styles = useStyles();
  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress(value)}>
      <View style={styles.radioButtonContainer}>
        <BaseRadioButton selected={selected} disabled={disabled} />
      </View>
      <View style={{ flex: 1 }}>
        <Text style={styles.label}>{text}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.getSpacing(2),
  },
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
}));

interface ResetLinkRadioButtonProps {
  selected: boolean;
  disabled: boolean;
  text: string;
  value: SendOptions;
  description: string | undefined;
  onPress: (value: SendOptions) => void;
}
