import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { makeStyles } from 'assets/theme';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import { useForm } from 'assets/form';
import { getText } from 'assets/localization/localization';

import { View } from 'react-native';
import { Divider } from 'react-native-paper';

import {
  PrescriptionDto,
  RefillRemoveBehavior,
} from '@digitalpharmacist/prescription-service-client-axios';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { PrescriptionCard } from '../../../components/prescription-card';
import { useRefillMedicationsStore } from '../refill-store';
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from './UnableToSubmitProps';
import { UnableToSubmitTestID } from './UnableToSubmitTestID';

import { useAppStateStore } from '../../../store/app-store';
const UnableToSubmitBottomSheetComponent: ForwardRefRenderFunction<
  UnableToSubmitHandler,
  UnableToSubmitProps
> = (
  {
    onChange = (value: string) => undefined,
    disabled = false,
    handleUnableToSubmit = (value: string) => undefined,
  }: UnableToSubmitProps,
  ref: ForwardedRef<UnableToSubmitHandler>,
) => {
  const styles = useStyles();
  const {
    selectedMedications,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const { locationPrescriptionConfig } = useAppStateStore();
  const sheetRef = React.useRef<BottomSheetModal>(null);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const removeMedicationNotSupportedToRefill = () => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedMedications: selectedMedications?.filter((med) =>
        isRemovable(med),
      ),
    });
  };

  const removeUnableToSubmitMedications = (item: PrescriptionDto) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedMedications: selectedMedications?.filter(
        (x) => x.rx_number !== item.rx_number,
      ),
      unableToSubmitMedications: unableToSubmitMedications?.filter(
        (med) => med.rx_number !== item.rx_number,
      ),
    });
  };

  const isRemovable = (item: PrescriptionDto) => {
    const rx_config = locationPrescriptionConfig.refill_configs.find(
      (config) => config.rx_status === item.rx_status,
    );

    if (!rx_config) return true;

    return rx_config.remove_behavior === RefillRemoveBehavior.AllowRemove;
  };

  const handleBottomSheetDismiss = () => {
    removeMedicationNotSupportedToRefill();
    sheetRef.current?.dismiss();
  };

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      title={'Notice'}
      height={'90%'}
      onDismiss={handleBottomSheetDismiss}
      children={
        <View>
          <Text style={styles.textTitle}>
            {getText('unable-to-process-items')}
          </Text>
          <Divider />
          <Text style={styles.textTitleDescription}>
            {getText('we-will-contact-prescriber')}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText('please-review-the-messages-below')}
          </Text>
          {unableToSubmitMedications?.map((medication, index) => (
            <PrescriptionCard
              prescription={medication}
              key={index}
              removable={isRemovable(medication)}
              showDetailsIcon={false}
              onRemove={() => removeUnableToSubmitMedications(medication)}
            />
          ))}
          <View>
            <Button
              hierarchy="primary"
              onPress={() => {
                handleUnableToSubmit('continue');
                handleBottomSheetDismiss();
              }}
              testID={UnableToSubmitTestID.submitButton}
              logger={{ id: UnableToSubmitTestID.submitButton }}
            >
              {getText('continue')}
            </Button>
          </View>
        </View>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: '500',
    fontSize: 14,
    marginTop: theme.getSpacing(3),
  },
}));

export const UnableToSubmitBottomSheet = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(UnableToSubmitBottomSheetComponent);
