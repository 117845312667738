import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const NotificationTypeIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      color={color}
      testID={testID}
    >
      <Circle cx={12} cy={12} r={10} fill={color} />
    </Svg>
  );
};
