import { RecordUnderCareDto } from '@digitalpharmacist/patient-service-client-axios';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Platform, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Text } from 'assets/components/text';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { makeStyles } from 'assets/theme';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Modal } from 'assets/components/modal';

export const AlertWebComponent: FunctionComponent<
  PropsWithChildren<{
    onButtonPress: () => void;
    showModal: boolean;
    handleCancelOnPress: (val: boolean) => void;
    title?: string;
    description?: string;
  }>
> = ({ onButtonPress, showModal, handleCancelOnPress, title, description }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <>
      <Modal
        size="sm"
        title={title}
        show={showModal}
        cancelButtonProps={{
          onPress: () => handleCancelOnPress(false),
          logger: { id: 'alert-modal-cancel-button' },
        }}
        okButtonProps={{
          text: getText('delete'),
          hierarchy: 'destructive-secondary',
          onPress: () => onButtonPress(),
          logger: { id: 'alert-modal-button' },
        }}
      >
        <View style={{ alignContent: 'center' }}>
          <Text style={styles.modalText}>{description}</Text>
        </View>
      </Modal>
    </>
  );
};

export const AlertMobileComponent: FunctionComponent<
  PropsWithChildren<{
    bottomSheetRef: React.RefObject<BottomSheetModal>;
    onButtonPress: () => void;
    handleCancelOnPress: (val: boolean) => void;
    title?: string;
    description?: string;
  }>
> = ({
  bottomSheetRef,
  onButtonPress,
  handleCancelOnPress,
  title,
  description,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <>
      <BottomSheet
        bottomSheetRef={bottomSheetRef}
        height={'35%'}
        footerContent={
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="secondary-gray"
              logger={{
                id: 'cancel-bottom-sheet-button',
              }}
              style={{ marginEnd: theme.getSpacing(2), width: '50%' }}
              onPress={() => handleCancelOnPress(false)}
            >
              {getText('cancel')}
            </Button>
            <Button
              hierarchy="destructive-secondary"
              logger={{
                id: 'delete-bottom-sheet-button',
              }}
              style={{ width: '50%' }}
              onPress={() => onButtonPress()}
            >
              {getText('delete')}
            </Button>
          </View>
        }
        onDismiss={() => handleCancelOnPress(false)}
        headerContent={
          <View style={styles.footerContent}>
            <View style={styles.headerIconStyle}>
              <IconButton
                icon={CloseIcon}
                onPress={() => handleCancelOnPress(false)}
                size={20}
                color={theme.palette.gray[500]}
                logger={{
                  id: 'close-button',
                }}
              />
            </View>
            <View style={styles.headerText}>
              <Text style={styles.modalHeader}>{title}</Text>
            </View>
          </View>
        }
      >
        <View style={styles.mainContainer}>
          <Text style={styles.modalText}>{description}</Text>
        </View>
      </BottomSheet>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: { alignSelf: 'center', flexDirection: 'column' },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  headerIconStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerContent: { justifyContent: 'center' },
  headerText: { alignSelf: 'center', marginTop: theme.getSpacing(1) },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
    padding: theme.getSpacing(1),
    alignSelf: 'center',
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
}));
