import { FunctionComponent, PropsWithChildren } from 'react';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { getText } from 'assets/localization/localization';
import { IconButton } from 'assets/components/icon-button';
import { BellIcon, CloseIcon } from 'assets/icons';
import { NotificationList } from 'assets/components/notifications';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { logError } from 'assets/logging/logger';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';

export const NotificationsListNative: FunctionComponent<
  PropsWithChildren<NotificationsListNativeProps>
> = ({ bottomSheetRef, notifications }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { refreshNotifications } = useAppStateStore();

  const handleOpenBottomSheet = async () => {
    try {
      await refreshNotifications();
    } catch {
      logError(new Error('Failed to get notifications'));
    }
    bottomSheetRef.current?.present();
  };

  const handleBottomSheetDismiss = () => {
    bottomSheetRef.current?.dismiss();
  };

  return (
    <>
      <IconButton
        icon={BellIcon}
        onPress={handleOpenBottomSheet}
        color={theme.palette.white}
        logger={{ id: 'notifications-list-web-icon-button' }}
      />
      <BottomSheet
        hideHandle={true}
        bottomSheetRef={bottomSheetRef}
        height={'95%'}
        contentContainerStyle={styles.contentContainerStyle}
        headerContent={
          <View style={styles.headerContainer}>
            <View style={styles.bottomSheetViewTitleContainer}>
              <Text style={styles.bottomSheetViewTitle}>
                {getText('notifications')}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <IconButton
                logger={{ id: 'notifications-list-close-icon-button' }}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        }
        footerContent={
          <View style={styles.footerContainer}>
            <Button
              hierarchy="secondary"
              onPress={handleBottomSheetDismiss}
              logger={{ id: 'notifications-list-close-button' }}
            >
              {getText('close')}
            </Button>
          </View>
        }
      >
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} />
        ) : (
          <NotificationsEmptyState />
        )}
      </BottomSheet>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainerStyle: {
    marginHorizontal: getSpacing(2),
    paddingTop: getSpacing(2),
    paddingBottom: getSpacing(4),
  },
  bottomSheetViewTitle: {
    ...theme.fonts.medium,
    fontSize: 18,
  },
  bottomSheetViewTitleContainer: {
    marginLeft: theme.getSpacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  footerContainer: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  headerContainer: {
    flexDirection: 'row',
    margin: theme.getSpacing(1),
  },
  iconContainer: {
    justifyContent: 'flex-end',
  },
}));

export interface NotificationsListNativeProps {
  bottomSheetRef: React.RefObject<BottomSheetModal>;
  notifications: UserNotificationDto[];
}
