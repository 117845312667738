import { NormalTimeRangeDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';

export const isNumber = (str: string): boolean => {
  return /^\d+$/.test(str);
};

export const isZipCode = (str?: string): boolean => {
  return !!str && isNumber(str) && str.length === 5;
};

export const currentDayOpenHours = (hoursAsStr: NormalTimeRangeDto[]) => {
  const day = new Date().getDay();
  const todayOpenHours = hoursAsStr.find((openHour) => {
    return openHour.day === day;
  });
  if (todayOpenHours) {
    return getHoursAsStr(todayOpenHours);
  }
};

export const getHoursAsStr = (hours: NormalTimeRangeDto) => {
  const startHour = hours.start_hour;
  const startMinute = hours.start_minute === 0 ? '00' : hours.start_minute;
  const endHour = hours.end_hour;
  const endMinute = hours.end_minute === 0 ? '00' : hours.end_minute;

  const startHourStr =
    startHour < 12
      ? `${startHour}:${startMinute}AM`
      : `${startHour - 12}:${startMinute}PM`;
  const endHourStr =
    endHour < 12
      ? `${endHour}:${endMinute}AM`
      : `${endHour - 12}:${endMinute}PM`;
  return `${startHourStr} - ${endHourStr}`;
};

export const getDayAsStr = (hours: NormalTimeRangeDto) => {
  switch (hours.day) {
    case 1:
      return getText('monday');
    case 2:
      return getText('tuesday');
    case 3:
      return getText('wednesday');
    case 4:
      return getText('thursday');
    case 5:
      return getText('friday');
    case 6:
      return getText('saturday');
    case 7:
      return getText('sunday');
    default:
      return '';
  }
};
