import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'react-native-paper';
import { Modal } from 'assets/components/modal';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { PatientUnderCareForm, ResourceItem } from '../../patient-actions';
import {
  findAllergiesById,
  findConditionsById,
} from '../../../../../components/medical-info/medical-info-actions';
import { UseFormReturn } from 'react-hook-form';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
} from '../../patient-store';
import { EditPersonalInformation } from '../manage-people-under-care-details/EditPersonalInformation';
import { EditMedicalInformation } from '../manage-people-under-care-details/EditMedicalInformation';
import { PersonalInformationComponent } from '../account-information/PersonalInformationComponent';
import { MedicalInformation } from '../account-information/MedicalInformation';

export const PersonalInfoModal: FunctionComponent<
  PropsWithChildren<{
    setVisibility: (val: boolean) => void;
    handleRemovePatientUnderCare: (record?: RecordUnderCareDto) => void;
    record?: RecordUnderCareDto;
    showModal: boolean;
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    viewMode: boolean;
    relationshipProps?: Array<{ label: string; value: string }>;
    handleSave: (updatePR: UpdatePatientRecordDto) => void;
    handleSubmit: () => void;
    onEditPress: (
      editDetail: PatientUnderCareEditDetailsEnum,
      methods: UseFormReturn<PatientUnderCareForm>,
    ) => void;
    whichDetailsToEdit: (
      editType: PatientUnderCareEditDetailsEnum.PersonalInfo,
    ) => void;
    editDetails: PatientUnderCareEditDetailsEnum;
    form: UseFormReturn<PatientUnderCareForm>;
    title: string;
  }>
> = ({
  showModal,
  setVisibility,
  handleRemovePatientUnderCare,
  record,
  patientRecord,
  changeViewMode,
  viewMode,
  relationshipProps,
  handleSave,
  handleSubmit,
  onEditPress,
  whichDetailsToEdit,
  editDetails,
  form,
  title,
}) => {
  return (
    <Modal
      show={showModal}
      title={title}
      okButtonProps={{
        text: record ? getText('remove-patient') : getText('save'),
        hierarchy: record ? 'destructive-secondary' : 'primary',
        onPress: () =>
          record ? handleRemovePatientUnderCare(record) : handleSubmit(),
        logger: { id: 'remove-patient-access-modal-send-button' },
      }}
      cancelButtonProps={{
        text: getText('cancel'),
        hierarchy: 'secondary-gray',
        onPress: () => setVisibility(false),
        logger: { id: 'cancel-patient-access-modal-send-button' },
      }}
      isScrollable={true}
    >
      <View>
        <View>
          {!viewMode ? (
            <View>
              <PersonalInformationComponent
                patientRecord={patientRecord}
                onEditPress={() => {
                  onEditPress(
                    PatientUnderCareEditDetailsEnum.PersonalInfo,
                    form,
                  );
                }}
                record={record}
              ></PersonalInformationComponent>
              <MedicalInformation
                patientRecord={patientRecord}
                onEditPress={() => {
                  onEditPress(
                    PatientUnderCareEditDetailsEnum.MedicalInfo,
                    form,
                  );
                }}
              ></MedicalInformation>
            </View>
          ) : PatientUnderCareEditDetailsEnum.PersonalInfo === editDetails ? (
            <EditPersonalInformation
              changeViewMode={changeViewMode}
              relationshipProps={relationshipProps}
              handleSave={handleSave}
              patientRecord={patientRecord}
              record={record}
              form={form}
            ></EditPersonalInformation>
          ) : PatientUnderCareEditDetailsEnum.MedicalInfo === editDetails ? (
            <EditMedicalInformation
              patientRecord={patientRecord}
              changeViewMode={changeViewMode}
              handleSave={handleSave}
              record={record}
              form={form}
            ></EditMedicalInformation>
          ) : (
            // TODO Insurance and Id info will come here - out of scope for BLUES-2677
            <></>
          )}
        </View>
      </View>
    </Modal>
  );
};
