import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { View } from 'react-native';
import {
  PrescriptionDto,
  RefillRemoveBehavior,
} from '@digitalpharmacist/prescription-service-client-axios';
import { PrescriptionCard } from '../../../components/prescription-card';
import { makeStyles } from 'assets/theme';
import { useRefillMedicationsStore } from '../refill-store';
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from './UnableToSubmitProps';
import { UnableToSubmitTestID } from './UnableToSubmitTestID';
import { useAppStateStore } from '../../../store/app-store';

export const UnableToSubmitModalComponent = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(
  (
    {
      onChange = (value: string) => {},
      disabled = false,
      handleUnableToSubmit = (value: string) => {},
    }: UnableToSubmitProps,
    ref: ForwardedRef<UnableToSubmitHandler>,
  ) => {
    const styles = useStyles();
    const {
      selectedMedications,
      unableToSubmitMedications,
      updateMedicationsData,
    } = useRefillMedicationsStore();
    const { locationPrescriptionConfig } = useAppStateStore();
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const removeMedicationNotSupportedToRefill = () => {
      if (!updateMedicationsData) return;

      updateMedicationsData({
        selectedMedications: selectedMedications?.filter((med) =>
          isRemovable(med),
        ),
      });
    };

    const removeUnableToSubmitMedications = (item: PrescriptionDto) => {
      if (!updateMedicationsData) return;

      updateMedicationsData({
        selectedMedications: selectedMedications?.filter(
          (x) => x.rx_number !== item.rx_number,
        ),
        unableToSubmitMedications: unableToSubmitMedications?.filter(
          (med) => med.rx_number !== item.rx_number,
        ),
      });
    };

    const isRemovable = (item: PrescriptionDto) => {
      const rx_config = locationPrescriptionConfig.refill_configs.find(
        (config) => config.rx_status === item.rx_status,
      );

      if (!rx_config) return true;

      return rx_config.remove_behavior === RefillRemoveBehavior.AllowRemove;
    };

    const handleOnSave = () => {
      removeMedicationNotSupportedToRefill();
      handleUnableToSubmit('continue');
      handleDismissModal();
    };

    return (
      <Modal
        size="sm"
        title={getText('notice')}
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          testID: UnableToSubmitTestID.cancelModalButton,
          logger: { id: UnableToSubmitTestID.cancelModalButton },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          testID: UnableToSubmitTestID.okModalButton,
          logger: { id: UnableToSubmitTestID.okModalButton },
        }}
      >
        <View>
          <Text style={styles.textTitle}>
            {getText('unable-to-process-items')}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText('we-will-contact-prescriber')}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText('please-review-the-messages-below')}
          </Text>
          {unableToSubmitMedications?.map((medication, index) => (
            <PrescriptionCard
              prescription={medication}
              key={index}
              removable={isRemovable(medication)}
              showDetailsIcon={false}
              onRemove={() => removeUnableToSubmitMedications(medication)}
              patient={undefined}
            />
          ))}
        </View>
      </Modal>
    );
  },
);
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: '500',
    fontSize: 14,
    marginTop: theme.getSpacing(3),
  },
}));
