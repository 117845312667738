import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AccountStackParamList } from '../AccountNavigation';
import { usePatientIntakeState } from './patient-store';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import {
  View,
  TouchableOpacity,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { ImageIcon, TrashWithStripesIcon } from 'assets/icons';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import patientService from '../../../api/patient-service';
import FileStorageService from '../../../api/file-storage-service';
import { useAppStateStore } from '../../../store/app-store';
import { useUserState } from '../../../store/user-store';
import {
  CardType,
  DeleteCardDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  PhotoButton,
  PhotoInfo,
} from './components/insurance-card/PhotoButton';
import { cardTypeConfig } from './EditPatientCard';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  AlertWebComponent,
  AlertMobileComponent,
} from './components/AlertDialog';
import { Button } from 'assets/components/button';
import { StackNavigationProp } from '@react-navigation/stack';
import { RefillStackParamList } from '../../refill/RefillNavigation';
import { useRefillMedicationsStore } from '../../refill/refill-store';

export const InsuranceAndIDCards: FunctionComponent<
  PropsWithChildren<PatientInfoProps>
> = ({ navigation }) => {
  const theme = useTheme();

  const nav = useNavigation<StackNavigationProp<RefillStackParamList>>();
  const { data, updateData } = usePatientIntakeState();
  const [showModal, setShowModal] = useState(false);
  const bottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { status, error, isRefill } = usePatientIntakeState();
  const { selectedPatientRecord, updateMedicationsData } =
    useRefillMedicationsStore();
  const {} = useRefillMedicationsStore();
  const styles = useStyles();
  const { pharmacyId } = useAppStateStore();
  let patientId = useUserState.getState().user?.patientRecordId;
  const [selectedCard, setSelectedCard] = useState<CardType>(
    CardType.InsurancePrimary,
  );
  const { width, height } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);
  const buttonHeight = height * 0.25;
  if (!patientId) {
    throw Error('No patient ID');
  }
  if (selectedPatientRecord) {
    patientId = selectedPatientRecord.id;
  }

  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();

  const navigateToAddCard = (cardType: CardType) => {
    if (!isRefill && updateMedicationsData) {
      updateMedicationsData({
        selectedPatientRecord: undefined,
      });
    }
    navigation.navigate('edit-patient-card', {
      cardType: cardType,
      nextNavigation: isRefill ? 'change-insurance' : undefined,
    });
  };

  const onDeleteButtonPress = async (
    cardType: CardType,
    confirmation: boolean,
  ) => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    if (confirmation) {
      const deleteCard: DeleteCardDto = {
        type: cardType,
      };
      const patientRecord = await patientService.patientRecordDeleteCard(
        patientId,
        deleteCard,
      );
      setPatientRecord(patientRecord);
      handleShowModal(false);
    } else {
      handleShowModal(false);
    }
  };

  const showModalForSelectedCard = (show: boolean, cardType: CardType) => {
    setSelectedCard(cardType);
    handleShowModal(show);
  };

  const handleShowModal = (show: boolean) => {
    setShowModal(show);
    if (!show) {
      bottomSheetRef.current?.dismiss();
    } else {
      bottomSheetRef.current?.present();
    }
  };

  const makePrimaryInsuranceCard = async () => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    const patientRecord =
      await patientService.patientRecordSwitchInsurance(patientId);
    setPatientRecord(patientRecord);
  };

  const loadPatientRecord = async () => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    try {
      usePatientIntakeState.setState({
        error: undefined,
        status: 'loading',
      });
      const patientRecord = await patientService.findPatientRecord(patientId);
      setPatientRecord(patientRecord);

      usePatientIntakeState.setState({
        error: undefined,
        status: 'idle',
      });
    } catch (error) {
      usePatientIntakeState.setState({
        error: { message: getText('error-loading-patient-record') },
        status: 'error',
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      void loadPatientRecord();
    }, [patientId]),
  );
  const handleInsuranceChangeNext = () => {
    if (!updateData) return;
    updateData({
      isRefill: false,
      data: data,
      status: 'success',
    });
    nav.navigate('refill-review');
  };
  const PatientCard = ({
    patientRecord,
    cardType,
  }: {
    patientRecord: PatientRecordDto;
    cardType: CardType;
  }) => {
    const {
      viewTitleCopy,
      category,
      frontFilenameProperty,
      backFilenameProperty,
    } = cardTypeConfig[cardType];

    const [frontPhoto, setFrontPhoto] = useState<PhotoInfo>();
    const [backPhoto, setBackPhoto] = useState<PhotoInfo>();

    const loadImages = async () => {
      try {
        const frontFilename = patientRecord[frontFilenameProperty];

        if (frontFilename) {
          const responseReadUrl = await FileStorageService.readUrl(
            category,
            frontFilename,
            pharmacyId,
          );
          setFrontPhoto({ uri: responseReadUrl.url });
        }

        if (backFilenameProperty) {
          const backFilename = patientRecord[backFilenameProperty];

          if (backFilename) {
            const responseReadUrl = await FileStorageService.readUrl(
              category,
              backFilename,
              pharmacyId,
            );
            setBackPhoto({ uri: responseReadUrl.url });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      void loadImages();
    }, []);

    return (
      <View style={{ marginTop: theme.getSpacing(3) }}>
        <View style={styles.imageOptions}>
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 14,
            }}
          >
            {getText(viewTitleCopy)}
          </Text>
          {frontPhoto && (
            <View style={styles.inline}>
              {cardType === CardType.InsuranceSecondary && (
                <TouchableOpacity onPress={() => makePrimaryInsuranceCard()}>
                  <Text style={styles.replaceText}>
                    {getText('make-primary')}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity onPress={() => navigateToAddCard(cardType)}>
                <Text style={styles.replaceText}>{getText('replace')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => showModalForSelectedCard(true, cardType)}
              >
                <TrashWithStripesIcon
                  size={20}
                  color={theme.palette.gray[500]}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
        <PhotoButton
          uri={frontPhoto?.uri}
          width={buttonWidth}
          height={buttonHeight}
          onPress={() => {
            if (!frontPhoto) {
              navigateToAddCard(cardType);
            }
          }}
        >
          <View
            style={{
              padding: theme.getSpacing(1),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View>
              <ImageIcon size={32} color={theme.palette.gray[500]} />
            </View>
            <View style={styles.upload}>
              <FileUploadIcon color={theme.palette.gray[500]} size={24} />
              <Text style={styles.uploadText}>{getText('upload')}</Text>
            </View>
          </View>
        </PhotoButton>
        {backPhoto && (
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <PhotoButton
              uri={backPhoto.uri}
              width={buttonWidth}
              height={buttonHeight}
            ></PhotoButton>
          </View>
        )}
      </View>
    );
  };

  return status === 'loading' || !patientRecord ? (
    <LoadingIndicator />
  ) : (
    <ScreenContainer>
      <View>
        <PatientCard
          patientRecord={patientRecord}
          cardType={CardType.InsurancePrimary}
        />
        {patientRecord.insurance_card_primary_front_url && (
          <PatientCard
            patientRecord={patientRecord}
            cardType={CardType.InsuranceSecondary}
          />
        )}
        {!isRefill ? (
          <PatientCard
            patientRecord={patientRecord}
            cardType={CardType.PhotoId}
          />
        ) : (
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              testID={'next-button-id'}
              logger={{ id: 'next-button-id' }}
              onPress={handleInsuranceChangeNext}
              disabled={
                !patientRecord.insurance_card_primary_front_url &&
                !patientRecord.insurance_card_primary_back_url
              }
              style={styles.buttonContainer}
            >
              {getText('next')}
            </Button>
          </View>
        )}
      </View>
      {Platform.OS === 'web' ? (
        <AlertWebComponent
          onButtonPress={() => onDeleteButtonPress(selectedCard, true)}
          showModal={showModal}
          handleCancelOnPress={() => onDeleteButtonPress(selectedCard, false)}
          description={getText('delete-image')}
        ></AlertWebComponent>
      ) : (
        <AlertMobileComponent
          onButtonPress={() => onDeleteButtonPress(selectedCard, true)}
          bottomSheetRef={bottomSheetRef}
          handleCancelOnPress={() => onDeleteButtonPress(selectedCard, false)}
          description={getText('delete-image')}
        ></AlertMobileComponent>
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  replaceText: {
    color: theme.palette.primary[600],
    fontSize: 14,
    fontWeight: '500',
    marginRight: 12,
  },
  buttonContainer: {
    marginTop: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

type PatientInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  'insurance-and-id-card'
>;
