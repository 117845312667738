import React, {
  ForwardedRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import {
  CancelAppointmentHandler,
  CancelAppointmentProps,
} from './CancelAppointment';
import { useAppointmentDetailsState } from '../appointment-details-store';

export const CancelAppointmentModal: ForwardRefRenderFunction<
  CancelAppointmentHandler,
  CancelAppointmentModalProps
> = ({ onConfirm }, ref: ForwardedRef<CancelAppointmentHandler>) => {
  const [showModal, setShowModal] = useState(false);
  const { cancelAppointmentStatus } = useAppointmentDetailsState();

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDismissModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleDismissModal,
  }));

  return (
    <Modal
      show={showModal}
      title={getText('cancel-appointment')}
      okButtonProps={{
        onPress: handleConfirm,
        logger: { id: 'cancel-appointment-ok-button-modal' },
        text: getText('cancel-appointment'),
        loading: cancelAppointmentStatus === 'loading',
      }}
      cancelButtonProps={{
        onPress: handleDismissModal,
        logger: { id: 'cancel-appointment-cancel-button-modal' },
        text: getText('keep'),
      }}
      dismissButtonProps={{
        onPress: handleDismissModal,
        logger: { id: 'cancel-appointment-dismiss-button-modal' },
      }}
      isScrollable={true}
    >
      <Text>{getText('cancel-appointment-confirmation')}</Text>
    </Modal>
  );
};

export interface CancelAppointmentModalProps extends CancelAppointmentProps {
  onConfirm: () => void;
}
