import { getText } from 'assets/localization/localization';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { useRegisterState } from './register-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import moment from 'moment';
import {
  PatientUserDto,
  UpdateUserRequest,
  UserPatientPasswordSetPost200Response,
  UserRegisterDto,
} from '@digitalpharmacist/users-service-client-axios';
import UsersServiceInstance from '../../api/users-service';
import PharmacyService from '../../api/pharmacy-service';
import { ampli } from '../../common/ampliPatient';
import { logError } from 'assets/logging/logger';
import { initPushNotificationToken } from '../../common/push-notifications';

export const register = async (
  values: RegisterEmailForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useRegisterState.setState({ error: undefined, status: 'loading' });
  const userRegistration: UserRegisterDto = {
    email: values.email,
    pharmacy_id: pharmacyId,
    password: values.password,
    user_accepted_privacy_policy: true,
    user_accepted_terms_of_service: true,
  };
  try {
    const user: PatientUserDto =
      await UsersServiceInstance.registerPatient(userRegistration);
    useRegisterState.setState({ status: 'success' });
    useUserState.setState({ user: user });
    await AsyncStorage.setItem(StorageKeys.Password, values.password);
  } catch (e) {
    useRegisterState.setState({
      error: { message: getText('unable-to-complete-registration') },
      status: 'error',
    });
  }
};

export const registerDetails = async (
  values: RegisterDetailsForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  useRegisterState.setState({ error: undefined, status: 'loading' });

  try {
    const userId = useUserState.getState().user?.id;
    if (!userId) {
      throw new Error(getText('unable-to-update-account'));
    }
    const formattedDOB = moment(values.dateOfBirth, 'MM-DD-YYYY').format(
      'YYYY-MM-DD',
    );
    const userInfo: UpdateUserRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: formattedDOB,
      phoneNumber: values.phoneNumberMobile,
    };
    const user: PatientUserDto = await UsersServiceInstance.updatePatient(
      userId,
      userInfo,
    );
    useUserState.setState({
      user: user,
    });
    useRegisterState.setState({ status: 'success' });
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Verification code sent',
    });
    navigation.navigate('phone-verification');
  } catch (e) {
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Verification code send failed',
    });
    useRegisterState.setState({
      error: { message: getText('unable-to-update-account') },
      status: 'error',
    });
  }
};

export const updateLocationDetails = async (
  locationId: string,
): Promise<void> => {
  try {
    const pharmacyDepartments =
      await PharmacyService.findLocationHours(locationId);
    const pharmacyDepartment = pharmacyDepartments.find(
      (department) => department.department_name === 'Pharmacy',
    );
    useAppStateStore.setState({
      /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
      locationStoreHours: pharmacyDepartment!.time_ranges,
      locationPharmacyHours: pharmacyDepartment!.time_ranges,
    });
  } catch (e) {
    logError(e as Error);
  }

  try {
    const locationLanguages: string[] =
      await PharmacyService.findLocationLanguage(locationId);
    useAppStateStore.setState({ locationLanguages: locationLanguages });
  } catch (e) {
    logError(e as Error);
  }

  // commented because backend throws 404 for services and causes issue on opening the location details modal
  // const locationServices: string[] = [];
  // try {
  //   const response = await PharmacyService.findLocationServices(locationId);
  //   locationServices.push(...response);
  // } catch (e) {
  //   console.log(e);
  // }
  // useAppStateStore.setState({ locationServices });
};

export const registerStore = async (
  preferredPharmacyLocationId: string,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const userId = useUserState.getState().user?.id;
  try {
    if (!userId) {
      throw new Error(getText('unable-to-complete-registration'));
    }
    //TODO change this later after the backend bug was resolved dateOfBirth should not be a part of this.
    const dateOfBirth = useUserState.getState().user?.dateOfBirth;
    const userInfo: UpdateUserRequest = {
      preferredPharmacyLocationId: preferredPharmacyLocationId,
      dateOfBirth: dateOfBirth,
    };
    const user: PatientUserDto = await UsersServiceInstance.updatePatient(
      userId,
      userInfo,
    );
    user.preferredPharmacyLocationId = preferredPharmacyLocationId;
    useUserState.setState({ user: user });
    useRegisterState.setState({ status: 'success' });
    ampli.onboardingCompleted({
      onBoardingCompletedTime: new Date().toISOString(),
      onboardingCompleteStatus: 'Completed',
    });
    initPushNotificationToken();
  } catch (e) {
    ampli.onboardingCompleted({
      onBoardingCompletedTime: new Date().toISOString(),
      onboardingCompleteStatus:
        'Failed to choose a location and complete onboarding',
    });
    useRegisterState.setState({
      error: { message: getText('unable-to-complete-registration') },
      status: 'error',
    });
  }
};

export const createPassword = async (
  values: CreateAccountForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  useRegisterState.setState({ error: undefined, status: 'loading' });
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const dto = {
      email: values.email,
      password: values.password,
      cognito_username: values.cognito_username,
      pharmacy_id: pharmacyId,
      temp_password: values.temp_password,
      user_accepted_privacy_policy: true,
      user_accepted_terms_of_service: true,
    };

    const userResponse: UserPatientPasswordSetPost200Response =
      await UsersServiceInstance.passwordSet(dto);
    if (!userResponse) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    if (!userResponse.patient_user_dto) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    const user: PatientUserDto = userResponse.patient_user_dto;
    if (!user.id) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    await AsyncStorage.setItem(StorageKeys.UserId, user.id);
    if (!userResponse.accessToken) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    await AsyncStorage.setItem(
      StorageKeys.AccessToken,
      userResponse.accessToken,
    );
    await AsyncStorage.setItem(
      StorageKeys.RefreshToken,
      userResponse.refreshToken,
    );
    useUserState.setState({
      user: userResponse.patient_user_dto,
    });
    ampli.accountCreated({
      accountCreatedTime: new Date().toISOString(),
      accountType: 'Patient',
    });
    useRegisterState.setState({ status: 'success' });
    useUserState.setState({ user: user });
    navigation.navigate('register-details');
  } catch (e) {
    ampli.accountCreated({
      accountCreatedTime: new Date().toISOString(),
      accountType: 'Patient account creation failed',
    });
    useRegisterState.setState({
      error: { message: getText('unable-to-complete-registration') },
      status: 'error',
    });
  }
};

export interface UpdateUserForm {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumberMobile: string;
}

export interface VerificationForm {
  email: string;
  confirmCode: string;
}

export interface RegisterEmailForm {
  email: string;
  password: string;
  passwordConfirm: string;
  pharmacyId: string;
}

export interface CreateAccountForm {
  email: string;
  password: string;
  passwordConfirm: string;
  cognito_username: string;
  temp_password: string;
}

export interface RegisterDetailsForm {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumberMobile?: string;
}

export interface AddMeds {
  option: 'skip' | 'request-to-transfer' | 'prescriptions-at-file';
}
