import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const GoogleIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M18.75 10.194c0-.72-.059-1.244-.188-1.788H10.18v3.247H15.1c-.1.807-.635 2.022-1.825 2.839l-.017.108 2.65 2.013.184.018c1.687-1.527 2.659-3.773 2.659-6.437Z"
        fill="#4285F4"
      />
      <Path
        d="M10.179 18.75c2.41 0 4.434-.778 5.912-2.12l-2.817-2.138c-.754.515-1.766.875-3.095.875a5.365 5.365 0 0 1-5.08-3.637l-.104.01-2.756 2.09-.036.098c1.468 2.858 4.484 4.822 7.976 4.822Z"
        fill="#34A853"
      />
      <Path
        d="M5.1 11.73A5.29 5.29 0 0 1 4.802 10c0-.603.11-1.186.288-1.73l-.005-.117-2.79-2.123-.092.042A8.616 8.616 0 0 0 1.251 10c0 1.41.347 2.742.952 3.928L5.1 11.73Z"
        fill="#FBBC05"
      />
      <Path
        d="M10.179 4.633c1.676 0 2.807.71 3.452 1.303l2.52-2.411C14.604 2.115 12.59 1.25 10.18 1.25c-3.492 0-6.508 1.964-7.976 4.822L5.09 8.27a5.387 5.387 0 0 1 5.089-3.636Z"
        fill="#EB4335"
      />
    </Svg>
  );
};
