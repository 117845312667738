import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { RegisterTestIDs } from './RegisterTestIDs';
import { useRegisterState } from './register-store';
import { registerDetails, RegisterDetailsForm } from './register-actions';
import { getText } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import { useNavigation } from '@react-navigation/native';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View,
} from 'react-native';
import * as validate from '@digitalpharmacist/validation-dp';
import {
  DateTimeFormat,
  formatDate,
  formatISODateAsString,
  formatPhoneNumberAsString,
} from '../../common/form-utils';
import { useUserState } from '../../store/user-store';
import {
  PharmacyPoliciesDto,
  PolicyType,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Modal } from 'assets/components/modal';
import PharmacyService from '../../api/pharmacy-service';

export const RegisterDetails: FunctionComponent<
  PropsWithChildren<RegisterDetailsProps>
> = (props) => {
  const user = useUserState().user;
  const theme = useTheme();
  const methods = useForm<RegisterDetailsForm>({
    defaultValues: {
      dateOfBirth: user?.dateOfBirth
        ? formatISODateAsString(user.dateOfBirth, DateTimeFormat.USDateFormat)
        : '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumberMobile: user?.phoneNumber
        ? formatPhoneNumberAsString(user.phoneNumber)
        : '',
    },
  });
  const [keyboardContainerHeight, setKeyboardContainerHeight] = useState<
    number | string
  >();

  const { status, error } = useRegisterState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const handleSubmit = async () => {
    registerDetails(methods.getValues(), navigation);
  };

  const minKeyboardContainerHeight = 610;
  const windowHeight = Dimensions.get('window').height;
  const kbContainerHeight = windowHeight * 0.8;

  const handleOnFocusEvent = () => {
    setKeyboardContainerHeight((prev) =>
      kbContainerHeight < minKeyboardContainerHeight
        ? minKeyboardContainerHeight
        : kbContainerHeight,
    );
  };

  const handleOnBlurEvent = () => {
    setKeyboardContainerHeight((prev) => undefined);
  };

  const [policies, setPolicies] = useState<PharmacyPoliciesDto>({});
  const [policyModalSettings, setPolicyModalSettings] = useState({
    title: '',
    type: '',
    ref: React.useRef<BottomSheetModal>(null),
    state: false,
  });
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);

  const styles = useStyles();

  useEffect(() => {
    if (pharmacyId) {
      void (async () => {
        const policiesResponse: PharmacyPoliciesDto =
          await PharmacyService.findPoliciesForPharmacy(pharmacyId);
        setPolicies(policiesResponse);
      })();
    }
  }, [pharmacyId]);

  const closePolicyModal = () => {
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: '',
      state: false,
      title: '',
    }));
    if (Platform.OS !== 'web') {
      policyModalSettings.ref.current?.dismiss();
    }
  };

  const openPolicyModal = (policyType: PolicyType) => {
    const policyTitle =
      policyType === PolicyType.TermsOfService
        ? getText('terms-of-service')
        : getText('privacy-policy');
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: policyType,
      state: true,
      title: policyTitle,
    }));
    if (Platform.OS !== 'web') {
      policyModalSettings.ref.current?.present();
    }
  };

  const getPoliciesContent = (): ReactNode => {
    return (
      <>
        <>{getText('continuation-disclaimer-register') + '\n'} </>
        <Text
          style={styles.link}
          onPress={() => openPolicyModal(PolicyType.TermsOfService)}
        >
          {getText('terms-of-service')}&nbsp;
        </Text>
        <>{getText('and')} </>
        <Text
          style={styles.link}
          onPress={() => openPolicyModal(PolicyType.PrivacyPolicy)}
        >
          {getText('privacy-policy')}
        </Text>
        .
      </>
    );
  };

  function getModalContent() {
    if (policyModalSettings.type in policies) {
      return policies[policyModalSettings.type as PolicyType]?.content;
    } else {
      return `${getText(
        'contact-pharmacy-for-policy-info',
      )} ${policyModalSettings.title.toLocaleLowerCase()}`;
    }
  }

  return (
    <ScreenContainer>
      <ScrollView style={{ flex: 1 }}>
        <KeyboardAvoidingView
          behavior="position"
          enabled={Platform.OS === 'ios'}
          contentContainerStyle={{
            flex: 1,
            height: keyboardContainerHeight,
          }}
        >
          <View
            style={{
              alignItems: 'center',
              width: '50%',
              alignSelf: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 30,
                marginBottom: theme.getSpacing(2),
                marginTop: theme.getSpacing(4),
                textAlign: 'center',
              }}
            ></Text>
          </View>
          <Text
            style={{
              fontSize: 30,
              marginBottom: theme.getSpacing(1),
              marginTop: theme.getSpacing(2),
              textAlign: 'center',
            }}
          >
            {getText('welcome')}
          </Text>
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 14,
              paddingBottom: theme.getSpacing(2),
              textAlign: 'center',
            }}
          >
            {getText('please-enter-your-information')}
          </Text>

          <Form methods={methods}>
            <Form.Alert
              title={error?.message}
              intent="error"
              visible={!!error}
            />
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('first-name')}
                  name="firstName"
                  rules={{
                    required: getText('first-name-is-required'),
                    validate: {
                      value: () => {
                        return validate.isName(methods.getValues().firstName)
                          ? true
                          : getText('first-name-is-not-valid');
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  testID={RegisterTestIDs.firstNameInput}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('last-name')}
                  name="lastName"
                  rules={{
                    required: getText('last-name-is-required'),
                    validate: {
                      value: () => {
                        return validate.isName(methods.getValues().lastName)
                          ? true
                          : getText('last-name-is-not-valid');
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  testID={RegisterTestIDs.lastNameInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('birth-date')}
                  name="dateOfBirth"
                  rules={{
                    required: getText('birth-date-is-required'),
                    validate: {
                      validateLegalAge: (value: string) => {
                        return validate.isLegalAge(
                          formatDate(value, DateTimeFormat.USDateFormat),
                        )
                          ? true
                          : getText('date-is-not-valid');
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  type="date"
                  testID={RegisterTestIDs.dateOfBirthInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText('mobile-number')}
                  name="phoneNumberMobile"
                  rules={{
                    required: getText('mobile-number-is-required'),
                    validate: {
                      value: (value) => {
                        return validate.isPhoneNumber(
                          // Backend is expecting 1 digit country code in front of the phone number
                          value ? '+1' + value : '',
                        )
                          ? true
                          : getText('phone-is-not-valid');
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  type="telephoneNumber"
                  testID={RegisterTestIDs.phoneNumberMobileInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <View style={{ marginTop: 44, marginBottom: 44 }}>
              <Text style={styles.text}>{getPoliciesContent()}</Text>
            </View>
            <Form.Actions>
              <Button
                testID={RegisterTestIDs.registerButton}
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === 'loading'}
                logger={{ id: 'register-details-submit-button' }}
              >
                {getText('next')}
              </Button>
            </Form.Actions>
          </Form>

          {Platform.OS === 'web' ? (
            <Modal
              title={policyModalSettings.title}
              show={policyModalSettings.state}
              okButtonProps={{
                onPress: closePolicyModal,
                logger: { id: 'policy-ok-button-modal' },
                text: getText('close'),
              }}
              dismissButtonProps={{
                onPress: closePolicyModal,
                logger: { id: 'policy-dismiss-button-modal' },
              }}
              isScrollable
              height={800}
            >
              <Text style={styles.modalText}>{getModalContent()}</Text>
            </Modal>
          ) : (
            <BottomSheet
              bottomSheetRef={policyModalSettings.ref}
              title={policyModalSettings.title}
              height={'90%'}
              onDismiss={closePolicyModal}
            >
              <View>
                <Text style={styles.modalText}>{getModalContent()}</Text>
              </View>
            </BottomSheet>
          )}
        </KeyboardAvoidingView>
      </ScrollView>
    </ScreenContainer>
  );
};
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  link: {
    ...theme.fonts.medium,
    textDecorationLine: 'underline',
    color: theme.palette.primary[600],
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
}));

interface RegisterDetailsProps {}
