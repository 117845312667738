import React, { FunctionComponent, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { Form, ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { LandingHeader } from '../../components/landing-header';
import { RootNavigationParamList } from '../../navigation/RootNavigation';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CommonActions, useNavigation } from '@react-navigation/native';

import {
  getForm,
  getPatientRecord,
  initLocationPatientRecord,
} from './fill-in-form-actions';
import { useFillInFormStore } from './fill-in-form-store';
import { useForm } from 'assets/form';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { Button } from 'assets/components/button';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal, TouchableOpacity } from '@gorhom/bottom-sheet';
import { Modal } from 'assets/components/modal';
import { IconButton } from 'assets/components/icon-button';
import { CloseIcon } from 'assets/icons';
import { Divider } from 'react-native-paper';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { FillInFormContent } from './FillInFormContent';
import { getText } from 'assets/localization/localization';

const hideLandingHeaderOnWeb = Platform.OS !== 'web';

export const FillInForm: FunctionComponent<FillInFormProps> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();

  // Read route params
  const formId = route?.params.form_id,
    locationId = route?.params.location_id;

  const { form, patientRecord } = useFillInFormStore();
  const { user } = useUserState();
  const { pharmacyName } = useAppStateStore();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const navigation = useNavigation();

  const [locationPatientRecordId, setLocationPatientRecordId] = useState<
    string | null
  >(null);

  const handleFormSubmit = () => {
    setIsSubmitted(true);
  };

  const getLocationPatientRecordId = (
    patientRecord: PatientRecordDto,
  ): string | null => {
    const findLocationPatientRecordId =
      patientRecord.location_patient_records.find(
        (record) => record.location_id === locationId,
      )?.id;

    return findLocationPatientRecordId || null;
  };

  const methods = useForm<SelectPatientRecord>({
    defaultValues: {
      patientRecordId: null,
    },
  });

  const startFillingIn = () => {
    // Showing Modal on web or BottomSheet on mobile
    Platform.OS === 'web' ? setModalIsOpen(true) : sheetRef.current?.present();
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data.action !== 'submission-completed') return;

    handleFormSubmit();
  };

  useEffect(() => {
    user?.patientRecordId && getPatientRecord(user?.patientRecordId);

    getForm(formId, locationId);

    // Adding a "message" event listener on the web version.
    // We're using it for detecting the form submission and
    // redirecting users to the homepage. Jotform fires a "message"
    // event when user submits a form, but it only works for
    // iframes. For WebView we're detecting the Thank You page on
    // page load (see the FormWebView component)
    //
    // Jotform thread explaining this:
    // https://www.jotform.com/answers/1609027-iframe-embedded-form-does-iframe-emit-events
    Platform.OS === 'web' && window.addEventListener('message', handleMessage);

    return () => {
      Platform.OS === 'web' &&
        window.removeEventListener('message', handleMessage);
    };
  }, [formId]);

  useEffect(() => {
    if (patientRecord) {
      const foundPatientRecordAtLocation =
        getLocationPatientRecordId(patientRecord);

      if (!foundPatientRecordAtLocation) {
        initLocationPatientRecord(patientRecord.id, locationId);
      }

      methods.setValue('patientRecordId', locationPatientRecordId);
      setLocationPatientRecordId(foundPatientRecordAtLocation);
    }
  }, [patientRecord, locationPatientRecordId]);

  const watchPatientRecordId = methods.watch('patientRecordId');

  const navigateHome = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'home',
      }),
    );

    setIsSubmitted(false);
  };

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();

    isSubmitted && navigateHome();
  };

  const handleModalDismiss = () => {
    setModalIsOpen(false);

    isSubmitted && navigateHome();
  };

  return (
    <View style={{ flex: 1 }}>
      {hideLandingHeaderOnWeb && (
        <LandingHeader
          pharmacyName={pharmacyName}
          patientName={user?.firstName}
        />
      )}
      {!user || !form ? (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator></LoadingIndicator>
        </View>
      ) : (
        <ScreenContainer>
          <View
            style={{
              paddingHorizontal: theme.getSpacing(2),
              flex: 1,
            }}
          >
            <Text style={styles.title}>{form.title}</Text>
            <Divider />
            <Text
              style={{
                color: theme.palette.gray[700],
                marginTop: theme.getSpacing(3),
                marginBottom: theme.getSpacing(2),
              }}
            >
              {getText('patient-select')}
            </Text>
            <Form methods={methods}>
              <Form.Row>
                <Form.Column>
                  <DropdownSelect
                    label={getText('person')}
                    fieldName="patientRecordId"
                    options={[
                      {
                        value: locationPatientRecordId || '',
                        label: getText('myself'),
                      },
                    ]}
                    disabled={!locationPatientRecordId}
                  ></DropdownSelect>
                </Form.Column>
              </Form.Row>
            </Form>
            <TouchableOpacity>
              <Text style={{ color: theme.palette.primary[500] }}>
                Add new patient...
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                color: theme.palette.gray[500],
                marginVertical: theme.getSpacing(1),
              }}
            >
              {getText('after-adding-patient-message')}
            </Text>

            <Button
              style={{ marginTop: 'auto' }}
              hierarchy="primary"
              disabled={!watchPatientRecordId}
              logger={{ id: 'prescription-flow' }}
              onPress={() => startFillingIn()}
            >
              {getText('go-to-form')}
            </Button>
          </View>
        </ScreenContainer>
      )}
      {Platform.OS === 'web' ? (
        <Modal
          title={form?.title}
          show={modalIsOpen}
          okButtonProps={{
            onPress: handleModalDismiss,
            logger: { id: 'fill-in-form-ok-button-modal' },
            text: isSubmitted ? getText('close') : getText('cancel'),
          }}
          dismissButtonProps={{
            onPress: handleModalDismiss,
            logger: { id: 'fill-in-form-dismiss-button-modal' },
          }}
          isScrollable={true}
          height={800}
          scrollViewStyle={{ flex: 1 }}
          contentContainerStyle={{ flex: 1 }}
        >
          {locationPatientRecordId && (
            <FillInFormContent
              formId={formId}
              locationId={locationId}
              locationPatientRecordId={locationPatientRecordId}
              onFormSubmit={handleFormSubmit}
            />
          )}
        </Modal>
      ) : (
        <BottomSheet
          height="100%"
          bottomSheetRef={sheetRef}
          onDismiss={handleBottomSheetDismiss}
          contentContainerStyle={{ flex: 1 }}
          hideHandle={true}
          headerContent={
            <View
              style={{
                position: 'relative',
                marginBottom: theme.getSpacing(1),
              }}
            >
              <View style={styles.sheetIconContainer}>
                <IconButton
                  icon={CloseIcon}
                  onPress={handleBottomSheetDismiss}
                  size={24}
                  color={theme.palette.gray[500]}
                  logger={{ id: 'form-bottom-sheet-close' }}
                />
              </View>
              <View style={styles.sheetTitleContainer}>
                <Text style={styles.sheetTitle}>{form?.title}</Text>
              </View>
            </View>
          }
          children={
            <>
              {locationPatientRecordId && (
                <FillInFormContent
                  formId={formId}
                  locationId={locationId}
                  locationPatientRecordId={locationPatientRecordId}
                  onFormSubmit={handleFormSubmit}
                />
              )}
            </>
          }
        />
      )}
    </View>
  );
};

interface SelectPatientRecord {
  patientRecordId: string | null;
}

interface FillInFormProps
  extends BottomTabScreenProps<RootNavigationParamList, 'forms'> {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(2),
  },
  sheetIconContainer: {
    position: 'absolute',
    left: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  loadingIndicator: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -50,
  },
}));
