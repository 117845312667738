import React, { FunctionComponent } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { StoreSelectorItemProps } from '../types';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import BaseRadioButton from 'assets/components/radio-button-group/BaseRadioButton';
import { InfoIcon } from 'assets/icons';
import { Distance } from '../../../types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { updateLocationDetails } from '../../../screens/register/register-actions';
import { TouchableOpacity } from 'react-native-gesture-handler';

export const StoreSelectorItem: FunctionComponent<StoreSelectorItemProps> = ({
  item = {} as Distance<PharmacyLocationDto>,
  selected = false,
  disabled = false,
  onPress = (item: Distance<PharmacyLocationDto>) => {},
  onInfoButtonPress,
  status,
  // showInfoModal,
  ...rest
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleInfoButtonOnPress = async () => {
    onInfoButtonPress?.(item);
    // TODO refactor action
    if (item.id) await updateLocationDetails(item.id);
  };

  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress(item)}>
      <View style={styles.radioButtonContainer}>
        <BaseRadioButton selected={selected} disabled={disabled} />
      </View>

      <View style={{ flex: 1 }}>
        <View style={styles.titleContainer}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title} ellipsizeMode="tail" numberOfLines={1}>
              {item.name}
            </Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {item.distance && (
              <Text style={styles.secondTitle}>
                {`${item.distance.toFixed(2)} ${item.distanceUnit}`}
              </Text>
            )}

            <TouchableOpacity
              style={styles.iconContainer}
              onPress={handleInfoButtonOnPress}
            >
              <Icon icon={InfoIcon} color={theme.palette.gray[500]} size={14} />
            </TouchableOpacity>
          </View>
        </View>
        {item.address && (
          <>
            <Text style={styles.secondTitle}>{item.address.address1}</Text>
            <Text style={styles.secondTitle}>
              {`${item.address.city}, ${item.address.state} ${item.address.postal_code}`}
            </Text>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    width: '100%',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(1),
  },
  secondTitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  iconContainer: { marginLeft: theme.getSpacing(1), alignSelf: 'center' },
}));
