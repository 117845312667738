import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import type {
  NativeStackScreenProps,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { ScreenContainer, ScreenSafeContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { Surface } from 'react-native-paper';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
} from './patient-store';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import {
  PatientUnderCareForm,
  refreshCaregiverRequestsState,
  removeRecordUnderCare,
} from './patient-actions';
import { PersonalInfoBottomSheet } from './components/record-under-care-info/PatientPersonalInfoBottomSheet';
import {
  PatientRecordDto,
  PatientUnderCareRelationship,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import patientService from '../../../api/patient-service';
import {
  DateTimeFormat,
  formatISODateAsString,
} from '../../../common/form-utils';
import { PersonalInfoModal } from './components/record-under-care-info/PatientPersonalInfoModal';
import { UseFormReturn, useForm } from 'react-hook-form';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AsyncStatus } from '../../../store/types';

import { Icon } from 'assets/components/icon';
import {
  AddPersonIcon,
  CalendarIcon,
  MedicationTransferIcon,
  PlusCircleIcon,
} from 'assets/icons';
import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { AppointmentDetailsScreenRouteProp } from '../../../navigation/RootNavigation';
import { ScrollView } from 'react-native-gesture-handler';
import { PrescriptionCard } from '../../../components/prescription-card';
import { useUserState } from '../../../store/user-store';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import {
  BookingPageDto,
  SortOrder,
} from '@digitalpharmacist/appointment-service-client-axios';
import appointmentService from '../../../api/appointment-service';
import refillService from '../../refill/refill-service';
import { formatDateToMMMD } from '../../../common/datetime-utils';
import { CountBadge } from './components/count-badge/CountBadge';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import moment from 'moment';
import { Button } from 'assets/components/button';

export const PatientUnderCareList: FunctionComponent<
  PropsWithChildren<PatientUnderCareListProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordsUnderCare, caregiverRequests, status } =
    usePatientRecordState();

  useFocusEffect(
    useCallback(() => {
      void refreshCaregiverRequestsState();
      usePatientRecordState.setState({ status: 'success' });
    }, []),
  );

  const onPress = () => {
    navigation.navigate('add-patient-under-care-info');
  };

  console.log('recs', recordsUnderCare, caregiverRequests);

  //    if (caregiverRequests.length > 0 || recordsUnderCare.length > 0)
  //navigation.navigate('patient-under-care');

  if (status !== 'success') {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator></LoadingIndicator>
      </View>
    );
  } else {
    return caregiverRequests.length == 0 && recordsUnderCare.length == 0 ? (
      <ScreenContainer>
        <View style={styles.emptyContainer}>
          <View>
            <AddPersonIcon color={theme.palette.white} />
          </View>
          <Text style={styles.textTitle}>
            {getText('people-under-your-care')}
          </Text>
          <View style={styles.emptyTextTitle}>
            <View style={{ marginTop: theme.getSpacing(2) }}>
              <Text style={[styles.emptyText]}>
                {getText('people-under-your-care-empty-page')}
              </Text>
            </View>
          </View>
          <Button
            onPress={onPress}
            hierarchy="primary"
            icon={PlusCircleIcon}
            logger={{
              id: 'add-person-or-pet-submit-button',
            }}
          >
            {getText('add-person-or-pet')}
          </Button>
        </View>
      </ScreenContainer>
    ) : (
      <ScreenSafeContainer style={styles.container}>
        <ScrollView>
          {recordsUnderCare.map((record) => (
            <View key={record.id} style={styles.container}>
              <InfoCard
                name={`${record.record_under_care.first_name} ${record.record_under_care.last_name}`.trim()}
                date={formatISODateAsString(
                  record.record_under_care.date_of_birth,
                  DateTimeFormat.USDateFormat,
                )}
                relationship={record.relationship}
                submittedDate={record.created_at}
                isRequest={false}
                record={record}
                status={status}
                navigation={navigation}
              />
            </View>
          ))}
          {caregiverRequests.map((caregiver) => (
            <View key={caregiver.id} style={styles.container}>
              <RequestInfoCard
                name={`${caregiver.first_name} ${caregiver.last_name}`.trim()}
                dob={formatISODateAsString(
                  caregiver.date_of_birth,
                  DateTimeFormat.USDateFormat,
                )}
                relationship={caregiver.relationship}
                status={status}
                submittedDate={formatDateToMMMD(caregiver.created_at)}
              />
            </View>
          ))}
        </ScrollView>
      </ScreenSafeContainer>
    );
  }
};

const RequestInfoCard: FunctionComponent<
  PropsWithChildren<{
    name?: string;
    dob?: string;
    relationship?: string;
    status: AsyncStatus;
    submittedDate: string;
  }>
> = ({ name, dob, relationship, status, submittedDate }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Surface
      style={[
        {
          elevation: 2,
          overflow: 'hidden',
          backgroundColor: theme.palette.white,
          minHeight: 100,
          width: '100%',
          marginBottom: theme.getSpacing(1),
        },
      ]}
    >
      <View style={{ margin: theme.getSpacing(2) }}>
        <View style={styles.requestTextContainer}>
          <Text style={styles.detailsText}>{name}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: theme.getSpacing(1),
          }}
        >
          <Text style={styles.otherDetailsText}>{dob}</Text>
          <Text
            style={[
              styles.otherDetailsText,
              { paddingLeft: theme.getSpacing(0.5) },
            ]}
          >
            {'('}
            {relationship}
            {')'}
          </Text>
        </View>
        <View style={styles.authorizationContainer}>
          <View style={styles.rightSpacing}>
            <Icon
              icon={CalendarIcon}
              color={theme.palette.warning[500]}
              size={14}
            ></Icon>
          </View>
          <Text style={styles.requestPendingStyle}>
            {getText('request-status', {
              submittedDate: submittedDate,
            })}
          </Text>
        </View>
      </View>
    </Surface>
  );
};

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    name?: string;
    date?: string;
    relationship?: string;
    submittedDate?: string; //leaving this but in the new figma design we don't show submitted data like we did earlier
    isRequest: boolean;
    record: RecordUnderCareDto;
    status: AsyncStatus;
    navigation: NativeStackNavigationProp<
      PatientUnderCareStackParamList,
      'patient-under-care-list',
      undefined
    >;
  }>
> = ({ name, date, relationship, isRequest, record, status, navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isWeb = Platform.OS === 'web';
  const nativeRef = React.useRef<BottomSheetModal>(null);
  const [showModal, setShowModal] = useState(false);
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [viewMode, switchViewMode] = useState(false);
  const [title, setTitle] = useState(getText('personal-info'));
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.PersonalInfo,
  );
  const methods = useForm<PatientUnderCareForm>({});
  const [form, setForm] =
    useState<UseFormReturn<PatientUnderCareForm>>(methods);
  const appNav = useNavigation<AppointmentDetailsScreenRouteProp>();
  const { pharmacyId } = useAppStateStore();
  const { user } = useUserState();
  const [pucMedications, setPucMedications] = useState<PrescriptionDto[]>();
  const [pucAppointments, setPucAppointments] = useState<BookingPageDto>();
  const [statusText, setStatusText] = useState(getText('retrieving')); //TODO error case handling
  const nav = useNavigation<NavigationProp<ParamListBase>>();
  const { updateMedicationsData } = useRefillMedicationsStore();
  const { recordsUnderCare, caregiverRequests } = usePatientRecordState();

  useEffect(() => {
    loadPUCDetails();
  }, []);

  const handleRemovePatientUnderCare = async (record?: RecordUnderCareDto) => {
    // TODO Do we need error handling? This probably applies more broadly to these types of calls. (Error handling)
    if (!record) throw new Error('No patient record has been found');
    await removeRecordUnderCare(record.caregiver_id, record.id);
    await setVisibility(false);
  };

  const loadPUCDetails = async () => {
    if (!user?.preferredPharmacyLocationId) {
      throw new Error(getText('store-selector-no-stores-error'));
    }
    const currentLocationId = user.preferredPharmacyLocationId;
    const filtered_location_patient_records =
      record.location_patient_records.filter(
        (minLPR) => minLPR.location_id === currentLocationId,
      );
    setPucMedications(undefined);
    //upcoming TODO parallelize these
    if (filtered_location_patient_records.length === 0) {
      setPucMedications([]);
    } else {
      const location_patient_record_id =
        filtered_location_patient_records[0].id;
      const medication_list: PrescriptionDto[] =
        await refillService.getPatientPrescriptions(
          user.preferredPharmacyLocationId,
          location_patient_record_id,
        );
      setPucMedications(medication_list);
    }

    setPucAppointments(undefined);

    const upcoming_appointment: BookingPageDto =
      await appointmentService.findBookings(pharmacyId, 0, 7, SortOrder.Asc, {
        patientRecordId: record.record_under_care.id,
        minEndDate: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      });

    setPucAppointments(upcoming_appointment);
    usePatientRecordState.setState({ status: 'success' });
  };

  const setVisibility = async (visible: boolean) => {
    form.clearErrors();
    if (visible) await refreshPatientRecord();
    else {
      changeViewMode(false);
    }
    if (Platform.OS === 'web') {
      setShowModal(visible);
    } else {
      if (!visible) {
        nativeRef.current?.dismiss();
      } else {
        nativeRef.current?.present();
      }
    }
  };

  const handleOnMedsPress = () => {
    if (updateMedicationsData)
      updateMedicationsData({
        selectedPatientRecordId: record.record_under_care.id,
      });
    nav.navigate('app', {
      screen: 'medications',
      params: { screen: 'medications' },
    });
  };

  const handleOnAppointmentPress = () => {
    appNav.navigate('appointments');
  };

  const refreshPatientRecord = async () => {
    if (!record.record_under_care) throw new Error('No record id');
    else {
      setPatientRecord(
        await patientService.findPatientRecord(record.record_under_care.id),
      );
    }
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    await refreshCaregiverRequestsState();
    if (patientRecord)
      setPatientRecord(
        await patientService.updatePatientRecord(patientRecord.id, updatePR),
      );
  };

  const handleSave = (updatePR: UpdatePatientRecordDto) => {
    updatePatientRecord(updatePR).catch((error) => {
      alert(error); //TODO Error handling
    });
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    if (editType === PatientUnderCareEditDetailsEnum.PersonalInfo) {
      setTitle(getText('personal-info'));
    } else if (editType === PatientUnderCareEditDetailsEnum.MedicalInfo) {
      setTitle(getText('medical-info'));
    }
    setWhichEditDetails(editType);
  };

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    if (patientRecord?.first_name)
      methods.setValue('first_name', patientRecord.first_name);
    if (patientRecord?.last_name)
      methods.setValue('last_name', patientRecord.last_name);
    if (patientRecord?.date_of_birth)
      methods.setValue(
        'date_of_birth',
        formatISODateAsString(
          patientRecord.date_of_birth,
          DateTimeFormat.USDateFormat,
        ),
      );
    if (record.relationship)
      methods.setValue('relationship', record.relationship);
    if (patientRecord?.gender) methods.setValue('gender', patientRecord.gender);
    if (patientRecord?.address) {
      methods.setValue(
        'address1',
        patientRecord.address.address1 ? patientRecord.address.address1 : '',
      );
      methods.setValue(
        'address2',
        patientRecord.address.address2 ? patientRecord.address.address2 : '',
      );
      methods.setValue(
        'city',
        patientRecord.address.city ? patientRecord.address.city : '',
      );
      methods.setValue(
        'state',
        patientRecord.address.state ? patientRecord.address.state : '',
      );
      methods.setValue(
        'country',
        patientRecord.address.country ? patientRecord.address.country : '',
      );
      methods.setValue(
        'postal_code',
        patientRecord.address.postal_code
          ? patientRecord.address.postal_code
          : '',
      );
    } else {
      methods.setValue('address1', '');
      methods.setValue('address2', '');
      methods.setValue('city', '');
      methods.setValue('state', '');
      methods.setValue('country', 'US');
      methods.setValue('postal_code', '');
    }
    if (patientRecord?.medical_conditions)
      methods.setValue('medical_conditions', patientRecord.medical_conditions);
    if (patientRecord?.allergies) {
      methods.setValue('allergies', patientRecord.allergies);
    }
    if (patientRecord?.prefers_easy_open_bottle_caps) {
      methods.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }

    changeViewMode(true);
    whichDetailsToEdit(editDetail);
    setForm(methods);

    return methods;
  };

  const relationshipProps: Array<{ label: string; value: string }> = [
    {
      label: getText('child'),
      value: PatientUnderCareRelationship.Child,
    },
    {
      label: getText('spouse'),
      value: PatientUnderCareRelationship.Spouse,
    },
    {
      label: getText('parent'),
      value: PatientUnderCareRelationship.Parent,
    },
    {
      label: getText('grandparent'),
      value: PatientUnderCareRelationship.Grandparent,
    },
    {
      label: getText('grandchild'),
      value: PatientUnderCareRelationship.Grandchild,
    },
    {
      label: getText('partner'),
      value: PatientUnderCareRelationship.Partner,
    },
    {
      label: getText('pet'),
      value: PatientUnderCareRelationship.Pet,
    },
    {
      label: getText('other'),
      value: PatientUnderCareRelationship.Other,
    },
  ];

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  const filterAppointments = pucAppointments?.results;

  return (
    <Surface
      style={[
        {
          elevation: 2,
          overflow: 'hidden',
          backgroundColor: theme.palette.white,
          minHeight: 100,
          width: '100%',
          marginBottom: theme.getSpacing(1),
        },
      ]}
    >
      <View style={styles.bottomLineBold}>
        <View style={styles.requestTextContainer}>
          <Text style={styles.detailsText}>{name}</Text>
          {!isRequest && (
            <TouchableOpacity
              style={styles.textContainer}
              onPress={() => setVisibility(true)}
            >
              <Text
                style={[
                  styles.pressableTextRevoke,
                  styles.leftSpacing,
                  isWeb && styles.pointer,
                ]}
              >
                {getText('details')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: theme.getSpacing(2),
          }}
        >
          <Text style={styles.otherDetailsText}>{date}</Text>
          <Text
            style={[
              styles.otherDetailsText,
              { paddingLeft: theme.getSpacing(0.5) },
            ]}
          >
            {'('}
            {relationship}
            {')'}
          </Text>
        </View>
      </View>
      <View style={styles.viewMargin}>
        <View style={styles.requestTextContainer}>
          <View style={styles.rowDirection}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={CalendarIcon}
                color={theme.palette.gray[400]}
                size={18}
              ></Icon>
            </View>
            <Text style={styles.nameText}>{getText('appointments')}</Text>
            {filterAppointments && filterAppointments.length > 0 && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <CountBadge
                  backgroundColor={theme.palette.gray[200]}
                  color={theme.palette.gray[900]}
                  label={filterAppointments.length.toString()}
                ></CountBadge>
              </View>
            )}
          </View>
          <TouchableOpacity
            onPress={() => handleOnAppointmentPress()}
            style={styles.textContainer}
          >
            <Text
              style={[
                styles.pressableTextRevoke,
                styles.leftSpacing,
                isWeb && styles.pointer,
              ]}
            >
              {getText('view')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.marginLeft}>
          {filterAppointments ? (
            filterAppointments.length > 0 ? (
              filterAppointments
                .map((appointment) => (
                  <View key={appointment.id} style={{ marginLeft: 1 }}>
                    <Text style={styles.textDetails}>
                      {getText('coming-up')}{' '}
                      {formatDateToMMMD(appointment.startTime)}
                    </Text>
                  </View>
                ))
                .at(0)
            ) : (
              <View style={{ marginLeft: 1 }}>
                <Text style={styles.textDetails}>
                  {getText('no-upcoming-appointments')}
                </Text>
              </View>
            )
          ) : (
            <View style={{ marginLeft: 1 }}>
              <Text style={styles.textDetails}>{statusText}</Text>
            </View>
          )}

          <View style={styles.bottomLineThin}></View>
        </View>
      </View>
      <View style={styles.viewMargin}>
        <View style={styles.requestTextContainer}>
          <View style={styles.rowDirection}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={MedicationTransferIcon}
                color={theme.palette.gray[500]}
                size={16}
              ></Icon>
            </View>
            <Text style={styles.nameText}>{getText('medications')}</Text>
            {pucMedications && pucMedications.length > 0 && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <CountBadge
                  backgroundColor={theme.palette.gray[200]}
                  color={theme.palette.gray[900]}
                  label={pucMedications.length.toString()}
                ></CountBadge>
              </View>
            )}
          </View>
          <TouchableOpacity
            onPress={() => handleOnMedsPress()}
            style={styles.textContainer}
          >
            <Text
              style={[
                styles.pressableTextRevoke,
                styles.leftSpacing,
                isWeb && styles.pointer,
              ]}
            >
              {getText('view')}
            </Text>
          </TouchableOpacity>
        </View>
        {pucMedications ? (
          pucMedications.length > 0 ? (
            pucMedications.slice(0, 3).map((medication) => (
              <View
                key={medication.rx_number}
                style={{ marginLeft: theme.getSpacing(3) }}
              >
                <View
                  style={{ marginBottom: theme.getSpacing(1), marginLeft: 4 }}
                >
                  <PrescriptionCard
                    selectable={false}
                    showRefillStatus={true}
                    pucStyle={true}
                    showRxNumber={false}
                    showStatus={true}
                    showPatientInfo={false}
                    onPress={() => {}}
                    prescription={medication}
                  />
                </View>
              </View>
            ))
          ) : (
            <View style={{ marginLeft: 4 }}>
              <Text style={styles.textDetails}>
                {getText('no-refills-due')}
              </Text>
            </View>
          )
        ) : (
          <View style={{ marginLeft: 4 }}>
            <Text style={styles.textDetails}>{statusText}</Text>
          </View>
        )}
      </View>
      {patientRecord &&
        (Platform.OS === 'web' ? (
          <PersonalInfoModal
            showModal={showModal}
            setVisibility={setVisibility}
            handleRemovePatientUnderCare={handleRemovePatientUnderCare}
            record={record}
            patientRecord={patientRecord}
            changeViewMode={changeViewMode}
            viewMode={viewMode}
            relationshipProps={relationshipProps}
            handleSave={handleSave}
            handleSubmit={() => {}}
            onEditPress={onEditPress}
            whichDetailsToEdit={whichDetailsToEdit}
            editDetails={editDetails}
            title={title}
            form={form}
          ></PersonalInfoModal>
        ) : (
          <PersonalInfoBottomSheet
            nativeRef={nativeRef}
            setVisibility={setVisibility}
            handleRemovePatientUnderCare={handleRemovePatientUnderCare}
            record={record}
            relationshipProps={relationshipProps}
            changeViewMode={changeViewMode}
            viewMode={viewMode}
            patientRecord={patientRecord}
            handleSave={handleSave}
            onEditPress={onEditPress}
            whichDetailsToEdit={whichDetailsToEdit}
            editDetails={editDetails}
            title={title}
            form={form}
          ></PersonalInfoBottomSheet>
        ))}
    </Surface>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  emptyContainer: {
    marginTop: theme.getSpacing(4),
    alignItems: 'center',
    paddingTop: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    display: 'flex',
  },
  emptyTextTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textDetails: {
    marginTop: 2,
    marginLeft: theme.getSpacing(3),
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    height: 16,
    color: theme.palette.gray[600],
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  emptyText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
    textAlign: 'center',
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  rowDirection: {
    flexDirection: 'row',
  },
  otherDetailsText: {
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
  },
  marginLeft: { marginLeft: theme.getSpacing(0.5) },
  detailsText: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[700],
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  authorizationContainer: {
    flexDirection: 'row',
  },
  requestApprovedText: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(0.5),
  },
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
  },
  bottomLineBold: {
    margin: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  bottomLineThin: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 0.5,
    marginLeft: theme.getSpacing(3),
    marginTop: theme.getSpacing(2),
  },
  viewMargin: {
    margin: theme.getSpacing(2),
  },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
}));

type PatientUnderCareListProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'patient-under-care-list'
>;
