import {
  AppointmentGroupLocationDto,
  AppointmentTypeDto,
  AppointmentTypeSlotDto,
  BookingDto,
  SubmissionDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';

export enum BookingStep {
  Patient = 'patient',
  Date = 'date',
  Time = 'time',
  Forms = 'forms',
  Summary = 'summary',
  Confirmation = 'confirmation',
}

export const useBookAppointmentState = create<BookAppointmentState>(() => ({
  slotsStatus: 'idle',
  formsStatus: 'idle',
  step: 0,
  stepName: BookingStep.Patient,
  stepsMap: [
    BookingStep.Patient,
    BookingStep.Date,
    BookingStep.Time,
    BookingStep.Confirmation,
  ],
  nextButtonStatus: 'disabled',
  patientRecordStatus: 'idle',
  bookingStatus: 'idle',
  patientsUnderCareStatus: 'idle',
  availableAppointmentLocationsStatus: 'idle',
  showBookAppointment: false,
  isReschedule: false,
  processError: false,
  availableAppointmentLocations: [],
  patientsUnderCare: [],
  patientsUnderCareRequests: [],
  currentForm: 0,
  formSubmissions: [],
  appointmentTypeDetailsStatus: 'idle',
}));

interface BookAppointmentState {
  slotsStatus: AsyncStatus;
  patientRecordStatus: AsyncStatus;
  bookingStatus: AsyncStatus;
  availableAppointmentLocationsStatus: AsyncStatus;
  patientsUnderCareStatus: AsyncStatus;
  formsStatus: AsyncStatus;
  appointmentTypeDetailsStatus: AsyncStatus;
  step: number;
  stepName: BookingStep;
  stepsMap: BookingStep[];
  nextButtonStatus: 'enabled' | 'disabled';
  showBookAppointment: boolean;
  isReschedule: boolean;
  processError: boolean;
  availableAppointmentLocations: AppointmentGroupLocationDto[];
  patientsUnderCare: RecordUnderCareDto[];
  patientsUnderCareRequests: RecordUnderCareRequestDto[];
  currentForm: number;
  formSubmissions: SubmissionDto[];
  error?: ApiError;
  slots?: AppointmentTypeSlotDto[];
  timeSlots?: AppointmentTypeSlotDto[];
  selectedDate?: string;
  selectedSlot?: AppointmentTypeSlotDto;
  patientRecord?: PatientRecordDto;
  location?: PharmacyLocationDto;
  selectedPatient?: PatientRecordDto | RecordUnderCareDto;
  booking?: BookingDto;
  appointmentType?: AppointmentTypeDto;
  bookingId?: string;
  locationOverride?: PharmacyLocationDto;
  selectedLocation?: PharmacyLocationDto;
  selectedLocationPatientRecordId?: string;
  formHtmlContent?: string;
  appointmentTypeDetails?: AppointmentTypeDto;
}
