import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const ShoppingCartIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M1.33337 0.833374H4.66671L6.90004 11.9917C6.97624 12.3754 7.18496 12.72 7.48966 12.9653C7.79436 13.2106 8.17562 13.3409 8.56671 13.3334H16.6667C17.0578 13.3409 17.4391 13.2106 17.7438 12.9653C18.0484 12.72 18.2572 12.3754 18.3334 11.9917L19.6667 5.00004H5.50004M8.83337 17.5C8.83337 17.9603 8.46028 18.3334 8.00004 18.3334C7.5398 18.3334 7.16671 17.9603 7.16671 17.5C7.16671 17.0398 7.5398 16.6667 8.00004 16.6667C8.46028 16.6667 8.83337 17.0398 8.83337 17.5ZM18 17.5C18 17.9603 17.6269 18.3334 17.1667 18.3334C16.7065 18.3334 16.3334 17.9603 16.3334 17.5C16.3334 17.0398 16.7065 16.6667 17.1667 16.6667C17.6269 16.6667 18 17.0398 18 17.5Z" />
    </Svg>
  );
};
